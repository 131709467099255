import { Injectable } from '@angular/core';
import { environment } from '@messenger-env/environment';
import { EnvironmentModel } from '@messenger-env/environment.model';

import { BrowserStorageService } from '@pm/core/services/browser-storage.service';

enum StorageKey {
  API = 'pm_api_testing_uri_param',
  SOCKET = 'pm_preview_socket_testing_uri_param',
}

@Injectable()
export class EnvironmentService {
  readonly api: string;
  readonly socket: string;
  readonly socketVersionUrl: string;

  constructor(private storage: BrowserStorageService) {
    this.api = this.generateApiURI(environment, this.getItem(StorageKey.API));
    this.socket = this.getPreviewSocketURI(environment, this.getItem(StorageKey.SOCKET));
    this.socketVersionUrl = this.getSocketUrlVersionCheck();
  }

  private getItem(key: StorageKey): string {
    return this.storage.getItem(key) || '';
  }

  private generateApiURI(env: EnvironmentModel, envApi: string): string {
    return (env.isTesting && !!envApi && envApi.length > 0) ? envApi : env.api;
  }

  private getPreviewSocketURI(env: EnvironmentModel, socket: string): string {
    return (!env.isTesting || socket.length === 0) ? env.socket : socket;
  }

  private extractHostname(url: string): string {

    // find & remove protocol (http, ftp, etc.) and get hostname
    let hostname = url.indexOf('//') > -1 ? url.split('/')[2] : url.split('/')[0];

    // find & remove port number
    hostname = hostname.split(':')[0];
    // find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  private getSocketUrlVersionCheck(): string | null {
    const hostName = this.extractHostname(this.socket);

    return hostName ? `https://${hostName}` : null;
  }
}
