import { AuthGrantApiModel } from '@pm/core/models/auth-grant.model';
import { MessageMetaModel } from '@pm/chat/models/message-meta.model';
import { UUID } from '@pm/utils';
import { LevelApiModel } from '@pm/core/models/user.model';
import { DefaultLanguageCode, LanguageCode } from '@pm/localisation/models/localisation.model';

// CONSTANTS

const QUICK_OPTION_LENGTH_LIMIT = 15;
const QUICK_SUM_OPTIONS_LENGTH_LIMIT = 150;
const QUICK_OPTIONS_COUNT_LIMIT = 16;

// ------------------------------------------------
// NEO INCOMING
// ------------------------------------------------

export type MessageModelType =
  'Heartbeat' |
  'Statement' |
  'Question' |
  'AnswerView' |
  'History' |
  'GoodBye' |
  'WalletBalance' |
  'LevelsProgress' |
  'Error' |
  'WebhookExecuted' |
  'ScreenOut' |
  'LanguageSwitched';

export type MessageModel =
  HeartbeatMessageModel |
  StatementMessageModel |
  QuestionMessageModel |
  AnswerViewMessageModel |
  HistoryMessageModel |
  LevelsMessageModel |
  GoodByeMessageModel |
  WalletBalanceMessageModel |
  ErrorMessageModel |
  WebhookExecutedMessageModel |
  LanguageSwitchedMessageModel |
  ScreenOutMessageModel;

export type StoreMessageModel =
  StatementMessageModel |
  QuestionMessageModel |
  AnswerViewMessageModel |
  GoodByeMessageModel;

export interface MessageModelBase {
  id: string;
  created_at: string; // ISO-8601 date strings
  kind: MessageModelType;
}

// HEARTBEAT

export interface HeartbeatMessageModel extends MessageModelBase {
  readonly kind: 'Heartbeat';
}

// WALLET

export interface WalletBalanceMessageModel extends MessageModelBase {
  readonly kind: 'WalletBalance';
  current_balance: number;
}

// STATEMENT

export interface StatementMessageModel extends MessageModelBase {
  readonly kind: 'Statement';
  name: string;
  name_html: string;
}

// ANSWER VIEW

export interface AnswerViewMessageModel extends MessageModelBase {
  readonly kind: 'AnswerView';
  question_id: string;
  answers: AnswerViewMessageAnswersModel[];
}

export interface AnswerViewMessageAnswersModel {
  id: string;
  name: string;
  name_html: string;
  value?: string;
}

// QUESTION

export type QuestionMessageModelType = 'RadioQuestion' | 'MultipleQuestion' | 'LanguageQuestion' | 'OpenEndedQuestion';

export type QuestionMessageModelDisplayType = 'auto' | 'row' | 'column';

export interface QuestionMessageModel extends MessageModelBase {
  readonly kind: 'Question';
  name: string;
  name_html: string;
  question_id: string;
  question_type: QuestionMessageModelType;
  display_type: QuestionMessageModelDisplayType;
  question_options: QuestionOptionModel[];
  bundle_name_html?: string;
}

export interface QuestionOptionModel {
  id: string;
  name: string;
  name_html: string;
  nota: boolean;
  open_ended: boolean;
  code?: LanguageCode;
  image: QuestionOptionImageData;
}

// LANGUAGE SWITCHED

export interface LanguageSwitchedMessageModel extends MessageModelBase {
  readonly kind: 'LanguageSwitched';
  language: LanguageCode;
}

// GOODBYE

export interface GoodByeMessageModel extends MessageModelBase {
  readonly kind: 'GoodBye';
  redirect_url?: string;
}

export interface WebhookExecutedMessageModel extends MessageModelBase {
  readonly kind: 'WebhookExecuted';
  name: string;
  reason: string;
}


export interface ScreenOutMessageModel extends MessageModelBase {
  readonly kind: 'ScreenOut';
  name: string;
}

// ERROR

export type MessageErrorModelType =
  'AuthenticationFailed' |
  'TokenExpired' |
  'UnauthorizedAction' |
  'InvalidMassage' |
  'ServerError' |
  'Misunderstood' |
  'NoSpoon';

export interface ErrorMessageModel extends MessageModelBase {
  readonly kind: 'Error';
  error: MessageErrorModel;
}

export interface LevelsMessageModel extends MessageModelBase {
  readonly kind: 'LevelsProgress';
  level: LevelApiModel;
}

export interface MessageErrorModel {
  messages: string[];
  kind: MessageErrorModelType;
  info: Map<string, string[]> | null;
}

// HISTORY

export type HistoryMessages = StatementMessageModel | QuestionMessageModel | AnswerViewMessageModel | LanguageSwitchedMessageModel;

export interface HistoryMessageModel extends MessageModelBase {
  readonly kind: 'History';
  messages: HistoryMessages[];
  size: number;
}

// ------------------------------------------------
// NEO OUTGOING
// ------------------------------------------------

export type NeoMessageModelType = 'Authenticate' | 'Answer' | 'ResetConversation' | 'RollbackConversation';

export type NeoMessage =
  AuthenticationNeoMessageModel |
  AnswerNeoMessageModel;

export interface NeoMessageModelBase {
  id: string;
  created_at: string; // ISO-8601 date strings
  kind: NeoMessageModelType;
}

// AUTHENTICATION

export interface AuthenticationNeoMessageModel extends NeoMessageModelBase {
  auth_grant: AuthGrantApiModel;
}

// ANSWERS

export interface AnswerNeoMessageModel extends NeoMessageModelBase {
  question_id: string;
  answers: AnswersModel; // <Question Options UUID, 1>
  meta: MessageMetaModel;
}

export interface AnswersModel {
  [key: string]: number;
}

// RESET CONVERSATION MESSAGE

export interface ResetConversationMessage extends NeoMessageModelBase {
}

// ROLLBACK CONVERSATION MESSAGE

export interface RollbackConversationMessage extends NeoMessageModelBase {
  message_id: string;
}

// ------------------------------------------------
// CHAT INCOMING
// ------------------------------------------------

export enum MessageType {
  Statement = 'Statement',
  AnswerView = 'AnswerView',
  MultipleQuestion = 'MultipleQuestion',
  RadioQuestion = 'RadioQuestion',
  LanguageQuestion = 'LanguageQuestion',
  OpenEndedQuestion = 'OpenEndedQuestion',
  GoodBye = 'GoodBye',
  Error = 'Error',
  WebhookExecuted = 'WebhookExecuted',
  ScreenOut = 'ScreenOut'

}

export type QuestionMessageType =
  MessageType.RadioQuestion |
  MessageType.MultipleQuestion |
  MessageType.OpenEndedQuestion |
  MessageType.LanguageQuestion;

export type Message =
  StatementMessage |
  AnswerViewMessage |
  RadioQuestionMessage |
  MultipleQuestionMessage |
  OpenEndedQuestionMessage |
  LanguageQuestionMessage |
  GoodByeMessage |
  WebhookExecutedMessage |
  ScreenOutMessage;

export interface MessageBase {
  id: string;
  createdAt: Date;
  type: MessageType;
  fromHistory: boolean;
}

export interface MessageText {
  nameHtml: string; // HTML
}

// STATEMENT

export interface StatementMessage extends MessageBase, MessageText {
}

// ANSWER VIEW

export interface AnswerViewMessage extends MessageBase, MessageText {
}

// QUESTIONS

export type QuestionMessage =
  RadioQuestionMessage |
  MultipleQuestionMessage |
  LanguageQuestionMessage |
  OpenEndedQuestionMessage;

export interface QuestionMessageBase extends MessageBase, MessageText {
  questionId: string;
}

export interface RadioQuestionMessage extends QuestionMessageBase {
  options: Map<string, QuestionOption>;
  isQuick: boolean;
  bundleNameHtml: string;
}

export interface MultipleQuestionMessage extends QuestionMessageBase {
  options: Map<string, QuestionOption>;
  isQuick: boolean;
  bundleNameHtml: string;
}

export interface OpenEndedQuestionMessage extends QuestionMessageBase {
  options: Map<string, QuestionOption>;
  hasMultipleOptions: boolean;
}

export interface LanguageQuestionMessage extends QuestionMessageBase {
  options: Map<string, QuestionOption>;
  isQuick: boolean;
  bundleNameHtml: string;
}

export interface QuestionOptionImageData {
  alt_text: string;
  size: {width: string, height: string};
  url: string;
}

export interface QuestionOption extends MessageText {
  id: string;
  code: LanguageCode;
  nota: boolean;
  openEnded: boolean;
  image: QuestionOptionImageData;
}

// GOODBYE

export interface GoodByeMessage extends MessageBase {
  originalId: string;
  redirectUrl?: string;
}

// ERROR

export interface ErrorMessage extends MessageBase, MessageText {
  // Legacy
}

// WEBHOOK EXECUTED

export interface WebhookExecutedMessage extends MessageBase, MessageText {
}

// SCREEN OUT

export interface ScreenOutMessage extends MessageBase, MessageText {
}

// ------------------------------------------------
// CHAT ANSWERS
// ------------------------------------------------

export interface Answers {
  questionId: string;
  questionType: QuestionMessageType;
  answer: AnswerValue[];
  languageCode?: LanguageCode;
}

export type AnswerValue = RadioAnswerValue | MultipleAnswerValue | OpenEndedAnswerValue | LanguageAnswerValue;

export interface RadioAnswerValue {
  id: string;
  text?: string;
}

export interface MultipleAnswerValue {
  id: string;
  selected: boolean;
  text?: string;
}

export interface OpenEndedAnswerValue {
  id: string;
  text: string;
}

export interface LanguageAnswerValue {
  id: string;
  code: string;
  text?: string;
}

// ------------------------------------------------
// HELPERS INCOMING
// ------------------------------------------------

// STATEMENT

export function toStatementMessage(data: StatementMessageModel, fromHistory: boolean): StatementMessage {
  return {
    id: data.id,
    createdAt: new Date(data.created_at),
    type: MessageType.Statement,
    nameHtml: data.name_html,
    fromHistory
  };
}

// ANSWER VIEW

export function toAnswerViewMessage(data: AnswerViewMessageModel, fromHistory: boolean): AnswerViewMessage {
  const answersHtml = data.answers.map((answer) => answer.value.length > 0 ? `<p>${answer.value}</p>` : answer.name_html);

  return {
    id: data.id,
    createdAt: new Date(data.created_at),
    type: MessageType.AnswerView,
    nameHtml: answersHtml.join(''),
    fromHistory
  };
}

// GOODBYE

export function toGoodByeMessage(data: GoodByeMessageModel, fromHistory: boolean): GoodByeMessage {
  return {
    id: 'goodby-message-id',
    originalId: data.id,
    createdAt: new Date(data.created_at),
    type: MessageType.GoodBye,
    fromHistory,
    redirectUrl: data.redirect_url,
  };
}

// SCREEN OUT
export function toScreenOutMessage(data: ScreenOutMessageModel, fromHistory: boolean): ScreenOutMessage {
  return {
    id: data.id,
    createdAt: new Date(data.created_at),
    type: MessageType.ScreenOut,
    nameHtml: `<p>Respondent answered ${data.name}.</p><p>Screen-out trigger above terminated the survey</p>`,
    fromHistory
  };
}

// WEBHOOK EXECUTED

export function toWebhookExecutedMessage(data: WebhookExecutedMessageModel, fromHistory: boolean): WebhookExecutedMessage {
  return {
    id: data.id,
    createdAt: new Date(data.created_at),
    type: MessageType.WebhookExecuted,
    nameHtml: data.reason,
    fromHistory
  };
}

// QUESTIONS

export const QuestionMessageTypeMap = new Map<QuestionMessageModelType, MessageType>([
  ['RadioQuestion', MessageType.RadioQuestion],
  ['MultipleQuestion', MessageType.MultipleQuestion],
  ['OpenEndedQuestion', MessageType.OpenEndedQuestion],
  ['LanguageQuestion', MessageType.LanguageQuestion]
]);

export function toQuestionMessage(data: QuestionMessageModel, fromHistory: boolean): QuestionMessage {
  switch (data.question_type) {
    case 'RadioQuestion':
      return toRadioQuestionMessage(data, fromHistory);
    case 'MultipleQuestion':
      return toMultipleQuestionMessage(data, fromHistory);
    case 'OpenEndedQuestion':
      return toOpenEndedQuestionMessage(data, fromHistory);
    case 'LanguageQuestion' :
      return toLanguageQuestionMessage(data, fromHistory);

    default:
      throw new Error(`[Question Model] Question type ${data.question_type} is not defined!`);
  }
}

export function toQuestionMessageBase(data: QuestionMessageModel, fromHistory: boolean): QuestionMessageBase {
  return {
    id: data.id,
    questionId: data.question_id,
    createdAt: new Date(data.created_at),
    type: QuestionMessageTypeMap.get(data.question_type),
    nameHtml: data.name_html,
    fromHistory
  };
}

export function toRadioQuestionMessage(data: QuestionMessageModel, fromHistory: boolean): RadioQuestionMessage {
  const base = toQuestionMessageBase(data, fromHistory);
  const options = toQuestionOptions(data.question_options);

  return Object.assign({}, base, {
    options,
    isQuick: someOptionsAreOpenEnded(Array.from(options.values())) ? false : isQuickQuestionOrPredicate(
      data.display_type,
      () => useQuickTemplate(Array.from(options.values())),
    ),
    bundleNameHtml: data.bundle_name_html || ''
  });
}

export function toMultipleQuestionMessage(data: QuestionMessageModel, fromHistory: boolean): MultipleQuestionMessage {
  const base = toQuestionMessageBase(data, fromHistory);
  const options = toQuestionOptions(data.question_options);

  return Object.assign({}, base, {
    options,
    isQuick: isQuickQuestionOrPredicate(
      data.display_type,
      () => useQuickTemplate(Array.from(options.values())),
    ),
    bundleNameHtml: data.bundle_name_html || ''
  });
}

export function toOpenEndedQuestionMessage(data: QuestionMessageModel, fromHistory: boolean): OpenEndedQuestionMessage {
  const base = toQuestionMessageBase(data, fromHistory);
  const options = toQuestionOptions(data.question_options);

  return {
    ...base,
    options,
    hasMultipleOptions: options.size > 1
  };
}

export function toLanguageQuestionMessage(data: QuestionMessageModel, fromHistory: boolean): LanguageQuestionMessage {
  const base = toQuestionMessageBase(data, fromHistory);
  const options = toQuestionOptions(data.question_options);

  return Object.assign({}, base, {
    options,
    isQuick: useQuickTemplate(Array.from(options.values())),
    bundleNameHtml: data.bundle_name_html || ''
  });
}


export function useQuickTemplate(options: QuestionOption[]): boolean {
  const htmlSearchRegex = /<[^>]+>/g;
  const textOnlyOptions = options.map((option) => option.nameHtml.replace(htmlSearchRegex, ''));

  const optionsCount = options.length;
  const allOptionsAreShort = textOnlyOptions.every((option) => option.length < QUICK_OPTION_LENGTH_LIMIT);
  const sumOptionsTextLength = textOnlyOptions.reduce((sum, option) => sum + option.length, 0);

  return someOptionsContainImages(options) || (
    allOptionsAreShort &&
    optionsCount < QUICK_OPTIONS_COUNT_LIMIT &&
    sumOptionsTextLength < QUICK_SUM_OPTIONS_LENGTH_LIMIT
  );
}

function isQuickQuestionOrPredicate(displayType: QuestionMessageModelDisplayType, predicate: () => boolean): boolean {
  if (displayType === 'auto') {
    return predicate();
  }

  return displayType === 'column';
}

export function someOptionsContainImages(options: QuestionOption[]): boolean {
  return options.some((option) => option.nameHtml.match(/<img/) !== null);
}

export function someOptionsAreOpenEnded(options: QuestionOption[]): boolean {
  return options.some((option) => option.openEnded);
}

export function toQuestionOptions(data: QuestionOptionModel[]): Map<string, QuestionOption> {
  return data.reduce((acc, curr) => {
    const option = toQuestionOption(curr);
    acc.set(curr.id, option);
    return acc;
  }, new Map());
}

export function toQuestionOption(data: QuestionOptionModel): QuestionOption {
  return {
    id: data.id,
    nameHtml: data.name_html,
    nota: data.nota,
    code: data.code || DefaultLanguageCode,
    openEnded: data.open_ended,
    image: data.image
  };
}

// ------------------------------------------------
// HELPERS OUTGOING
// ------------------------------------------------

// ANSWERS

export function toAnswerNeoMessageModel(answers: Answers, meta: MessageMetaModel): AnswerNeoMessageModel {
  if (!answers) {
    console.error('toAnswerNeoMessageModel:: answers: %s, meta: %s', JSON.stringify(answers), JSON.stringify(meta));
    throw new Error('toAnswerNeoMessageModel:: Missing answers!');
  }

  return {
    id: UUID.create4(),
    created_at: (new Date()).toISOString(),
    kind: 'Answer',
    question_id: answers.questionId,
    answers: extractAnswersModel(answers),
    meta
  };
}

export function extractAnswersModel(answers: Answers): AnswersModel {
  switch (answers.questionType) {
    case MessageType.MultipleQuestion:
      return answers.answer
        .filter((val: MultipleAnswerValue) => val.selected === true)
        .reduce((acc, curr) => {
          acc[curr.id] = curr.text ? curr.text : '';
          return acc;
        }, {});

    case MessageType.RadioQuestion: {
      return answers.answer
        .reduce((acc, curr) => {
          acc[curr.id] = curr.text ? curr.text : '';
          return acc;
        }, {});
    }

    case MessageType.LanguageQuestion: {
      return answers.answer
        .reduce((acc, curr) => {
          acc[curr.id] = '';
          return acc;
        }, {});
    }

    case MessageType.OpenEndedQuestion:
      return answers.answer
        .reduce((acc, curr: OpenEndedAnswerValue) => {
          acc[curr.id] = curr.text;
          return acc;
        }, {});
  }
}

// ------------------------------------------------
// HELPERS OTHER
// ------------------------------------------------

export function isQuestionMessage(message: Message): boolean {
  switch (message.type) {
    case MessageType.RadioQuestion:
    case MessageType.MultipleQuestion:
    case MessageType.LanguageQuestion:
    case MessageType.OpenEndedQuestion:
      return true;

    default:
      return false;
  }
}

// ANSWERS TEXTS
export interface AnswersTexts {
  nameHtml: string[];
  imageTitles: string[];
}

export function extractSelectedAnswersTexts(options: Map<string, QuestionOption>, answers: Answers): AnswersTexts {
  if (!options || !answers) {
    return { nameHtml: [], imageTitles: [] };
  }

  const selectedAnswers = extractSelectedAnswers(answers);

  const selectedOptions = selectedAnswers
    .map((selectedOption => options.get(selectedOption.id)))
    .filter((option) => !!option)
    .map((option) => option.openEnded ? `<p>${extractAnswerText(answers, option.id)}</p>` : option.nameHtml);

  return {
    nameHtml: selectedOptions,
    imageTitles: new Array(selectedOptions.length),
  };
}

export function extractAnswerText(answers: Answers, id: string): string {
  switch (answers.questionType) {
    case MessageType.OpenEndedQuestion:
    case MessageType.RadioQuestion:
    case MessageType.LanguageQuestion:
    case MessageType.MultipleQuestion:
      return answers.answer.find((item: OpenEndedAnswerValue) => item.id === id).text;

    default:
      throw new Error(`[Message Service] Can't extract answer text, question type ${answers.questionType} is not defined!`);
  }
}

export function extractSelectedAnswers(answers: Answers): AnswerValue[] {
  switch (answers.questionType) {
    case MessageType.RadioQuestion:
    case MessageType.LanguageQuestion:
    case MessageType.OpenEndedQuestion:
      return answers.answer;

    case MessageType.MultipleQuestion:
      return answers.answer.filter((item: MultipleAnswerValue) => item.selected);

    default:
      throw new Error(`[Message Service] Can't extract selected answers, question type ${answers.questionType} is not defined!`);
  }
}
