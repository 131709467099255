<div
  class="container answer-input-block text-center"
>
  <button
    pm-button
    (click)="handleClick()"
    type="submit"
    class="primary block-md box-shadow-1"
    [isDisabled]="isDisabled"
    [isLoading]="isLoading">
    {{submitButtonName | async}}
  </button>
</div>
