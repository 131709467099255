import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiError, ErrorType } from '@pm/core/models/error.model';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';

/**
 * This interceptor converts all BE errors to instances of ApiError for common error handling!
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((response: HttpErrorResponse) => {
            const error = new ApiError(response);

            console.error(error);

            switch (error.type) {
              case ErrorType.ExceptionCloudFlareRateLimiting:
                this.injector.get<CoreStateFacade>(CoreStateFacade).rateLimitActive();
                break;
              default:
            }

            return throwError(error);
          }
        )
      );
  }
}
