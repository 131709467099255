<div
  #container
  id="pm-susi__container"
>

  <div
    #purpleBg
    id="pm-susi__purple-bg"
  ></div>

  <svg
    #flippingCoin
    id="pm-susi__coin"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 31 20"
  >
    <defs>
      <ellipse id="a" cx="192.39" cy="308.04" rx="16.5" ry="6"/>
      <filter id="b" width="103%" height="108.3%" x="-1.5%" y="-4.2%" filterUnits="objectBoundingBox">
        <feOffset
          dx="-1"
          dy="-1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.996078431 0 0 0 0 0.545098039 0 0 0 0 0.294117647 0 0 0 1 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="rotate(-28 -493.72 514)">
      <use fill="#F5C131" xlink:href="#a"/>
      <use fill="#000" filter="url(#b)" xlink:href="#a"/>
    </g>
  </svg>

  <div
    #coinShadowBg
    id="pm-susi__coin-bg-shadow"
  ></div>
  <div
    #coinBg
    id="pm-susi__coin-bg"
  ></div>

  <svg
    #logo
    id="pm-susi__logo"
    width="70"
    height="96"
    viewBox="0 0 406 560"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M102.39 17.62l61.88-13.2c105.72-22.54 211.7 43.33 236.3 146.82 12.39 52.2 3.42 106-25.2 151.46-28.62 45.49-73.17 76.69-125.48 87.89l-28.9 6.16a51.13 51.13 0 0 1-41.63-9.14c-10.1-7.52-17.23-19-20.07-32.42l-12.84-60.54-44.75 9.54c-37.35 7.95-74.12-16.04-82.03-53.43L2.84 171.4c-14.82-69.95 29.85-138.93 99.55-153.78zm79.19 222.77l34.76-7.4c19.36-4.13 32.4-22.07 29.75-40.84a35.98 35.98 0 0 0-15.1-24.6 35.57 35.57 0 0 0-28.15-5.73l-24.12 5.15a12.5 12.5 0 0 0-9.57 14.77l12.43 58.65zm45.75 37.01l-36.26 7.73 13.14 62.08a6.1 6.1 0 0 0 7.3 4.78l25.77-5.48c40.5-8.7 76.58-33.88 99-69.2 21.98-34.68 29.19-75.25 20.19-114.37C338.03 82.66 257.6 31.29 177.27 48.4l-65.4 13.95c-45.1 9.62-74.01 54.26-64.41 99.54l16.81 79.34a23.56 23.56 0 0 0 27.94 18.2l44.76-9.54-12.68-59.83c-6.53-30.73 13.13-61.07 43.77-67.6l23.18-4.94c43.84-9.35 86.9 16.41 98.04 58.65a81.51 81.51 0 0 1-9.34 63.96 81.16 81.16 0 0 1-52.61 37.26zM35.4 495.46c-9.32-43.97 18.73-87.3 62.56-96.64 43.8-9.33 87 18.81 96.31 62.77 9.3 43.92-18.74 87.3-62.52 96.62-43.84 9.34-87.05-18.83-96.35-62.75zm44.6-9.5a35.65 35.65 0 0 0 42.26 27.51 35.77 35.77 0 0 0 27.44-42.38 35.68 35.68 0 0 0-42.26-27.52A35.76 35.76 0 0 0 80 485.95z"
      fill="#FFF"
      fill-rule="evenodd"
    />
  </svg>

  <div
    #whiteBg
    id="pm-susi__white-bg"
  ></div>
</div>
