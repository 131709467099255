import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BubbleType } from '@pm/chat/models/bubble.model';
import { MessageType, QuestionMessage } from '@pm/chat/models/message.model';

@Component({
  selector: 'pm-answered-question-message',
  templateUrl: './answered-question-message.component.html',
  styleUrls: ['./answered-question-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnsweredQuestionMessageComponent {
  @Input() message: QuestionMessage;
  @Input() isTesting: boolean;
  @Input() isRightToLeft = false;

  @Output() rollbackConversation = new EventEmitter();

  readonly bubbleType = BubbleType.Question;

  get text(): string {
    return this.message.nameHtml;
  }

  get isLanguageQuestion(): boolean {
    return this.message.type === MessageType.LanguageQuestion;
  }

  triggerRollbackConversation(): void {
    this.rollbackConversation.emit();
  }
}
