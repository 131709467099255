import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import {
  AdTrackingService,
  AffiliateService,
  BrowserStorageServiceProvider,
  BugsnagService,
  EncryptService,
  EnvironmentService,
  ImgPreloadService,
  RegistrationTrackingService,
  RoutingService,
  WindowRefService,
} from './services';
import { ErrorInterceptor } from '@pm/core/interceptors/error.interceptor';
import { UrlInterceptor } from '@pm/core/interceptors/url.interceptor';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { DebugModule } from '@pm/core/debug/debug.module';
import { SusiAnimationService } from '@pm/core/services/susi-animation.service';
import { AnimationDebugModule } from '@pm/core/animation-debug/animation-debug.module';
import { PersistentStorageModule } from '@pm/core/persistent-storage/persistent-storage.module';
import { VersionUpdateCheckService } from '@pm/core/services/version-update-check.service';
import { SocialAuthService } from '@pm/core/services/social-auth.service';
import { FontawesomeCoreModule } from '@pm/core/fontawesome-core/fontawesome-core.module';
import { LngDetectorService } from '@pm/core/services/lng-detector.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    DebugModule,
    AnimationDebugModule,
    PersistentStorageModule,
    FontawesomeCoreModule,
  ],
  declarations: [],
  exports: [
    CommonModule,
    HttpClientModule,
    DebugModule,
    AnimationDebugModule,
  ],
  providers: [
    WindowRefService,
    AffiliateService,
    AdTrackingService,
    RegistrationTrackingService,
    BugsnagService,
    BrowserStorageServiceProvider,
    SocialAuthService,

    CoreStateFacade,
    RoutingService,
    SusiAnimationService,
    ImgPreloadService,
    EnvironmentService,
    EncryptService,
    VersionUpdateCheckService,
    LngDetectorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    }
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    susiAnimationService: SusiAnimationService,
    imgPreloadService: ImgPreloadService,
    versionUpdateCheckService: VersionUpdateCheckService,
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }

    susiAnimationService.initListener();
    imgPreloadService.init();
    versionUpdateCheckService.init();
  }
}
