<div class="container pb-md-0 pb-1rem">
  <div class="row">
    <div class="col-md-3 d-flex">
      <div class="m-auto text-center py-1rem">
        <img
          src="../../../assets/img/emailreg-1.gif"
          alt="email"
          class="img-fluid pm-notification__img"
        />
      </div>
    </div>

    <div class="col-md-6 d-flex m-auto">
      <div >
        <h2 class="mt-1rem m-md-0 mb-0">You’re all set!</h2>

        <p class="mt-_5rem mb-1rem mb-md-0">
          Thank you for confirming your email.
        </p>
      </div>
    </div>

    <div class="col-md-3 d-flex m-auto">
      <button
        pm-button
        class="btn-jonno block"
        (click)="clickButton()"
        [isLoading]="buttonLoading$ | async"
      >
        Let’s Go!
      </button>
    </div>
  </div>
</div>
