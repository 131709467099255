import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { DefaultLanguageCode, LanguageCode } from '@pm/localisation/models/localisation.model';

// ------------------------------------------------------------------------
// Actions
// ------------------------------------------------------------------------

export const LocalisationActions = {
  UpdateLanguage: createAction('[Localisation] Update Language', props<{language: LanguageCode, isRightToLeft: boolean}>()),
};

// ------------------------------------------------------------------------
// Reducers
// ------------------------------------------------------------------------

export interface LocalisationState {
  language: LanguageCode;
  isRightToLeft: boolean;
}

const initialState: LocalisationState = {
  language: DefaultLanguageCode,
  isRightToLeft: false,
};

const localisationReducer = createReducer(
  initialState,
  on(LocalisationActions.UpdateLanguage, (state, { language, isRightToLeft }) => ({
    ...state,
    language: language,
    isRightToLeft: isRightToLeft,
  })),
);

export function LocalisationReducer(state: LocalisationState | undefined, action: Action): LocalisationState {
  return localisationReducer(state, action);
}

// ------------------------------------------------------------------------
// Selectors
// ------------------------------------------------------------------------
export const localisationState = createFeatureSelector<LocalisationState>('localisation');

export const LocalisationSelectors = {
  Language: createSelector(localisationState, (state: LocalisationState) => state.language),
  IsRightToLeft: createSelector(localisationState, (state: LocalisationState) => state.isRightToLeft),
};
