import { NgModule } from '@angular/core';

import { PublicRoutingModule } from './public-routing.module';
import { SharedModule } from '@pm/shared/shared.module';

import {
  PublicComponent,
  NewPublicComponent,
  ExchangeComponent
} from './containers';
import { InfoPagesModule } from '../info-pages/info-pages.module';
import { AnimationDebugModule } from '@pm/core/animation-debug/animation-debug.module';

@NgModule({
  imports: [
    SharedModule,
    InfoPagesModule,
    PublicRoutingModule,
    AnimationDebugModule,
  ],
  declarations: [
    PublicComponent,
    NewPublicComponent,
    ExchangeComponent,
  ],
  exports: [
    SharedModule
  ],
})
export class PublicModule {
}
