import { Injectable } from '@angular/core';

import { WindowRefService } from '@pm/core/services/window-ref.service';
import { PersistentStorageStrategyService } from '@pm/core/persistent-storage/index';

@Injectable()
export class PSSDbService implements PersistentStorageStrategyService {

  private readonly conf;
  private readonly _window;
  private readonly dbHandle;

  constructor(
    private win: WindowRefService,
  ) {
    this.conf = {
      DB_NAME: 'pp',
      DB_VERSION: 1,
      DB_STORE: 'pp'
    };

    this._window = this.win.nativeWindow;

    this.dbHandle = this.getDatabaseHandle(this.conf.DB_STORE, this.conf.DB_VERSION, this.conf.DB_NAME);
  }

  set(key: string, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbHandle
        .then((db) => {
          // Store data using the requested storage key
          const request = db
            .transaction([this.conf.DB_NAME], 'readwrite')
            .objectStore(this.conf.DB_NAME)
            .put(value, key);

          request.onsuccess = (event) => resolve(event);

          request.onerror = (e) => reject(e);
        })
        // IndexedDB not supported / error case
        .catch((e) => reject(e));
    });
  }

  get(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.dbHandle
        .then((db) => {
          const request = db
            .transaction([this.conf.DB_NAME], 'readonly')
            .objectStore(this.conf.DB_NAME)
            .get(key);

          request.onsuccess = (event) => {
            try {
              if (event.target.result) {
                // Valid value for key returned
                resolve(event.target.result);
              } else {
                // No data for this key stored
                reject();
              }
            } catch (error) {
              reject();
            }
          };
          request.onerror = (e) => reject(e);
        })
        // IndexedDB not supported / error case
        .catch((e) => reject(e));
    });
  }

  remove(key: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbHandle
        .then((db) => {
          // Store data using the requested storage key
          const request = db
            .transaction([this.conf.DB_NAME], 'readwrite')
            .objectStore(this.conf.DB_NAME)
            .delete(key);

          request.onsuccess = (event) => resolve(event);
          request.onerror = (e) => reject(e);
        })
        // IndexedDB not supported / error case
        .catch((e) => reject(e));
    });
  }

  private isSupported(): boolean {
    return 'indexedDB' in this._window;
  }

  /**
   * Return a handle to an initialised and valid IndexedDB object.
   * Returns a promise resolving to the db handle.
   */
  private getDatabaseHandle(DB_STORE: string, DB_VERSION: number, DB_NAME: string): Promise<any> {

    if (!this.isSupported()) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      try {
        const dbOpenRequest = this._window.indexedDB.open(DB_STORE, DB_VERSION);

        // IndexedDB schema change (or create) handler
        dbOpenRequest.onupgradeneeded = (event) => {
          const db = event.target.result;

          // Create store for this key
          if (!db.objectStoreNames.contains(DB_NAME)) {
            db.createObjectStore(DB_NAME);
          }
        };

        // IndexedDB database open success
        dbOpenRequest.onsuccess = (event) => {
          // Resolve with a handle to the DB
          resolve(event.target.result);
        };

        // IndexedDB database open error
        dbOpenRequest.onerror = (event) => {
          reject(event);
        };

      } catch (e) {
        reject(e);
      }
    });
  }
}
