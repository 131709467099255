import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@angular/compiler';

import { environment } from '@messenger-env/environment';
import { AppModule } from '@pm/app.module';

if (environment.enableProdMode) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error('platformBrowserDynamic.bootstrapModule::', err));
