import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';

import { Answers, MultipleAnswerValue, MultipleQuestionMessage, QuestionMessageType, QuestionOption } from '@pm/chat/models/message.model';
import { MessageMeta } from '@pm/chat/models/message-meta.model';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { Power3, TimelineLite } from 'gsap';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'pm-multiple-question-message',
  templateUrl: './multiple-question-message.component.html',
  styleUrls: ['./multiple-question-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleQuestionMessageComponent extends AbstractComponent implements OnInit {
  @ViewChild('wrapper', {static: true}) wrapper: ElementRef;
  @ViewChild('question', {static: true}) question: ElementRef;
  @ViewChild('answers', {static: true}) answers: ElementRef;

  @Input() message: MultipleQuestionMessage;
  @Input() answersInput: Answers;
  @Input() isError: boolean;
  @Input() isRightToLeft = false;

  @Output() answerChange = new EventEmitter<{ answers: Answers, meta: Partial<MessageMeta> }>();

  form: FormGroup;
  openEndedValuesForm: FormGroup;

  private oldValues: boolean[];
  private isIndecisive = false; // This is used for tracking & SL score calculation on BE
  private tl = new TimelineLite();

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  get meta(): Partial<MessageMeta> {
    return {
      quick: this.isQuick,
      direct: false,
      indecisive: this.isIndecisive
    };
  }

  get text(): string {
    return this.message.nameHtml;
  }

  get isQuick(): boolean {
    return this.message.isQuick;
  }

  get isBundleQuestion(): boolean {
    return this.message.bundleNameHtml.length > 0;
  }

  get questionStyle(): string {
    return this.isError ? 'pm-question-active-code-error' : 'pm-question-active-code-info';
  }

  get questionOptions(): QuestionOption[] {
    return Array.from(this.message.options.values());
  }

  markForCheck() {
    this.cdr.markForCheck();
  }

  ngOnInit() {
    this.form = this.createForm(this.questionOptions);
    this.openEndedValuesForm = this.createOpenEndedValuesForm(this.questionOptions);
    this.oldValues = Object.values(this.form.getRawValue());

    const notaKeys = this.notaKeys(this.questionOptions);
    const notNotaKeys = this.notNotaKeys(this.questionOptions);

    merge(
      this.notaChanges$(notaKeys, this.form).pipe(tap((key: string) => this.uncheckAllExcept(key, this.form))),
      this.notNotaChanges$(notNotaKeys, this.form).pipe(tap(() => this.uncheckNota(notaKeys, this.form))),
      merge(this.form.valueChanges, this.openEndedValuesForm.valueChanges)
        .pipe(
          tap(() => this.checkIfIndecisive(this.form.getRawValue())),
          tap(() => this.answerChange.emit({answers: this.buildAnswer(this.form.getRawValue(), this.openEndedValuesForm.getRawValue(), this.questionOptions), meta: this.meta})),
        ),
    )
      .pipe(
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  open(): Promise<void> {
    return new Promise((resolve) => {
      this.tl
        .to(this.question.nativeElement, .5, {opacity: 1, ease: Power3.easeOut})
        .eventCallback('onComplete', resolve.bind(this))
      ;
    });
  }

  close(): Promise<void> {
    return new Promise((resolve) => {
      this.answers.nativeElement.classList.add('d-none');

      this.tl
        .to(this.wrapper.nativeElement, .25, {opacity: 0, ease: Power3.easeOut})
        .eventCallback('onComplete', resolve.bind(this));
    });
  }

  inputIsActive(index: number): boolean {
    return this.form.getRawValue()[index];
  }

  areEmptyAnswerOptions(): boolean {
    return Object.values(this.form.getRawValue())
      .some((checked, i) => {
        const formControl = this.openEndedValuesForm.get(i.toString());
        return this.isOpenEndedOption(i.toString()) ? checked && formControl.value.length === 0 && formControl.touched : false;
    });
  }

  // ------------------------------------------------------------------------------------------------------------

  private isOldImage(questionOption: QuestionOption): boolean {
    const htmlText = questionOption.nameHtml;
    const altText = htmlText.substring(htmlText.indexOf('alt="') + 5, htmlText.lastIndexOf('"'));
    return htmlText.indexOf('alt="') !== -1 ? true : false;
  }

  private isNewImage(questionOption: QuestionOption): boolean {
    return questionOption?.image?.size ? true : false;
  }

  private isImage(questionOption: QuestionOption) {
    return (this.isOldImage(questionOption) || this.isNewImage(questionOption)) ? true : false;
  }

  private checkIfIndecisive(values: object) {
    if (this.isIndecisive) {
      return;
    }

    const newValues = Object.values(values);
    const isIndecisive = newValues.some((item, index) => !item && this.oldValues[index]);
    this.oldValues = newValues as boolean[];

    this.isIndecisive = isIndecisive;
  }

  private buildAnswer(checkedValues: object, openEndedValues: object, options: QuestionOption[]): Answers {
    const answer = Object.keys(checkedValues)
      .map((key) => ({
        id: options[key].id,
        selected: checkedValues[key],
        ...options[key].openEnded && {text: openEndedValues[key]}
      }));

    return {
      questionId: this.message.questionId,
      questionType: <QuestionMessageType>this.message.type,
      answer
    };
  }

  private uncheckNota(notaKeys: string[], form: FormGroup) {
    notaKeys
      .map((key) => form.controls[key])
      .filter((control) => control.value)
      .forEach((control) => control.setValue(false));
  }

  private uncheckAllExcept(thisKey: string, form: FormGroup) {
    const formVal = form.getRawValue();

    Object.keys(formVal)
      .forEach((key) => {
        formVal[key ] = thisKey === key;
      });

    form.setValue(formVal);
  }

  private isOpenEndedOption(key: string) {
    return this.questionOptions[key].openEnded;
  }

  private createForm(options: QuestionOption[]): FormGroup {

    const stashedAnswers = {};

    if (this.answersInput && this.answersInput.answer) {
      this.answersInput.answer.forEach((a) => stashedAnswers[a.id] = !!(a as MultipleAnswerValue).selected);
    }

    const controlsConfig = options.reduce((config, option, index) => {
      config[index] = stashedAnswers[option.id] || false;
      return config;
    }, {});

    return this.fb.group(controlsConfig);
  }

  private createOpenEndedValuesForm(options: QuestionOption[]): FormGroup {
    return this.fb.group(
      options.reduce((config, option, index) => {
        if (option.openEnded) { config[index] =  ''; }
        return config;
      }, {})
    );
  }

  private notaKeys(options: QuestionOption[]): string[] {
    return Object.keys(options)
      .filter((key) => options[key].nota);
  }

  private notNotaKeys(options: QuestionOption[]): string[] {
    return Object.keys(options)
      .filter((key) => !options[key].nota);
  }

  private notaChanges$(controlKeys: string[], form: FormGroup): Observable<string> {
    return merge(...controlKeys.map((key) =>
        form.controls[key].valueChanges
          .pipe(
            distinctUntilChanged(),
            filter((val) => val),
            map(() => key)
          )
      ),
    );
  }

  private notNotaChanges$(controlKeys: string[], form: FormGroup): Observable<string> {
    return merge(...controlKeys.map((key) =>
      form.controls[key].valueChanges
        .pipe(
          distinctUntilChanged(),
          filter((val) => val),
          map(() => key),
        )
    ));
  }
}
