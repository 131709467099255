<div class="row">
  <div class="col">
    <h2>Cards</h2>
  </div>
</div>
<div class="row">
  <div class="col-md">
    <div class="row">
      <div class="col-4">
      </div>
      <div class="col-6">

        <pm-card
          imageSrc="assets/svg/lower-treshold-illy.svg"
          theme="theme-purple"
          imagePos="left"
          cardTitle="We have lowered the cash out threshold"
        >
          <ng-container class="content">
            Would you like to cash out faster? Now you can!
            We have lowered the cash out threshold to just 3000 credits.
          </ng-container>
          <ng-container class="button">
            <button
              pm-button
              id="cardNewCashoutNowButton"
              class="btn-md btn-info mt-1rem"
            >
              Cash out now
            </button>
          </ng-container>
        </pm-card>
      </div>
      <div class="col-6">

        <pm-card
          imageSrc="assets/svg/lower-treshold-illy.svg"
          theme="theme-purple"
          imagePos="right"
          cardTitle="We have lowered the cash out threshold"
        >
          Would you like to cash out faster? Now you can!
          We have lowered the cash out threshold to just 3000 credits.
        </pm-card>
      </div>
    </div>
  </div>
</div>
