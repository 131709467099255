import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ApiError } from '@pm/core/models/error.model';
import { HttpResponse } from '@angular/common/http';

// Exponential Backoff Formula by Prof. Douglas Thain
// http://dthain.blogspot.co.uk/2009/02/exponential-backoff-in-distributed.html
export function getBackoffDelay(reconnectAttempts: number, initialTimeout: number, maxTimeout: number): number {
  const RANDOM = Math.random() + 1;

  return Math.floor(Math.min(RANDOM * initialTimeout * Math.pow(2, reconnectAttempts), maxTimeout));
}

interface GenericRetryStrategyConfig {
  maxRetryDuration?: number;
  scalingDuration?: number;
  maxRetries?: number | null;
  excludedStatusCodes?: number[];
  includedStatusCodes?: number[];
}

export const extractStatus = (response: ApiError | HttpResponse<any>): number | null => {
  if (response instanceof ApiError) {
    return response.originalResponse.status;
  }

  if (response.status) {
    return response.status;
  }

  return null;
};

export const genericRetryStrategy = ({
                                       maxRetryDuration = 50000,
                                       scalingDuration = 2000,
                                       maxRetries = null,
                                       excludedStatusCodes = [],
                                       includedStatusCodes = [],
                                     }: GenericRetryStrategyConfig = {}) => (attempts: Observable<ApiError>) => {
  return attempts
    .pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;

        const status = extractStatus(error);

        // console.log(`utils::genericRetryStrategy:: Attempt ${retryAttempt}`);

        // Retry only if response matches statuscodes
        if (
          maxRetries && retryAttempt > maxRetries ||
          excludedStatusCodes.find(e => e === status) ||
          (includedStatusCodes.length > 0) && includedStatusCodes.find(e => e === status) === undefined
        ) {
          // console.log('utils::genericRetryStrategy::throwError:: ', excludedStatusCodes, includedStatusCodes);

          return throwError(error);
        }

        const retryTTL = getBackoffDelay(retryAttempt, scalingDuration, maxRetryDuration);

        // console.log(`utils::genericRetryStrategy:: Attempt ${retryAttempt}: retrying in ${retryTTL}ms`);

        return timer(retryTTL);
      }),
      // finalize(() => console.log('utils::genericRetryStrategy:: DONE'))
    );
};

// The below function is taken from here:
// https://github.com/tylerjpeterson/ios-inner-height/blob/master/dist/ios-inner-height.js
export const getInnerHeightFactory = () => {
  const essenceOfEvil = (window as any);

  // Non-iOS browsers return window.innerHeight per usual.
  // No caching here since browsers can be resized, and setting
  // up resize-triggered cache invalidation is not in scope.
  if (!navigator.userAgent.match(/iphone|ipod|ipad/i)) {
    /**
     * Avoids conditional logic in the implementation
     * @return {number} - window's innerHeight measurement in pixels
     */
    return () => window.innerHeight;
  }

  // Store initial orientation
  const axis = Math.abs(essenceOfEvil.orientation);
  // And hoist cached dimensions
  const dims = { w: 0, h: 0 };

  /**
   * Creates an element with a height of 100vh since iOS accurately
   * reports vp height (but not essenceOfEvil.innerHeight). Then destroy it.
   */
  const createRuler = () => {
    let ruler = document.createElement('div');

    ruler.style.position = 'fixed';
    ruler.style.width = '0';
    ruler.style.top = '0';
    ruler.style.bottom = '0';

    document.documentElement.appendChild(ruler);

    // Set cache conscientious of device orientation
    dims.w = axis === 90 ? ruler.offsetHeight : essenceOfEvil.innerWidth;
    dims.h = axis === 90 ? essenceOfEvil.innerWidth : ruler.offsetHeight;

    // Clean up after ourselves
    document.documentElement.removeChild(ruler);
    ruler = null;
  };

  // Measure once
  createRuler();

  /**
   * Returns window's cached innerHeight measurement
   * based on viewport height and device orientation
   * @return {number} - window's innerHeight measurement in pixels
   */
  return () => Math.abs(essenceOfEvil.orientation) !== 90 ? dims.h : dims.w;
};
