import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pm-card',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() imageSrc: string | null = null;
  @Input() imageAlt: string | null = null;
  @Input() imagePos: 'left' | 'right' | 'top' = 'top';
  @Input() theme: string | null = null;
  @Input() cardTitle: string | null = null;
}
