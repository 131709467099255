<div class="row">
  <div class="col mb-2rem">
    <h2>Buttons</h2>
  </div>
</div>
<div class="row">
  <div class="col-md">
    <div class="row">
      <div class="col">
        <h3>Regular buttons</h3>
        <button
          pm-button
          class="mr-1rem my-1rem"
        >
          A regular button
        </button>
        <button
          pm-button
          [isLoading]="true"
        >
          A loading button
        </button>
      </div>
      <div class="col">
        <h3>Md buttons</h3>
        <button
          pm-button
          class="mr-1rem my-1rem btn-md"
        >
          A regular button
        </button>
        <button
          pm-button
          class="btn-md"
          [isLoading]="true"
        >
          A loading button
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h3 class="mt-1rem">A block button</h3>

        <button
          pm-button
          class="block"
        >
          Redeem credits 🤑&ensp;
        </button>

        <h3 class="mt-1rem">A block button on medium sized screens</h3>

        <button
          pm-button
          class="block-md"
        >
          Redeem credits 🤑&ensp;
        </button>
      </div>
    </div>
  </div>
  <div class="col-md">
    <div class="row">
      <div class="col">
        <h3>Button colors</h3>
        <button
          pm-button
          class="m-2"
        >
          (default)
        </button>
        <button
          pm-button
          class="btn-destructive m-2"
        >
          .btn-destructive
        </button>

        <button
          pm-button
          class="primary m-2"
        >
          .primary
        </button>
        <button
          pm-button
          class="btn-fb m-2"
        >
          .btn-fb
        </button>
        <button
          pm-button
          class="btn-google m-2"
        >
          .btn-google
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h3>Outline button</h3>

        <button
          pm-button
          class="outline"
        >
          I'm an outline
        </button>
        &nbsp;
        <button
          pm-button
          [isLoading]="true"
          class="outline"
        >
          I'm a loading outline
        </button>

        <br>
        <br>
        <button
          pm-button
          class="outline btn-destructive"
        >
          I'm a destructive outline
        </button>
        &nbsp;
        <button
          pm-button
          [isLoading]="true"
          class="outline btn-destructive"
        >
          I'm a loading outline
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2rem pm-public">
  <div class="col-md">
    <h3>Legacy Social buttons</h3>
    <button
      pm-button
      (click)="isSocialLoading = true"
      [isLoading]="isSocialLoading"
      [iconLeft]="['fab','facebook']"
      class="btn-fb block social-style my-1"
      iconRight="chevron-right"
    >
      Continue with Facebook
    </button>
    <button
      pm-button
      (click)="isSocialLoading = true"
      [isLoading]="isSocialLoading"
      [iconLeft]="['fab','google']"
      class="btn-google block social-style my-1"
      iconRight="chevron-right"
    >
      Continue with Google
    </button>
    <a
      pm-button
      class="primary block social-style my-1"
      iconLeft="envelope"
      iconRight="chevron-right"
      [isLoading]="isSocialLoading"
    >
      Sign in with your email
    </a>
  </div>
  <div class="col-md">
    <h3>Answer input button</h3>
    <button
      pm-button
      class="primary answer-input-button m-2"
      [isLoading]="false"
    >
      <fa-icon icon="paper-plane" aria-hidden="true"></fa-icon>
    </button>
    <button
      pm-button
      class="primary answer-input-button m-2"
      [isLoading]="true"
    >
      <fa-icon icon="paper-plane" aria-hidden="true"></fa-icon>
    </button>
    <button
      pm-button
      class="primary answer-input-button m-2"
      [isDisabled]="true"
    >
      <fa-icon icon="paper-plane" aria-hidden="true"></fa-icon>
    </button>
  </div>
</div>
