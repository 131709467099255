import { ChangeDetectorRef, Directive, EventEmitter, Output } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbstractComponent } from '@pm/core/abstracts/abstract.component';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export class AbstractNotificationComponent extends AbstractComponent {
  @Output() buttonClick = new EventEmitter<void>();

  buttonLoading$ = new Subject<boolean>();

  constructor(
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  clickButton(): void {
    this.buttonClick.emit();

    this.buttonLoading$.next(true);
    timer(1500)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.buttonLoading$.next(false);
      });
  }

  setProperty(propMap: object): void {
    Object.assign(this, propMap);
    this.cdr.markForCheck();
  }
}
