import { Injectable } from '@angular/core';
import {
  ErrorMessageModel,
  GoodByeMessageModel,
  HistoryMessageModel,
  LanguageSwitchedMessageModel,
  LevelsMessageModel,
  Message,
  MessageModel,
  toAnswerViewMessage,
  toGoodByeMessage,
  toQuestionMessage,
  toScreenOutMessage,
  toStatementMessage,
  toWebhookExecutedMessage
} from '@pm/chat/models/message.model';
import { ChatStateFacade } from '@pm/chat/ngrx/chat-facade.service';
import { BugsnaggableError, BugsnagService, WindowRefService } from '@pm/core/services';
import { LocalisationService } from './localisation.service';

/**
 * Single purpouse service: Will handle a message sent from NEO and add it to NGRX store accordingly
 */
@Injectable()
export class NeoMessageHandlerService {
  constructor(
    private readonly bugsnag: BugsnagService,
    private readonly chatFacade: ChatStateFacade,
    private readonly localisationService: LocalisationService,
    private readonly win: WindowRefService,
  ) {
  }

  handle(message: MessageModel): void {
    switch (message.kind) {
      case 'Heartbeat':
        // Ignore
        break;

      case 'LanguageSwitched':
        this.handleLanguageSwitched(message);
        break;

      case 'History':
        this.handleHistory(message);
        break;

      case 'Error':
        this.handleError(message);
        break;

      case 'GoodBye':
        this.handleDefault(message);
        this.handleGoodbye(message);
        break;

      default:
        this.handleDefault(message);
        break;
    }
  }

  // -------------------------------------------------------------------------------------------

  private handleError(message: ErrorMessageModel): void {
    console.error('NMHS::reportNeoError::', JSON.stringify(message));

    const errorKind = (message && message.error && message && message.error.kind);
    const errorMessage = (message && message.error && message.error.messages && message.error.messages.join(', ')) || 'UNKNOWN';

    this.chatFacade.updateLastError(errorMessage);

    if (errorKind !== 'Misunderstood') { // Allow only validation error to skip throwing an exception
      throw new BugsnaggableError(`NEO Error [${errorKind} - ${errorMessage}]`, { groupingHash: `NEO Error [${errorKind}` });
    }
  }

  private handleHistory(message: HistoryMessageModel): void {
    this.chatFacade.clearMessenger();
    this.chatFacade.setProcessingHistory(true);

    const languageSwitchedMessage = message.messages.find((msg) => msg.kind === 'LanguageSwitched');
    if (languageSwitchedMessage) {
      this.handleLanguageSwitched(<LanguageSwitchedMessageModel> languageSwitchedMessage);
    }

    message
      .messages
      .filter((msg) => (
        [
          // Only allow following messages while processing history
          'Statement',
          'Question',
          'AnswerView'
        ].indexOf(msg.kind) > -1)
      )
      .forEach((msg) => this.handleDefault(msg, true));

    this.chatFacade.setProcessingHistory(false);
  }

  private handleDefault(message: MessageModel, fromHistory: boolean = false): void {
    try {
      const msg = this.generateMessage(message, fromHistory);
      this.chatFacade.addMessage(msg);
    } catch (e) {
      console.error('NMHS::handleDefault::', e);
      this.bugsnag.reportError(e);
    }
  }

  private handleGoodbye(message: GoodByeMessageModel): void {
    if (message.redirect_url) {
      this.win.redirectTo(message.redirect_url);
    }
  }

  private handleLanguageSwitched(message: LanguageSwitchedMessageModel): void {
    this.localisationService.setByLanguageCode(message.language);
  }

  private generateMessage(data: MessageModel, fromHistory: boolean): Message {
    switch (data.kind) {
      case 'Statement':
        return toStatementMessage(data, fromHistory);

      case 'Question':
        return toQuestionMessage(data, fromHistory);

      case 'AnswerView':
        return toAnswerViewMessage(data, fromHistory);

      case 'GoodBye':
        return toGoodByeMessage(data, fromHistory);

      case 'WebhookExecuted':
        return toWebhookExecutedMessage(data, fromHistory);

      case 'ScreenOut':
        return toScreenOutMessage(data, fromHistory);

      // case 'Error':
      //   return toErrorMessage(data, fromHistory);

      default:
        throw new Error(`NEO response message type [${data.kind}] is not defined!`);
    }
  }
}
