import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CampaignContainerComponent } from '@pm/campaign/containers/campaing-container/campaign-container.component';
import { CampaignCodeRedirectGuard } from '@pm/campaign/guards/campaign-code-redirect-guard.service';

export const routes: Routes = [

  {
    path: ':linkType/:campaignCode/p/:panelCode',
    canActivate: [
      CampaignCodeRedirectGuard
    ],
    component: CampaignContainerComponent,
  },
  {
    path: ':linkType/:campaignCode',
    canActivate: [
      CampaignCodeRedirectGuard
    ],
    component: CampaignContainerComponent,
  },
  {
    path: ':linkType',
    canActivate: [
      CampaignCodeRedirectGuard
    ],
    component: CampaignContainerComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class CampaignRoutingModule {
}
