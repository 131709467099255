<div
  #wrapper
  class="pm-question-container"
>
  <div
    #question
    class="pm-question"
  >
    <pm-html-text
      [htmlText]="text"
      [ngClass]="questionStyle"
    ></pm-html-text>
  </div>

  <div
    #answers
    class="pm-question-answers"
  >
    <form
      [formGroup]="form"
      autocomplete="off"
      novalidate
    >
      <section class="pm-question-options">
        <ng-container *ngFor="let option of questionOptions; let i=index">
          <pm-radio
            [idField]="option.id"
            formControlName="options"
            [value]="option.id"
            name="options"
            [ngClass]="{
              'quick mb-2 mr-2': isQuick
            }"
          >
            <pm-html-text
              class="answerOption"
              [htmlText]="option.nameHtml"
            ></pm-html-text>
          </pm-radio>
          <div
            *ngIf="option.imageTitle && (i+1)%2===0"
            class="col-12"
          ></div>
        </ng-container>
      </section>
    </form>
  </div>
</div>
