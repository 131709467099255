import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { WindowRefService } from '@pm/core/services';
import {
  AnswerNeoMessageModel,
  AnswerViewMessage,
  extractSelectedAnswersTexts,
  Message,
  MessageType,
  QuestionMessage,
  toAnswerNeoMessageModel,
} from '@pm/chat/models/message.model';
import { ChatStateFacade } from '@pm/chat/ngrx/chat-facade.service';
import { MessageMeta, toMessageMetaModel } from '@pm/chat/models/message-meta.model';
import { LngDetectorService } from '@pm/core/services/lng-detector.service';

function isQuestion(toBeDetermined: Message): toBeDetermined is QuestionMessage {
  return !!(toBeDetermined && (toBeDetermined as QuestionMessage).options);
}

@Injectable()
export class AnswerMessageService {
  constructor(
    private readonly chatFacade: ChatStateFacade,
    private readonly windowRef: WindowRefService,
    private readonly lngDetectorService: LngDetectorService,
  ) {
  }

  generate$(): Observable<[AnswerNeoMessageModel, AnswerViewMessage]> {
    return this.chatFacade.activeMessage$
      .pipe(
        take(1),
        filter((activeMessage) => !!activeMessage),
        map(({ message, answers, meta }) => {
          if (!isQuestion(message)) {
            console.error('MessageService::generateAnswerMessage::', JSON.stringify(message), JSON.stringify(answers));
            throw new Error('MessageService::generateAnswerMessage:: Cannot generate answer for non-question message!');
          }

          const answerNeoMessage = toAnswerNeoMessageModel(answers, this.generateMeta(meta));
          const texts = extractSelectedAnswersTexts(message.options, answers);

          const answerViewMessage: AnswerViewMessage = {
            id: answerNeoMessage.id,
            createdAt: new Date,
            type: MessageType.AnswerView,
            nameHtml: texts.nameHtml.join(''),
            fromHistory: false
          };

          return [answerNeoMessage, answerViewMessage] as [AnswerNeoMessageModel, AnswerViewMessage];
        }),
      );
  }

  private generateMeta(data: Partial<MessageMeta>) {
    const screen = this.windowRef.screenResolution;
    // const lngMeta = this.lngDetectorService.getLangInfo();

    return toMessageMetaModel(data, screen, /* lngMeta */);
  }
}
