import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Answers, OpenEndedQuestionMessage, QuestionMessageType, QuestionOption} from '@pm/chat/models/message.model';
import {MessageMeta} from '@pm/chat/models/message-meta.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Power3, TimelineLite} from 'gsap';
import {AbstractComponent} from '@pm/core/abstracts/abstract.component';
import {takeUntil, tap, throttleTime} from 'rxjs/operators';

@Component({
  selector: 'ds-open-ended-question-message',
  templateUrl: './open-ended-question-message.component.html',
  styleUrls: ['./open-ended-question-message.component.scss']
})
export class OpenEndedQuestionMessageComponent extends AbstractComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', {static: true}) wrapper: ElementRef;
  @ViewChild('question', {static: true}) question: ElementRef;
  @ViewChild('answers', {static: true}) answers: ElementRef;
  @ViewChildren('input', {read: ElementRef}) inputs: QueryList<ElementRef>;

  @Input() message: OpenEndedQuestionMessage;
  @Input() answersInput: Answers;
  @Input() isError: boolean;
  @Input() isRightToLeft = false;

  @Output() answerChange = new EventEmitter<{ answers: Answers, meta: Partial<MessageMeta> }>();
  @Output() answerSubmit = new EventEmitter<string>();



  form: FormGroup;

  private isIndecisive = false; // This is used for tracking & SL score calculation on BE
  private tl = new TimelineLite();

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  get meta(): Partial<MessageMeta> {
    return {
      direct: false,
      indecisive: this.isIndecisive
    };
  }

  get text(): string {
    return this.message.nameHtml;
  }

  get questionStyle(): string {
    return this.isError ? 'pm-question-active-code-error' : 'pm-question-active-code-info';
  }

  get questionOptions(): QuestionOption[] {
    return Array.from(this.message.options.values());
  }
  get isAnswerInputted(): boolean {
    return this.form.getRawValue()[0].length > 0;
  }

  markForCheck() {
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.form = this.createForm(this.questionOptions);
    this.form.valueChanges
      .pipe(
        tap(() => this.answerChange.emit({answers: this.buildAnswer(this.form.getRawValue(), this.questionOptions), meta: this.meta})),
        throttleTime(1000),
        takeUntil(this.ngUnsubscribe$)
      ).subscribe();
  }

  ngAfterViewInit(): void {
    this.inputs.first.nativeElement.focus();

  }

  open(): Promise<void> {
    return new Promise((resolve) => {
      this.tl
        .to(this.question.nativeElement, .5, {opacity: 1, ease: Power3.easeOut})
        .eventCallback('onComplete', resolve.bind(this))
      ;
    });
  }

  close(): Promise<void> {
    return new Promise((resolve) => {
      this.answers.nativeElement.classList.add('d-none');

      this.tl
        .to(this.wrapper.nativeElement, .25, {opacity: 0, ease: Power3.easeOut})
        .eventCallback('onComplete', resolve.bind(this));
    });
  }

  submitOpenEndAnswer() {
    this.answerSubmit.emit(this.message.questionId);

    this.cdr.markForCheck();
  }

  private createForm(options: QuestionOption[]): FormGroup {

    const stashedAnswers = {};

    const controlsConfig = options.reduce((config, option, index) => {
      config[index] = stashedAnswers[option.id] || '';
      return config;
    }, {});

    return this.fb.group(controlsConfig);
  }

  private buildAnswer(values: object, options: QuestionOption[]): Answers {
    const answer = Object.keys(values)
      .reduce((acc, key) => {
        if (values[key]) { acc.push({id: options[key].id, text: values[key]}); }
        return acc;
      }, []);

    return {
      questionId: this.message.questionId,
      questionType: <QuestionMessageType>this.message.type,
      answer
    };
  }
}
