import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

function getWindow(): any {
  return window || {};
}

function getDocument(): any {
  return document || {};
}

export interface ScreenSize {
  screenWidth: number;
  screenHeight: number;
  pixelRatio: number;
  windowWidth: number;
  windowHeight: number;
}

@Injectable()
export class WindowRefService {
  get hasWindow(): boolean {
    return window !== undefined && window !== null;
  }

  get nativeWindow(): any {
    return getWindow();
  }

  get screenResolution(): ScreenSize {
    if (!this.hasWindow) {
      return {
        screenWidth: 0,
        screenHeight: 0,
        pixelRatio: 0,
        windowWidth: 0,
        windowHeight: 0
      };
    }

    const win = this.nativeWindow;
    return {
      screenWidth: Math.floor(win.screen.width * win.devicePixelRatio),
      screenHeight: Math.floor(win.screen.height * win.devicePixelRatio),
      pixelRatio: win.devicePixelRatio,
      windowWidth: Math.floor(win.innerWidth),
      windowHeight: Math.floor(win.innerHeight)
    };
  }

  constructor(
    @Inject(DOCUMENT) private readonly doc: any,
  ) {
  }

  windowResize$(): Observable<any> {
    const win = this.nativeWindow;

    return fromEvent(win, 'resize')
      .pipe(
        startWith(0),
        map(() => (win.innerWidth)),
      );
  }

  isMobile$(): Observable<any> {
    const MOBILE_BREAKPOINT = 768;

    return this.windowResize$()
      .pipe(
        map((screenWidth) => screenWidth <= MOBILE_BREAKPOINT),
        distinctUntilChanged(),
      );
  }

  reload(): void {
    this.nativeWindow.location.reload();
  }

  redirectTo(url: string): void {
    this.doc.location.href = url;
  }
}
