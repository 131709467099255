import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

import { Power3, Sine, TimelineLite } from 'gsap';

import { NotificationType } from '@pm/notification/types';
import { AbstractNotificationComponent } from '@pm/notification/components/abstract-notification.component';

@Component({
  selector: 'pm-notification-wrapper',
  templateUrl: './notification-wrapper.component.html',
  styleUrls: ['./notification-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NotificationWrapperComponent<T extends AbstractNotificationComponent> implements AfterViewInit, OnDestroy {
  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('component') component: T;

  @Input() type: NotificationType;
  @Output() buttonClick = new Subject<any>();

  private tl = new TimelineLite();
  private componentInitData: object;

  ngAfterViewInit(): void {
    if (this.componentInitData) {
      this.setComponentData(this.componentInitData);
    }
  }

  ngOnDestroy(): void {
    this.buttonClick.complete();
    this.buttonClick = null;

    this.tl.kill();
    this.tl = null;
  }

  open() {
    return new Promise((resolve) => {
      const container = this.container.nativeElement;

      this.tl
        .to(container, .7, { bottom: 0, ease: Power3.easeOut })
        .eventCallback('onComplete', resolve.bind(this))
      ;
    });
  }

  close() {
    return new Promise((resolve) => {
      const container = this.container.nativeElement;

      this.tl
        .to(container, .7, { bottom: -500, ease: Sine.easeOut })
        .eventCallback('onComplete', resolve.bind(this))
      ;
    });
  }

  clickButton(data: any): void {
    this.buttonClick.next(data);
  }

  setComponentData(props: object): void {
    if (this.component) {
      this.component.setProperty(props);
    }
  }

  setInitComponentData(props: object): void {
    this.componentInitData = props;
  }
}
