import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BubbleType } from '@pm/chat/models/bubble.model';
import { MessageType, StatementMessage } from '@pm/chat/models/message.model';

@Component({
  selector: 'pm-statement-message',
  templateUrl: './statement-message.component.html',
  styleUrls: ['./statement-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatementMessageComponent {
  @Input() message: StatementMessage;
  @Input() name = '';
  @Input() isTesting: boolean;
  @Input() isRightToLeft = false;

  @Output() resetConversation = new EventEmitter();
  @Output() rollbackConversation = new EventEmitter();


  constructor() { }

  triggerResetConversation() {
    this.resetConversation.emit();
  }

  get text(): string {
    return this.message.nameHtml;
  }

  get bubbleType() {
    switch (this.message.type) {
      case MessageType.WebhookExecuted:
        return BubbleType.Webhook;

      case MessageType.ScreenOut:
        return BubbleType.ScreenOut;

      case MessageType.GoodBye:
        return BubbleType.GoodBye;

      default:
        return BubbleType.Message;
    }
  }

  get isGoodbyeMessage(): boolean {
    return this.message.type === MessageType.GoodBye;
  }

  triggerRollbackConversation(): void {
    this.rollbackConversation.emit();
  }
}
