import { Injectable } from '@angular/core';
import { BrowserStorageService } from './browser-storage.service';

export interface IRegistrationTrackingSource {
  source: string;
  expires_on: number;
}

@Injectable()
export class RegistrationTrackingService {
  readonly registrationSourceKey = 'registration_source';

  constructor(private readonly storageService: BrowserStorageService) {
  }

  storeRegistrationSource(source: string) {
    const expiresOn = Date.now() + (30 * 24 * 60 * 60 * 1000);  // 30 days expiration time
    const data: IRegistrationTrackingSource = {source, expires_on: expiresOn};

    this.storageService.setItem(this.registrationSourceKey, data);
  }

  retrieveRegistrationSource(): string {
    if (this.hasRegistrationSource) {
      return this.storageService.getItem(this.registrationSourceKey).source;
    }

    return 'pollpass-messenger';
  }

  clearRegistrationSource() {
    this.storageService.removeItem(this.registrationSourceKey);
  }

  get hasRegistrationSource(): boolean {
    const data: IRegistrationTrackingSource = this.storageService.getItem(this.registrationSourceKey);

    if (data && data.expires_on < Date.now()) {
      this.clearRegistrationSource();
    }

    return data && data.expires_on > Date.now();
  }
}
