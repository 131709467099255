import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { Back, Expo, Linear, Sine, TimelineLite } from 'gsap';

@Component({
  selector: 'pm-susi-animation',
  templateUrl: './susi-animation.component.html',
  styleUrls: ['./susi-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SusiAnimationComponent implements OnInit, OnDestroy {

  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('purpleBg', { static: true }) purpleBg: ElementRef;
  @ViewChild('flippingCoin', { static: true }) flippingCoin: ElementRef;
  @ViewChild('coinShadowBg', { static: true }) coinShadowBg: ElementRef;
  @ViewChild('coinBg', { static: true }) coinBg: ElementRef;
  @ViewChild('logo', { static: true }) logo: ElementRef;
  @ViewChild('whiteBg', { static: true }) whiteBg: ElementRef;

  @Output() complete = new EventEmitter<void>();

  private tl = new TimelineLite();

  ngOnInit(): void {
    const container = this.container.nativeElement;
    const purpleBg = this.purpleBg.nativeElement;
    const flippingCoin = this.flippingCoin.nativeElement;
    const coinShadowBg = this.coinShadowBg.nativeElement;
    const coinBg = this.coinBg.nativeElement;
    const logo = this.logo.nativeElement;
    const whiteBg = this.whiteBg.nativeElement;

    const cW = container.offsetWidth;
    const cH = container.offsetHeight;

    const bgScale = Math.max(cW, cH) * 2 / 100;

    this.tl
      .to(purpleBg, .7, { delay: .2, scale: bgScale, ease: Expo.easeOut })
      .to(flippingCoin, .1, { scale: 1 }, '-=.6')
      .to(flippingCoin, .3, { y: -200, ease: Sine.easeOut }, '-=.6')
      .to(flippingCoin, .3, { y: 0, ease: Sine.easeIn })
      .to(flippingCoin, 1, { rotation: '700', ease: Linear.easeOut }, '-=1')
      .to(flippingCoin, .1, { opacity: .2, scale: .6, ease: Linear.easeIn }, '-=.1')
      .to(coinBg, .55, { scale: 1.55, transformOrigin: 'center center', ease: Back.easeOut.config(4) }, '-=.05')
      .to(coinShadowBg, .55, { scale: 1.55, transformOrigin: 'center center', ease: Back.easeOut.config(4) }, '-=.55')
      .to(logo, .55, { scale: 1, transformOrigin: 'center center', ease: Back.easeOut.config(4) }, '-=.55')
      .to(coinBg, .5, { scale: bgScale, ease: Expo.easeIn })
      .to(coinShadowBg, .5, { scale: bgScale, ease: Expo.easeIn }, '-=.5')
      .to(logo, .6, { scale: 0, transformOrigin: 'center center', ease: Expo.easeIn }, '-=.3')
      .to(whiteBg, .6, { scale: bgScale, ease: Expo.easeIn }, '-=.3')
      .eventCallback('onComplete', () => this.complete.emit())
    ;
  }

  ngOnDestroy(): void {
    this.tl.kill();
    this.tl = null;
  }

  get animation(): TimelineLite {
    return this.tl;
  }
}
