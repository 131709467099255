<div #wrapper
     class="pm-question-container">
    <div #question
         class="pm-question">
        <pm-html-text
                [htmlText]="text"
                [ngClass]="questionStyle"
        ></pm-html-text>
    </div>
    <div #answers
         class="pm-question-answers">
        <form [formGroup]="form"
              novalidate>
            <section class="no-gutters">
                <ng-container *ngFor="let option of questionOptions; let i=index">
                    <div class="open-answer-option">
                        <input pm-input
                               #input
                               [formControlName]="i"
                               placeholder="Please specify"
                               type="text">
                        <button pm-send-button
                                *ngIf="!message.hasMultipleOptions && isAnswerInputted"
                                (click)="submitOpenEndAnswer()"></button>
                    </div>
                    <div *ngIf="option.imageTitle && (i+1)%2===0"
                         class="col-12">
                    </div>
                </ng-container>
            </section>
        </form>
    </div>
</div>
