import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { environment } from '@messenger-env/environment';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { WindowRefService } from '@pm/core/services/window-ref.service';

@Injectable()
export class SocialAuthService {
  private currentSub: Subscription;

  constructor(
    private readonly coreStateFacade: CoreStateFacade,
    private readonly win: WindowRefService,
  ) {
  }

  facebook(): void {
    this.socialReg('facebook');
  }

  google(): void {
    this.socialReg('google_oauth2');
  }

  private socialReg(type: 'facebook' | 'google_oauth2'): void {
    if (this.currentSub) {
      // Prevent multiple calls to this function by mistake
      return;
    }

    this.coreStateFacade.setAuthenticating(true);
    this.win.redirectTo(`${environment.api}/auth/${type}`);
  }
}
