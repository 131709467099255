import { Injectable } from '@angular/core';

import { WindowRefService } from '@pm/core/services/window-ref.service';
import { PersistentStorageStrategyService } from '@pm/core/persistent-storage/index';

@Injectable()
export class PSSCookieService implements PersistentStorageStrategyService {

  private readonly _window;

  constructor(
    win: WindowRefService,
  ) {
    this._window = win.nativeWindow;
  }

  set(key: string, value: string): Promise<any> {
    return Promise.resolve(this.setCookie(key, value));
  }

  get(key: string): Promise<string> {
    try {
      const cookieVal = this.getCookie(key);

      return !cookieVal ? Promise.reject() : Promise.resolve(cookieVal);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  remove(key: string): Promise<boolean> {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    return Promise.resolve(true);
  }

  private setCookie(name: string, value: string, days: number = 99999) {
    const expiresDate = new Date();
    expiresDate.setTime(expiresDate.getTime() + (days * 24 * 60 * 60 * 1000));

    document.cookie = `${name}=${(value)}; expires=${expiresDate.toUTCString()}; path=/`;
  }

  private getCookie(key: string): string | null {
    const nameEQ = `${key}=`;
    const cookies = document.cookie.split(';');

    cookies.forEach((cookie) => {
      let c = cookie;

      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    });

    return null;
  }
}
