import { NgModule } from '@angular/core';
import { SharedModule } from '@pm/shared/shared.module';
import { ChatModule } from '@pm/chat/chat.module';

import { CampaignNavigationBarComponent } from './components';
import { CampaignService } from '@pm/campaign/services/campaign.service';
import { CampaignRoutingModule } from '@pm/campaign/campaign-routing.module';
import { CampaignContainerComponent } from '@pm/campaign/containers/campaing-container/campaign-container.component';
import { NotificationModule } from '@pm/notification/notification.module';
import { CampaignCodeRedirectGuard } from '@pm/campaign/guards/campaign-code-redirect-guard.service';

@NgModule({
  imports: [
    ChatModule,
    SharedModule,
    CampaignRoutingModule,
    NotificationModule,
  ],
  declarations: [
    CampaignContainerComponent,
    CampaignNavigationBarComponent,
  ],
  providers: [
    CampaignService,
    CampaignCodeRedirectGuard,
  ],
  exports: [
    CampaignNavigationBarComponent,
  ]
})
export class CampaignModule {
}
