import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'button[pm-button], a[pm-button]',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnChanges {
  @Input() iconLeft = '';
  @Input() iconRight = '';
  @Input() isLoading = false;
  // Use this property instead of core [disabled] if need to change disable state of pm-button !!
  @Input() isDisabled = false;

  @HostBinding('attr.disabled') disabled: true | null;
  @HostBinding('class.shake') isShaking = false;

  constructor(
    private readonly cdr: ChangeDetectorRef
  ) {
  }

  ngOnChanges(c: SimpleChanges): void {
    this.disabled = (this.isLoading || this.isDisabled) ? true : null;
  }

  shake(): void {
    if (this.isShaking === true) {
      return;
    }

    this.isShaking = true;

    timer(1500)
      .subscribe(() => {
        this.isShaking = false;
        this.cdr.markForCheck();
      });
  }
}
