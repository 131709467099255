import { Component, Input } from '@angular/core';

@Component({
  selector: 'pm-bundle-name',
  templateUrl: './bundle-name.component.html',
  styleUrls: ['./bundle-name.component.scss'],
})

export class BundleNameComponent {

  @Input() nameHtml: string;

}
