<div
  #container
  class="pm-notification"
>
  <ng-container [ngSwitch]="type">
    <pm-notification-emailreg
      #component
      *ngSwitchCase="'emailreg'"
      (buttonClick)="clickButton($event)"
    ></pm-notification-emailreg>
    <pm-notification-chat-error
      #component
      *ngSwitchCase="'chat-error'"
      (buttonClick)="clickButton($event)"
    ></pm-notification-chat-error>
    <pm-notification-new-version
      #component
      *ngSwitchCase="'new-app-version'"
      (buttonClick)="clickButton($event)"
    ></pm-notification-new-version>
  </ng-container>
</div>
