<div
  class="pm-debug"
  [class.is-closed]="!isOpen"
  [class.is-open]="isOpen"
>
  <div class="pm-debug__scroller">
    <div
      class="pm-debug__item"
      (click)="isOpen = !isOpen"
    >
      PM DEBUG
    </div>
    <div class="pm-debug__item">
      NEO: {{ neoVersion$ | async }}
      <div class="overlay">
        <button (click)="reconnect()">Reconnect</button>
      </div>
    </div>
    <div class="pm-debug__item">
      Animations
      <div class="overlay">
        <button (click)="susiAnimation()">susiAnimation</button>
      </div>
    </div>
    <div class="pm-debug__item">
      Notifications
      <div class="overlay">
        <button (click)="openNotification(notificationType.DISCONNECTED)">disconnected</button>
        <button (click)="openNotification(notificationType.EMAIL_REG)">emailreg</button>
        <button (click)="openNotification(notificationType.NEW_APP_VERSION)">new-app-version</button>
        <button (click)="triggerCheck()">TRIGGER new-app-version</button>
      </div>
    </div>
    <div class="pm-debug__item">
      Chat
      <div class="overlay">
        <button (click)="reconnect()">reconnect</button>
      </div>
    </div>

    <div class="pm-debug__item">
      Links
      <div class="overlay w-300 left text-left">
        <table class="link-table">
          <tr>
            <th colspan="2"><b><i>---------- Don't require auth status:</i></b></th>
          </tr>
          <tr>
            <th>Error pages</th>
            <td>
              <a routerLink="/public/overloaded">/overloaded</a>
              <br>
              ❌ <a routerLink="/public/account-deactivated">/account-deactivated</a>
              <br>
              ❌ <a routerLink="/public/deactivated">/deactivated</a>
              <br>
              ✅ <a routerLink="/public/rate-limit">/rate-limit</a>
              <br>
              ✅ <a routerLink="/public/unsupported-country">/unsupported-country</a>
              <br>
              ✅ <a routerLink="/public/no-content-component">/no-content-component</a>
              <br>
              ✅ <a routerLink="/public/campaign-error">/campaign-error</a>
              <br>
              ❌ <a routerLink="/public/account-pending-verification">/account-pending-verification</a>
              <br>
              ❌ <a routerLink="/public/account-pending-review">/account-pending-review</a>
            </td>
          </tr>
          <tr>
            <th>Kitchensink</th>
            <td>
              ✅ <a routerLink="/kitchensink">/kitchensink</a></td>
          </tr>
          <tr>
            <th colspan="2"><b><i>---------- Require Login:</i></b></th>
          </tr>
          <tr>
            <th>Home</th>
            <td><a routerLink="/">/</a></td>
          </tr>
          <tr>
            <th>Wallet</th>
            <td>
              ❌ <a routerLink="/wallet">/wallet</a>
              <br>
              ❌ <a routerLink="/wallet/incentives">/wallet/incentives</a>
              <br>
              ❌ <a routerLink="/wallet/success">/wallet/success</a>
            </td>
          </tr>
          <tr>
            <th>Account Settings</th>
            <td><a routerLink="/account-settings">/account-settings</a></td>
          </tr>
          <tr>
            <th colspan="2"><b><i>---------- Require logout:</i></b></th>
          </tr>
          <tr>
            <th>Phone Verification</th>
            <td>
              ❌ <a routerLink="/public/phone-verification" [queryParams]="{ignoreGuardCheck: 1}">/phone-verification</a>
              <br>
              ❌ <a routerLink="/public/phone-verification/token" [queryParams]="{ignoreGuardCheck: 1}">
                /phone-verification/token
              </a>
            </td>
          </tr>
          <tr>
            <th>Recover Account</th>
            <td>
              ❌ <a routerLink="/public/recover-account">/recover-account</a>
              <br>
              ❌ <a routerLink="/public/recover-account-confirmation">/recover-account-confirmation</a>
              <br>
              ❌ <a routerLink="/public/reset-password">/reset-password</a>
            </td>
          </tr>
          <tr>
            <th>SUSI</th>
            <td>
              ❌ <a routerLink="/public/sign-in">/sign-in</a>
              <br>
              ❌ <a routerLink="/public/sign-in/email">/sign-in/email</a>
              <br>
              🌴 <a routerLink="/public/register">/register</a>
              <br>
              ❌ <a routerLink="/public/register/email">/register/email</a>
              <br>
              ❌ <a
                routerLink="/public/registration-holding"
                [queryParams]="{ignoreGuardCheck: 1}">/registration-holding</a>
              <br>
              ❌ <a routerLink="/public/add-birth-date">/add-birth-date</a>
            </td>
          </tr>
          <tr>
            <th>Magic Link</th>
            <td>
              ✅ <a routerLink="/mll">/mll</a>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="pm-debug__item">
      <fa-icon icon="user" class="mr-1"></fa-icon>
      <div
        *ngIf="authGrant$ | async as authGrant"
        class="overlay w-300"
      >
        <div
          *ngIf="user$|async as user"
          class="text-left"
        >
          Token expires in: <span>{{tokenExpiresIn$ | async}}</span>

          <hr/>
          <table>
            <tr>
              <th>Id</th>
              <td>{{user.id}}</td>
            </tr>
            <tr>
              <th>User</th>
              <td>
                <button (click)="log(user)">Click to log</button>
              </td>
            </tr>
            <tr>
              <th colspan="2">
                <hr/>
              </th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
