export class PageTitles {
  static readonly titles = new Map([
    ['/public/sign-in', 'Sign In'],
    ['/public/sign-in/email', 'Sign In With Email'],
    ['/public/register', 'Register'],
    ['/public/register/email', 'Register With Email'],
    ['/public/registration-holding', 'Registration Holding'],
    ['/public/deactivated', 'Deactivated'],
    ['/public/add-birth-date', 'Add Birth Date'],
    ['/public/phone-verification', 'Phone Verification'],
    ['/public/phone-verification/token', 'Phone Verification - Token'],
    ['/public/recover-account', 'Recover Account'],
    ['/public/recover-account-confirmation', 'Recover Account Confirmation'],
    ['/public/reset-password', 'Reset Password'],
    ['/public/account-deactivated', 'Account Deactivated'],
    ['/public/overloaded', 'Overloaded'],
    ['/public/unsupported-country', 'Unsupported Country'],
    ['/exchange', 'Exchange'],
    ['/', 'Chat'],
    ['/wallet', 'Wallet'],
    ['/wallet/incentives', 'Wallet Incentives'],
    ['/wallet/incentive-email', 'Wallet Incentive Email'],
    ['/wallet/success', 'Success'],
    ['/account-settings', 'Account Settings'],
    ['/exchange', 'Exchange']
  ]);

  static getTitle(url: string) {
    const pageTitle = PageTitles.titles.has(url) ? PageTitles.titles.get(url) : 'Lost';

    if (pageTitle === 'Lost' && (url.startsWith('/m/') || url.startsWith('/c/'))) {
      return 'Pollpass';
    }

    return `${pageTitle} - Pollpass`;
  }
}
