<div class="container-fluid">
  <div class="row">
    <div class="col">
      <a
        pm-back-button
        routerLink="/"
        class="mt-4"
      >
        Back
      </a>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col">
      <h1>Kitchensink page</h1>
    </div>
  </div>
  <hr class="my-4" />
  <div class="row">
    <div class="col">
      <pm-ks-typography></pm-ks-typography>
    </div>
  </div>
  <hr class="my-4" />
  <div class="row">
    <div class="col">
      <pm-ks-inputs></pm-ks-inputs>
    </div>
  </div>
  <hr class="my-4" />
  <div class="row">
    <div class="col">
      <pm-ks-chat></pm-ks-chat>
    </div>
  </div>
  <hr class="my-4" />
  <div class="row">
    <div class="col">
      <pm-ks-card></pm-ks-card>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col">
      <pm-ks-button></pm-ks-button>
    </div>
  </div>
</div>

