import { Injectable } from '@angular/core';

import { WindowRefService } from '@pm/core/services/window-ref.service';
import { PersistentStorageStrategyService } from '@pm/core/persistent-storage/index';

@Injectable()
export class PSSSessionstorageService implements PersistentStorageStrategyService {

  private readonly _window;

  constructor(
    win: WindowRefService,
  ) {
    this._window = win.nativeWindow;
  }

  set(key: string, value: string): Promise<any> {
    return this.isSupported() ? Promise.resolve(this._window.sessionStorage.setItem(key, value)) : Promise.reject();
  }

  get(key: string): Promise<string> {
    return this.isSupported() ? Promise.resolve(this._window.sessionStorage.getItem(key)) : Promise.reject();
  }

  remove(key: string): Promise<boolean> {
    return this.isSupported() ? Promise.resolve(this._window.sessionStorage.removeItem(key)) : Promise.reject();
  }

  private isSupported(): boolean {
    return 'sessionStorage' in this._window;
  }
}
