import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

import { KitchensinkPageComponent } from '@pm/kitchensink/containers';
import {
  KsButtonComponent,
  KsCardComponent,
  KsChatComponent,
  KsInputsComponent,
  KsTypographyComponent
} from '@pm/kitchensink/components';
import { KitchensinkRoutingModule } from '@pm/kitchensink/kitchensink-routing.module';
import { SharedModule } from '@pm/shared/shared.module';
import { ChatModule } from '@pm/chat/chat.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    KitchensinkRoutingModule,
    SharedModule,
    RouterModule,
    ChatModule,
  ],
  declarations: [
    KsButtonComponent,
    KsCardComponent,
    KsTypographyComponent,
    KsInputsComponent,
    KsChatComponent,
    KitchensinkPageComponent,
  ],
  exports: []
})
export class KitchensinkModule {
}
