import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { LevelModel, UserModel } from '@pm/core/models/user.model';

// ------------------------------------------------------------------------
// Actions
// ------------------------------------------------------------------------
export const UserActions = {
  UserLoaded: createAction('[User] Loaded User', props<{ user: UserModel }>()),
  ClearUser: createAction('[User] Clear User'),
  UpdateLevel: createAction('[User] Update level', props<{ level: LevelModel }>()),
};

// ------------------------------------------------------------------------
// Reducers
// ------------------------------------------------------------------------
export interface UserState {
  user: UserModel | null;
}

const initialState: UserState = {
  user: null,
};

const userReducer = createReducer(
  initialState,
  on(UserActions.UserLoaded, (state, { user }) => ({
    ...state,
    user: { ...user },
  })),
  on(UserActions.ClearUser, () => ({
    ...initialState
  })),
  on(UserActions.UpdateLevel, (state, { level }) => ({
    user: { ...state.user, level }
  })),
);

export function UserReducer(state: UserState | undefined, action: Action): UserState {
  return userReducer(state, action);
}

// ------------------------------------------------------------------------
// Selectors
// ------------------------------------------------------------------------
export const userState = createFeatureSelector<UserState>('user');

export const UserSelectors = {
  User: createSelector(userState, (state: UserState) => state.user),
};
