<div class="container" *ngIf="error | async">
  <div class="deactivate-content">
    <span class="fs-60">😟</span>
    <h1 class="fs-60">Oops, something isn’t right</h1>
    <p class="fs-20">{{error | async}}</p>
    <button
      routerLink="/"
      pm-button
      class="block-md"
    >
      Resume chatting <span class="mic">🎤</span>
    </button>
  </div>
</div>
