import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { UserModel } from '@pm/core/models/user.model';
import { RoutingService } from '@pm/core/services';

@Injectable()
export class CampaignCodeRedirectGuard implements CanActivate {
  constructor(
    private readonly routingService: RoutingService,
    private readonly coreFacade: CoreStateFacade
  ) {
  }

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | true> {
    return this.coreFacade.userOnce$.pipe(
      map((user: UserModel) => {
          if (user !== null) {
            if (user.campaignCode && !router.params.campaignCode) {
              let url = `${user.linkType}/${user.campaignCode}`;

              if (user.panelCode) {
                url += `/p/${user.panelCode}`;
              }

              return this.routingService.url(url);
            }

            if (user.campaignCode === router.params.campaignCode) {
              if (!user.panelCode && router.params.panelCode) {
                return this.routingService.url(`${user.linkType}/${user.campaignCode}`);
              }

              if (user.panelCode && router.params.panelCode !== user.panelCode) {
                return this.routingService.url(`${user.linkType}/${user.campaignCode}/p/${user.panelCode}`);
              }
            }
          }

          return true;
        }
      ));
  }
}
