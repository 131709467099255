import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pm-loading-bubble',
  templateUrl: 'loading-bubble.component.html',
  styleUrls: ['loading-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBubbleComponent {
  @Input() isRightToLeft = false;
}
