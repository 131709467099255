import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PSSDbService } from '@pm/core/persistent-storage/p-s-s-db.service';
import { PersistentStorageService } from '@pm/core/persistent-storage/persistent-storage-service';
import { PSSLocalstorageService } from '@pm/core/persistent-storage/p-s-s-localstorage.service';
import { PSSSessionstorageService } from '@pm/core/persistent-storage/p-s-s-sessionstorage.service';
import { PSSCookieService } from '@pm/core/persistent-storage/p-s-s-cookie.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    PersistentStorageService,
    PSSDbService,
    PSSLocalstorageService,
    PSSCookieService,
    PSSSessionstorageService,
  ],
})
export class PersistentStorageModule {
}
