<div
  class="button-container"
  [class.icon-left]="iconLeft"
  [class.icon-right]="iconRight || isLoading"
>
  <fa-icon
    *ngIf="iconLeft"
    [icon]="iconLeft"
    aria-hidden="true"
  ></fa-icon>
  <div
    *ngIf="!isLoading"
    class="content"
  >
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="isLoading"
    class="content"
  >
    Loading
  </div>
  <fa-icon
    *ngIf="!isLoading && iconRight"
    [icon]="iconRight"
    aria-hidden="true"
  ></fa-icon>
  <fa-icon
    *ngIf="isLoading"
    icon="spinner-third"
    class="spin"
    aria-hidden="true"
  ></fa-icon>
</div>
