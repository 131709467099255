import {
  Action,
  ActionReducer,
  createAction,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
  props, State
} from '@ngrx/store';

// ------------------------------------------------------------------------
// Actions
// ------------------------------------------------------------------------
export const LayoutActions = {
  OpenNavigation: createAction('[Layout] Open Navigation'),
  CloseNavigation: createAction('[Layout] Close Navigation'),
  ToggleNavigation: createAction('[Layout] Toggle Navigation'),
  RegistrationsClosed: createAction('[Layout] Registrations Closed', props<{ isClosed: boolean; }>()),
  FirstScrollDone: createAction('[Layout] First scroll done'),
  InitAnimationStarted: createAction('[Layout] Init animation started'),
  InitAnimationDone: createAction('[Layout] Init animation done'),
};

// ------------------------------------------------------------------------
// Reducers
// ------------------------------------------------------------------------
export interface LayoutState {
  showNavigationMenu: boolean;
  registrationsClosed: boolean;
  firstScrollDone: boolean;
  initAnimationStarted: boolean;
}

const initialState: LayoutState = {
  showNavigationMenu: false,
  registrationsClosed: false,
  firstScrollDone: false,
  initAnimationStarted: false,
};

const layoutReducer = createReducer(
  initialState,
  on(LayoutActions.CloseNavigation, (state) => ({
    ...state,
    showNavigationMenu: false
  })),
  on(LayoutActions.OpenNavigation, (state) => ({
    ...state,
    showNavigationMenu: true
  })),
  on(LayoutActions.ToggleNavigation, (state) => ({
    ...state,
    showNavigationMenu: !state.showNavigationMenu
  })),
  on(LayoutActions.RegistrationsClosed, (state, { isClosed }) => ({
    ...state,
    registrationsClosed: isClosed
  })),
  on(LayoutActions.FirstScrollDone, (state) => ({
    ...state,
    firstScrollDone: true
  })),
  on(LayoutActions.InitAnimationStarted, (state) => ({
    ...state,
    initAnimationStarted: true
  })),
  on(LayoutActions.InitAnimationDone, (state, {}) => ({
    ...state,
    initAnimationStarted: false
  })),
);

export function LayoutReducer(state: LayoutState | undefined, action: Action): LayoutState {
  return layoutReducer(state, action);
}
// ------------------------------------------------------------------------
// Selectors
// ------------------------------------------------------------------------
const layoutState = createFeatureSelector<LayoutState>('layout');

export const LayoutSelectors = {
  showNavigationMenu: createSelector(layoutState, (state: LayoutState) => state.showNavigationMenu),
  firstScrollDone: createSelector(layoutState, (state: LayoutState) => state.firstScrollDone),
  initAnimationStarted: createSelector(layoutState, (state: LayoutState) => state.initAnimationStarted),
  registrationsClosed: createSelector(layoutState, (state: LayoutState) => state.registrationsClosed),
};
