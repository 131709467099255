import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { IExchangeAffiliate } from '@pm/core/models/exchange.model';
import { AffiliateSession, IAffiliateSession } from '@pm/core/models/affiliate.model';

import { BrowserStorageService } from './browser-storage.service';

@Injectable()
export class AffiliateService {
  readonly sessionKey = 'affiliate';

  constructor(
    private readonly storageService: BrowserStorageService,
    private readonly http: HttpClient
  ) {
  }

  storeSession(data: IExchangeAffiliate) {
    const session: IAffiliateSession = {
      source: data.source,
      key: data.aff_s1,
      click_id: data.aff_s2,
      expires_on: Date.now() + (30 * 24 * 60 * 60 * 1000)   // 30 days expiration time
    };

    this.storageService.setItem(this.sessionKey, session);
  }

  retrieveSession(): AffiliateSession {
    if (!this.hasSession) {
      return null;
    }

    const data = this.storageService.getItem(this.sessionKey) as IAffiliateSession;

    return new AffiliateSession(data.source, data.key, data.click_id);
  }

  clearSession() {
    this.storageService.removeItem(this.sessionKey);
  }

  sendSession() {
    if (!this.hasSession) {
      console.warn('No affiliate sessions stored!');
      return;
    }

    const sessionData = this.retrieveSession();
    const body = {affiliate: sessionData};

    this.http.post('/affiliates', body)
      .pipe(
        tap(() => this.clearSession()),
      )
      .subscribe();
  }

  get hasSession(): boolean {
    const data: IAffiliateSession = this.storageService.getItem(this.sessionKey);

    if (data && data.expires_on < Date.now()) {
      this.clearSession();
    }

    return data && data.expires_on > Date.now();
  }
}
