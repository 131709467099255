import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, OnChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type onFunctionType = (args?: any) => void;

@Component({
  selector: 'pm-checkbox',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }],
  templateUrl: 'checkbox.component.html',
  styleUrls: ['checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() idField: string;

  @ViewChild('checkbox', { static: true }) checkbox: ElementRef;

  isDisabled: boolean;

  private onTouch: onFunctionType;
  private onModelChange: onFunctionType;

  constructor() {
    this.onModelChange = () => {
    };
    this.onTouch = () => {
    };
  }

  writeValue(value: any): void {
    if (this.checkbox) {
      this.checkbox.nativeElement.checked = !!value;
    }
  }

  registerOnTouched(fn: onFunctionType): void {
    this.onTouch = fn;
  }

  registerOnChange(fn: onFunctionType): void {
    this.onModelChange = (value: string) => fn(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange(val: string): void {
    this.onTouch();
    this.onModelChange(val);
  }
}
