import { Injectable } from '@angular/core';

import { PSSDbService } from '@pm/core/persistent-storage/p-s-s-db.service';
import { PSSLocalstorageService } from '@pm/core/persistent-storage/p-s-s-localstorage.service';
import { PSSSessionstorageService } from '@pm/core/persistent-storage/p-s-s-sessionstorage.service';
import { PSSCookieService } from '@pm/core/persistent-storage/p-s-s-cookie.service';
import { PersistentStorageStrategyService } from '@pm/core/persistent-storage/index';

@Injectable()
export class PersistentStorageService {
  private readonly cacheData: Map<string, any>;
  private readonly cacheFetchingPromises: Map<string, Promise<string>>;
  private readonly storageStrategies: PersistentStorageStrategyService[];

  constructor(
    dbService: PSSDbService,
    localStorageService: PSSLocalstorageService,
    sessionStorageService: PSSSessionstorageService,
    cookieService: PSSCookieService,
  ) {

    this.cacheData = new Map();
    this.cacheFetchingPromises = new Map();
    this.storageStrategies = [dbService, localStorageService, cookieService,
      // Session-storage is per-tab so prioritise this last
      sessionStorageService
    ];
  }

  set(key: string, value: string): void {
    // Remove value from local (service) cache
    this.cacheData.delete(key);
    this.cacheFetchingPromises.delete(key);

    // Replicate passed in data to all storage locations
    this.storageStrategies.forEach((service) => service.set(key, value));
  }

  get(key: string): Promise<string> {
    if (this.cacheData.has(key)) {
      return Promise.resolve(this.cacheData.get(key));
    }

    if (!this.cacheData.has(key)) {
      this.cacheFetchingPromises
        .set(
          key,
          this.getDataFromStores(key)
            .then((data) => {
              this.cacheData.set(key, data);
              return data;
            })
        );
    }

    return this.cacheFetchingPromises.get(key);
  }

  remove(key: string): void {
    // Remove value from local (service) cache
    this.cacheData.delete(key);
    this.cacheFetchingPromises.delete(key);

    // Replicate passed in data to all storage locations
    this.storageStrategies.forEach((service) => service.remove(key));
  }

  private getDataFromStores(key: string): Promise<string> {
    const dataSourcePromises = [];

    this.storageStrategies.forEach((strategy) => dataSourcePromises.push(
      // We require all promises to resolve with either their data retrieved
      // from the store or a null to indicate no data. $q.all will reject
      // all promises if any one of them rejects, so we're wrapping each
      // one here to ensure they all resolve with a sensible value
      strategy.get(key)
        .then(data => data)
        // No data in the storage strategy
        .catch(() => null)
    ));

    return Promise.all(dataSourcePromises)
    // Find first non-null/empty object result
      .then((results) => results.find((res) => !!res));
  }
}
