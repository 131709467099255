<div #wrapper>
  <div #navbar
       class="pm-navbar-container">
    <nav class="pm-navbar container">
      <div class="pm-navbar__logo"
           *ngIf="!isRightToLeft"
           @slideOutRight>
        <img [src]="logoUrl"
             alt="Pollpass" />
      </div>
      <div class="pm-navbar__profile">
        <!--
          <button
            pm-button
            class="mt-1rem"
            (click)="logout()"
          >
            Exit
          </button>
        -->
      </div>
      <div class="pm-navbar__logo pm-navbar__logo--right"
           *ngIf="isRightToLeft"
           @slideInLeft>
        <img [src]="logoUrl"
             alt="Pollpass" />
      </div>
    </nav>
  </div>
</div>
