<div class="row">
  <div class="col">
    <h2>Chat</h2>
    <br>
  </div>
</div>
<div class="row">
  <div class="col-6 mb-2">
    <h4 class="mb-2">Checkboxes</h4>
    <pm-checkbox>Test 1</pm-checkbox>
    <pm-checkbox>Test 2</pm-checkbox>
    <pm-checkbox>Test 3</pm-checkbox>
    <pm-checkbox>Test 4</pm-checkbox>

    <h4 class="mb-2">Quick</h4>

    <pm-checkbox class="quick">Test 1</pm-checkbox>
    <pm-checkbox class="quick">Test 2</pm-checkbox>
    <pm-checkbox class="quick">Test 3</pm-checkbox>
    <pm-checkbox class="quick">Test 4</pm-checkbox>
  </div>
  <div class="col-6 mb-2 space-between">
    <h4 class="mb-2">Radios</h4>

    <pm-radio>Test 1</pm-radio>
    <pm-radio>Test 2</pm-radio>
    <pm-radio>Test 3</pm-radio>
    <pm-radio>Test 4</pm-radio>

    <h4 class="mb-2">Quick</h4>

    <pm-radio class="quick">Test 1</pm-radio>
    <pm-radio class="quick">Test 2</pm-radio>
    <pm-radio class="quick">Test 3</pm-radio>
    <pm-radio class="quick">Test 4</pm-radio>
  </div>
  <div class="col-12 mb-2 space-between">
    <hr>

    <div class="row">
      <div class="col-6">
        <h4 class="mb-2">InActive Question</h4>
        <div class="py-2rem">
          <pm-message-bubble
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum"
            type="Question"
            class="pm-question inactive"
          ></pm-message-bubble>
        </div>
      </div>
      <div class="col-6">
        <h4 class="mb-2">Active Question</h4>
        <button (click)="active=!active">Set active to "{{active|json}}"</button>
        <div class="py-2rem">
          <pm-message-bubble
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum"
            type="Question"
            class="pm-question active"
            *ngIf="active"
          ></pm-message-bubble>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mb-2 space-between">
    <hr>
    <h4 class="mb-2">Bubbles</h4>

    <div class="row">
      <div class="col-6">
        <div class="p-2rem">
          <pm-loading-bubble></pm-loading-bubble>
        </div>
      </div>
      <div class="col-6">
        <pm-message-bubble
          class="mb-1rem"
          text="HEllo World"
        >
        </pm-message-bubble>
        <pm-message-bubble
          class="mb-1rem"
          text="HEllo World HEllo World HEllo World HEllo World HEllo World HEllo World "
        >
        </pm-message-bubble>
      </div>
    </div>
    <div class="col-12 mb-2 space-between">
      <h4 class="mb-2">Credits widget</h4>
      <!--<pm-coins-widget [balance]="balance"></pm-coins-widget>-->
    </div>
  </div>
</div>
