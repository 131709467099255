import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';

@Component({
  selector: 'pm-ks-chat',
  templateUrl: './ks-chat.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KsChatComponent extends AbstractComponent {
  balance = 100;
  active = true;

  constructor(cdr: ChangeDetectorRef) {
    super();

    timer(1500, 4500)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.balance += 9;
        cdr.markForCheck();
      });
  }
}
