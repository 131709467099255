import { Component } from '@angular/core';
import {RadioQuestionMessageComponent} from '@pm/chat/components/radio-question-message/radio-question-message.component';

@Component({
  selector: 'app-language-question-message',
  templateUrl: './language-question-message.component.html',
  styleUrls: ['./language-question-message.component.scss']
})
export class LanguageQuestionMessageComponent extends RadioQuestionMessageComponent {

}
