<div
  class="card {{theme}} card-img-{{imagePos}}"
>
  <img
    *ngIf="imageSrc"
    [src]="imageSrc"
    [alt]="imageAlt"
    class="card-img-{{imagePos}}"
  />

  <div class="card-body">
    <h1
      *ngIf="cardTitle"
      class="card-title"
    >
      {{cardTitle}}
    </h1>
    <p class="card-text">
      <ng-content></ng-content>
      <ng-content select=".content"></ng-content>
    </p>
    <div class="card-cta">
      <ng-content select=".button"></ng-content>
    </div>
  </div>
</div>
