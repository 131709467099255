import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { NeoService } from '@pm/chat/services';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { ChatStateFacade } from '@pm/chat/ngrx/chat-facade.service';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { AuthGrantModel } from '@pm/core/models/auth-grant.model';
import { UserModel } from '@pm/core/models/user.model';
import { EnvironmentService } from '@pm/core/services';
import { SusiAnimationService } from '@pm/core/services/susi-animation.service';
import { NotificationService } from '@pm/notification/services/notification.service';
import { NotificationType } from '@pm/notification/types';
import { HttpClient } from '@angular/common/http';
import { ConnectionStateFacade } from '@pm/chat/ngrx/connection-facade.service';
import { VersionUpdateCheckService } from '@pm/core/services/version-update-check.service';

@Component({
  selector: 'pm-debug',
  templateUrl: 'debug.component.html',
  styleUrls: ['debug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebugComponent extends AbstractComponent implements OnInit {

  readonly notificationType = NotificationType;
  isOverlayOpen: boolean;
  isOpen: boolean;
  authGrant$: Observable<AuthGrantModel>;
  user$: Observable<UserModel>;
  tokenExpiresIn$: Observable<number>;
  neoVersion$: Observable<string>;

  constructor(
    private readonly neoService: NeoService,
    private readonly chatFacade: ChatStateFacade,
    private readonly connectionFacade: ConnectionStateFacade,
    private readonly coreFacade: CoreStateFacade,
    private readonly http: HttpClient,
    private readonly susiAnimationService: SusiAnimationService,
    private readonly mns: NotificationService,
    private readonly env: EnvironmentService,
    private readonly versionUpdateCheckService: VersionUpdateCheckService,
  ) {
    super();

    this.isOverlayOpen = false;
  }

  ngOnInit() {
    const NEO_VERSION_URL = this.env.socketVersionUrl;

    this.authGrant$ = this.coreFacade.authGrant$;
    this.user$ = this.coreFacade.user$;

    this.neoVersion$ = NEO_VERSION_URL
      // Anytime NEO reconnects, reload the NEO version
      ? this.neoService.neoInitialized$
        .pipe(
          // map((status: ConnectionStatusType) => status === ConnectionStatusType.OPEN),
          filter((isInitialized) => isInitialized),
          switchMap(() => (
            this.http.get(NEO_VERSION_URL)
              .pipe(
                map((res: any) => res.version),
                catchError((e) => {
                  console.error('NEO FETCH ERROR', e);

                  return of(`[Error] Request to [${NEO_VERSION_URL}] failed`);
                }),
              )
          )),
        )
      : of('[Error] missing NEO url');

    this.tokenExpiresIn$ = this.authGrant$
      .pipe(
        switchMap((authGrant) => {
          if (!authGrant) {
            return of(null);
          }

          const expiresAtTs = authGrant.createdAt + authGrant.expiresIn;
          const currentTs = Math.floor(Date.now() / 1000);

          const willExpireIn = expiresAtTs - currentTs;
          this.log('DebugComponent::tokenWillExpireIn::', willExpireIn);
          return timer(0, 1000)
            .pipe(
              map((val) => willExpireIn - val),
            );
        })
      );
  }

  susiAnimation(): void {
    void this.susiAnimationService.start();
  }

  openNotification(type: NotificationType): void {
    this.mns.close()
      .then(() => {
        this.mns.open(type)
          .buttonClick
          .subscribe(() => this.mns.close());
      });
  }

  triggerCheck(): void {
    this.versionUpdateCheckService.triggerNewVersionCheck();
  }

  log(...arg: any): void {
    // tslint:disable-next-line:no-console
    console.log(...arg);
  }

  reconnect() {
    this.neoService.reconnect();
  }
}
