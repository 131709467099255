import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { ConnectionStatusType, IReconnectionStatus } from '@pm/lib/rx-websocket-subject';
import { RootChatState } from '@pm/chat/ngrx/index';

// ------------------------------------------------------------------------
// Actions
// ------------------------------------------------------------------------
export const ConnectionActions = {
  UpdateStatus: createAction('[Connection] Update Status', props<{ cst: ConnectionStatusType; }>()),
  Reconnecting: createAction('[Connection] Reconnecting', props<{ reconnecting: IReconnectionStatus; }>()),
};

// ------------------------------------------------------------------------
// Reducers
// ------------------------------------------------------------------------
export interface ConnectionState {
  status: ConnectionStatusType;
  reconnecting: IReconnectionStatus;
}

const initialState: ConnectionState = {
  status: ConnectionStatusType.WAITING,
  reconnecting: { count: 0 }
};

const connectionReducer = createReducer(
  initialState,
  on(ConnectionActions.UpdateStatus, (state, { cst }) => ({
    ...state,
    status: cst
  })),
  on(ConnectionActions.Reconnecting, (state, { reconnecting }) => ({
    ...state,
    reconnecting
  })),
);

export function ConnectionReducer(state: ConnectionState | undefined, action: Action): ConnectionState {
  return connectionReducer(state, action);
}
// ------------------------------------------------------------------------
// Selectors
// ------------------------------------------------------------------------
const rootChatState = createFeatureSelector<RootChatState>('chat');
const connectionState = createSelector(rootChatState, (state) => state.connection);

export const ConnectionSelectors = {
  Status: createSelector(connectionState, (state: ConnectionState) => state.status),
  Reconnecting: createSelector(connectionState, (state: ConnectionState) => state.reconnecting),
};
