import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CampaignService } from '@pm/campaign/services/campaign.service';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { takeUntil } from 'rxjs/operators';
import { ChatStateFacade } from '../../../chat/ngrx/chat-facade.service';
import { slideInLeft, slideOutRight } from '../../animations/slide-in-out';


@Component({
  selector: 'pm-campaign-navigation-bar',
  templateUrl: 'campaign-navigation-bar.component.html',
  styleUrls: ['campaign-navigation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideOutRight,
    slideInLeft,
  ]
})
export class CampaignNavigationBarComponent extends AbstractComponent implements OnInit {
  readonly logoUrl = 'assets/svg/pollpass-logo.svg';

  isRightToLeft = false;

  constructor(
    private readonly campaignService: CampaignService,
    private readonly chatFacade: ChatStateFacade,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.chatFacade.isRightToLeft$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((isRightToLeft): void => {
        this.isRightToLeft = isRightToLeft;
        this.changeDetector.markForCheck();
      });
  }
}
