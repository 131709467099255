import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NoContentComponent} from './info-pages/containers';

export const routes: Routes = [
  {
    path: '**',
    component: NoContentComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',

      // Useful for debugging
      // enableTracing: true,
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
