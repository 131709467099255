import { ActionReducer, ActionReducerMap, MetaReducer, } from '@ngrx/store';

import { environment } from '@messenger-env/environment';

import { LayoutReducer, LayoutState } from './layout.ngrx';
import { AuthReducer, AuthState } from './auth.ngrx';
import { UserReducer, UserState } from './user.ngrx';
import { SettingsReducer, SettingsState } from './settings.ngrx';
import { LocalisationReducer, LocalisationState } from './localisation.ngrx';

export interface RootState {
  layout: LayoutState;
  auth: AuthState;
  user: UserState;
  settings: SettingsState;
  localisation: LocalisationState;
}

export const RootReducers: ActionReducerMap<RootState> = {
  layout: LayoutReducer,
  auth: AuthReducer,
  user: UserReducer,
  settings: SettingsReducer,
  localisation: LocalisationReducer
};

export function logger(reducer: ActionReducer<RootState>): ActionReducer<any, any> {
  return (state: RootState, action: any): RootState => {
    // tslint:disable-next-line:no-console
    // console.log('ngrx - state', state);
    const { type, ...params } = action;

    // tslint:disable-next-line:no-console
    // console.log('ngrx - action - %s', type, params);

    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any>> = environment.isStoreLogEnabled ? [logger] : [];
