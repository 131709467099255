import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { AdTrackingService } from '@pm/core/services';
import { PageTitles } from '@pm/core/models/page-titles.model';
import { environment } from '@messenger-env/environment';

@Component({
  selector: 'app',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent extends AbstractComponent implements OnInit {
  name = 'Pollpass Messenger';
  url = '';
  isDebugEnabled = !!environment.isDebugEnabled;

  constructor(
    private readonly router: Router,
    private readonly adTracking: AdTrackingService,
    private readonly title: Title
  ) {
    super();
    this.adTracking.checkForAdSource();
    this.adTracking.pushAdSource();
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe((evt: NavigationEnd) => {
        const title = PageTitles.getTitle(evt.url.split('?')[0]);

        this.title.setTitle(`${title}`);
      });
  }
}
