import { ActionReducerMap } from '@ngrx/store';

import { ChatReducer, ChatState } from '@pm/chat/ngrx/chat.ngrx';
import { ConnectionReducer, ConnectionState } from '@pm/chat/ngrx/connection.ngrx';

export interface RootChatState {
  chat: ChatState;
  connection: ConnectionState;
}

export const ChatRootReducer: ActionReducerMap<RootChatState> = {
  chat: ChatReducer,
  connection: ConnectionReducer,
};
