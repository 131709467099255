<pm-infopage>
  <img title-image src="../../../assets/svg/overloaded.svg">

  <h1 class="heading-1">Sorry, we’re overloaded at the minute 🤕</h1>
  <p class="">
    We have a high number of users accessing Pollpass at the moment. Please try again in an hour or two.
  </p>
  <p class="">
    We have been working around the clock improving Pollpass so that we can handle everyone.
    Thank you for being so patient.
  </p>
</pm-infopage>
