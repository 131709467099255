import { Injectable } from '@angular/core';
import Bugsnag, { Client } from '@bugsnag/js';

import { environment } from '@messenger-env/environment';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';

interface BugsnagErrorOptions {
  name: string;
  message: string;
  groupingHash: string;
  severity: 'error' | 'info' | 'warning';
  ngDebugContext: {
    component: string;
    context: string;
  };
}

export class BugsnaggableError extends Error implements BugsnagErrorOptions {
  name: string;
  message: string;
  groupingHash: string;
  severity: 'error' | 'info' | 'warning';
  ngDebugContext: {
    component: string;
    context: string;
  };

  constructor(message: string, extras?: Partial<BugsnagErrorOptions>) {
    super(message);

    Object.assign(this, extras);
  }
}

@Injectable()
export class BugsnagService {

  bugsnagClient: Client | null = null;

  constructor(
    private readonly coreFacade: CoreStateFacade,
  ) {
    if (environment.bugsnagKey && environment.bugsnagStage) {
      this.bugsnagClient = Bugsnag.start({
        apiKey: environment.bugsnagKey || '',
        enabledReleaseStages: ['production', 'staging', 'testing'],
        releaseStage: environment.bugsnagStage,
        appVersion: `${environment.appVersion}`,
      });
    }
  }

  reportError(error: BugsnaggableError): void {
    if (this.bugsnagClient === null) {
      console.error('BugsnagService::reportError:: SKIP REPORT', error);
      return;
    }

    const handledState = {
      severity: (error && error.severity) || 'error',
      severityReason: { type: 'unhandledException' },
      unhandled: true,
    };

    const event = this.bugsnagClient.Event.create(
      error,
      true,
      handledState,
      'angular error handler',
      1
    );

    const id = this.getUserId();

    if (id) {
      event.setUser(id);
    }

    if (error.groupingHash) {
      event.groupingHash = error.groupingHash;
    }

    if (error.ngDebugContext) {
      event.addMetadata('angular', {
        component: error.ngDebugContext.component,
        context: error.ngDebugContext.context,
      });
    }

    this.bugsnagClient._notify(event);
  }

  leaveBreadcrumb(message: string, payload?: any): void {
    if (this.bugsnagClient === null) {
      return;
    }

    this.bugsnagClient.leaveBreadcrumb(message, payload);
  }

  // ------------------------------------------------------------------------------------------

  private getUserId(): any {
    let userId = null;

    // This should be written so its synchronous but given store' sync ability it's ok for the following example
    this.coreFacade.userOnceExisting$.subscribe(({ id }) => {
      userId = id;
    });

    return userId;
  }
}
