import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'button[pm-send-button]',
  templateUrl: './send-button.component.html',
  styleUrls: ['./send-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendButtonComponent {
}
