import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { BugsnagService } from '@pm/core/services';

@Injectable()
export class BugsnagErrorHandler extends ErrorHandler {
  constructor(
    private readonly injector: Injector
  ) {
    super();
  }

  handleError(error: any): void {
    console.error('BugsnagErrorHandler::handleError::', error);

    this.injector.get<BugsnagService>(BugsnagService).reportError(error);

    ErrorHandler.prototype.handleError.call(this, error);
  }
}
