<div class="error-page-wrapper">
  <div class="m-2rem">
    <img src="../../../assets/svg/logo.svg" class="logo">
  </div>

  <div class="container">

    <p class="fs-60">🙈</p>

    <h1 class="color-ink-2 my-1rem">
      Oops, Something Went Wrong
    </h1>

    <p class="my-1rem">
      Thank you for trying to participate but this survey isn't currently available. Please check you have the
      correct link and try again later.
    </p>
  </div>
</div>
