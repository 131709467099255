import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';

import { ChatStateFacade } from '@pm/chat/ngrx/chat-facade.service';
import { ChatRootReducer } from '@pm/chat/ngrx';

import { SharedModule } from '@pm/shared/shared.module';

import { AnswerMessageService, LocalisationService, NeoService } from './services';
import { ChatComponent } from './containers';
import {
  AnsweredQuestionMessageComponent,
  AnswerInputComponent,
  BundleNameComponent,
  HtmlTextComponent,
  LanguageQuestionMessageComponent,
  LoadingBubbleComponent,
  MessageBubbleComponent,
  MessageComponent,
  MultipleQuestionMessageComponent,
  OpenEndedQuestionMessageComponent,
  RadioQuestionMessageComponent,
  StatementMessageComponent
} from './components';
import { MessageAnchorDirective, } from './directives';
import { ScrollService } from '@pm/chat/services/scroll.service';
import { ConnectionStateFacade } from '@pm/chat/ngrx/connection-facade.service';
import { NeoMessageHandlerService } from '@pm/chat/services/neo-message-handler.service';
import { ChatTimeTrackingService } from '@pm/chat/services/chat-time-tracking.service';
import { QuestionMessageItemImageContainerComponent } from './components/question-message-item-image-container/question-message-item-image-container/question-message-item-image-container.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('chat', ChatRootReducer)
  ],
  declarations: [
    ChatComponent,
    AnswerInputComponent,
    MessageBubbleComponent,
    LoadingBubbleComponent,
    MessageComponent,
    HtmlTextComponent,
    MessageAnchorDirective,
    AnsweredQuestionMessageComponent,
    StatementMessageComponent,
    RadioQuestionMessageComponent,
    MultipleQuestionMessageComponent,
    LanguageQuestionMessageComponent,
    OpenEndedQuestionMessageComponent,
    BundleNameComponent,
    QuestionMessageItemImageContainerComponent
  ],
  exports: [
    CommonModule,
    LoadingBubbleComponent,
    MessageBubbleComponent,
    ChatComponent
  ],
  providers: [
    NeoService,
    NeoMessageHandlerService,
    AnswerMessageService,
    ScrollService,
    ChatStateFacade,
    ConnectionStateFacade,
    ChatTimeTrackingService,
    LocalisationService
  ],
  entryComponents: [
    MessageBubbleComponent,
    AnsweredQuestionMessageComponent,
    RadioQuestionMessageComponent,
    MultipleQuestionMessageComponent,
    LanguageQuestionMessageComponent,
    StatementMessageComponent,
    QuestionMessageItemImageContainerComponent
  ]
})
export class ChatModule {
}
