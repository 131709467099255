import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';

@Component({
  selector: 'pm-answer-input',
  templateUrl: 'answer-input.component.html',
  styleUrls: ['answer-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.testing]': 'isTestingMode'
  }
})
export class AnswerInputComponent extends AbstractComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() isLoading: boolean;
  @Input() submitButtonName: Observable<string>;

  @Output() clicked: EventEmitter<void>;

  private click$: Subject<void>;

  isTestingMode = false;

  constructor(private coreFacade: CoreStateFacade,
              private readonly cdr: ChangeDetectorRef) {
    super();

    this.clicked = new EventEmitter<void>();
    this.click$ = new Subject<void>();
  }

  ngOnInit(): void {
    // Since the submit hide animation takes a few ms, its possible that users could trigger
    // more than one click if they click like maniacs. ThrottleTime will ensure not all clicks
    // trigger a submit
    this.click$
      .pipe(
        throttleTime(1000),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.clicked.emit());

    this.coreFacade.isTestingMode$.subscribe((isTesting) => {
      this.isTestingMode = isTesting;
      this.cdr.markForCheck();
    });

    this.coreFacade.settingsState$.subscribe((state) => {
      this.isTestingMode = (state.isTestingMode && !state.isDash2Mode);
      this.cdr.markForCheck();
    });
  }

  handleClick(): void {
    this.click$.next();
  }
}
