import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AbstractNotificationComponent } from '@pm/notification/components/abstract-notification.component';

@Component({
  selector: 'pm-notification-chat-error',
  templateUrl: './notification-chat-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationChatErrorComponent extends AbstractNotificationComponent {
  @Input() errorMessage: string;
}
