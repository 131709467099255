import { ScreenSize } from '@pm/core/services';

export interface MessageMeta {
  quick: boolean;
  indecisive: boolean;
  direct: boolean;
  shownAt: number; // Timestamp for when the message was received
}

export interface MessageMetaModel {
  quick: boolean;
  indecisive: boolean;
  direct: boolean;
  shown_at: number | null; // Timestamp for when the message was received
  answered_at: number; // Timestamp for when the answer was sent
  device_pixel_ratio: number;
  screen_resolution_height: number;
  screen_resolution_width: number;
  window_resolution_height: number;
  window_resolution_width: number;
  // translated_lang: null | string; // null means it was not translated / 2 letter country iso string or '-' if language was not detected
}

export function toMessageMetaModel(meta: Partial<MessageMeta>, screen: ScreenSize/*, lngMeta: string | null*/): MessageMetaModel {
  return {
    quick: meta.quick || false,
    direct: meta.direct || false,
    indecisive: meta.indecisive || false,
    shown_at: meta.shownAt || null,

    answered_at: Date.now(),

    screen_resolution_width: screen.screenWidth,
    screen_resolution_height: screen.screenHeight,
    device_pixel_ratio: screen.pixelRatio,
    window_resolution_width: screen.windowWidth,
    window_resolution_height: screen.windowHeight,

    // translated_lang: lngMeta,
  };
}
