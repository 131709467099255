import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { KitchensinkPageComponent } from './containers';

export const routes: Routes = [
  {
    path: 'kitchensink',
    component: KitchensinkPageComponent,
    canActivate: [],
    resolve: {
    },
    children: [
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
})
export class KitchensinkRoutingModule {
}
