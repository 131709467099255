import { Injectable } from '@angular/core';

import { BrowserStorageService } from './browser-storage.service';
import { WindowRefService } from './window-ref.service';
import { Utils } from '@pm/utils';

export interface IAdTrackingSource {
  source: string;
  expires_on: number;
}

@Injectable()
export class AdTrackingService {
  readonly storageKey = 'ad_tracking_source';

  private readonly _window: any;

  constructor(
    private readonly windowRef: WindowRefService,
    private readonly storageService: BrowserStorageService
  ) {
    this._window = windowRef.nativeWindow;
  }

  checkForAdSource() {
    const ref = this._window.document.referrer || '';
    const params = Utils.parseQueryParams(ref);

    if (params.has(`redirect_type`) &&
      params.get('redirect_type') === 'adtracking' &&
      params.has('source')) {
      this.storeAdSource(params.get('source'));
    }
  }

  storeAdSource(source: string) {
    const expiresOn = Date.now() + (30 * 24 * 60 * 60 * 1000);  // 30 days expiration time
    const data: IAdTrackingSource = {source, expires_on: expiresOn};

    this.storageService.setItem(this.storageKey, data);
  }

  retrieveAdSource(): IAdTrackingSource {
    return this.storageService.getItem(this.storageKey);
  }

  clearAdSource() {
    this.storageService.removeItem(this.storageKey);
  }

  pushAdSource() {
    if (!this.hasAdSource) {
      return;
    }

    const data: IAdTrackingSource = this.retrieveAdSource();

    if (this._window.dataLayer) {
      this._window.dataLayer.push({adTrackingSource: data.source});
    }
  }

  get hasAdSource(): boolean {
    const data: IAdTrackingSource = this.storageService.getItem(this.storageKey);

    if (data && data.expires_on < Date.now()) {
      this.clearAdSource();
    }

    return data && data.expires_on > Date.now();
  }
}
