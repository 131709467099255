export interface IAffiliateSession {
  source: string;
  key: string | number;
  click_id: string | number;
  expires_on: number;
}

export class AffiliateSession {
  constructor(
    public source: string,
    public key: string | number,
    public clickId: string | number
  ) {
  }

  toJSON() {
    const clone = Object.assign({}, this) as AffiliateSession;

    return {
      source: clone.source,
      key: clone.key,
      click_id: clone.clickId
    };
  }
}
