import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { Answers, Message } from '@pm/chat/models/message.model';
import { MessageMeta } from '@pm/chat/models/message-meta.model';
import { RootChatState } from '@pm/chat/ngrx/index';

// ------------------------------------------------------------------------
// Actions
// ------------------------------------------------------------------------
export const ChatActions = {
  addMessage: createAction('[Chat] Add Message', props<{ message: Message; }>()),
  removeMessage: createAction('[Chat] Remove Message', props<{ messageId: string; }>()),
  updateMessage: createAction('[Chat] Update Message', props<{ message: Message; }>()),
  updateActiveMessage: createAction('[Chat] Update Active Message', props<{ activeMessage: ActiveMessage; }>()),
  updateLastError: createAction('[Chat] Update Last error', props<{ error: string; }>()),
  updateActiveMessageData: createAction('[Chat] Update Active Message Data', props<{ answers: Answers; meta: Partial<MessageMeta>; }>()),
  setProcessingHistory: createAction('[Chat] Processing history', props<{ isProcessing: boolean; }>()),
  clear: createAction('[Chat] Clear Chat'),
};

// ------------------------------------------------------------------------
// Reducers
// ------------------------------------------------------------------------
export interface ActiveMessage {
  message: Message;
  meta: Partial<MessageMeta>;
  answers?: Answers;
}

export interface ChatState {
  messages: Map<string, Message>;
  processingHistory: boolean;
  activeMessage: ActiveMessage | null;
  lastError: string | null;
}

const initialState: ChatState = {
  messages: new Map(),
  activeMessage: null,
  processingHistory: true,
  lastError: null,
};

const chatReducer = createReducer(
  initialState,
  on(ChatActions.addMessage, (state, { message }) => ({
    ...state,
    messages: new Map(state.messages).set(message.id, message)
  })),
  on(ChatActions.removeMessage, (state, { messageId }) => {
    const messages = new Map(state.messages);
    messages.delete(messageId);

    return {
      ...state,
      messages,
    };
  }),
  on(ChatActions.updateMessage, (state, { message }) => ({
    ...state,
    messages: new Map(state.messages).set(message.id, { ...state.messages.get(message.id), ...message })
  })),
  on(ChatActions.updateLastError, (state, { error }) => ({
    ...state,
    lastError: error,
  })),
  on(ChatActions.updateActiveMessage, (state, { activeMessage }) => ({
    ...state,
    activeMessage
  })),
  on(ChatActions.updateActiveMessageData, (state, { answers, meta }) => ({
    ...state,
    activeMessage: {
      ...state.activeMessage,
      answers,
      meta: {
        ...state.activeMessage && state.activeMessage.meta,
        ...meta
      },
    }
  })),
  on(ChatActions.setProcessingHistory, (state, { isProcessing }) => ({
    ...state,
    processingHistory: isProcessing
  })),
  on(ChatActions.clear, () => ({
    ...initialState,
  }))
);

export function ChatReducer(state: ChatState | undefined, action: Action): ChatState {
  return chatReducer(state, action);
}

// ------------------------------------------------------------------------
// Selectors
// ------------------------------------------------------------------------
const rootChatState = createFeatureSelector<RootChatState>('chat');
const chatState = createSelector(rootChatState, (state) => state.chat);

export const ChatSelectors = {
  messages: createSelector(chatState, (state: ChatState) => state.messages),
  activeMessage: createSelector(chatState, (state: ChatState) => state.activeMessage),
  processingHistory: createSelector(chatState, (state: ChatState) => state.processingHistory),
  lastError: createSelector(chatState, (state: ChatState) => state.lastError),
};
