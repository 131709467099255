import { Injectable, Injector } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';

enum URL {
  CHAT = '/',
  SIGN_IN = '/public/sign-in',
  SIGN_UP = '/public/register',
  NEW_HOME = '',
  LOGOUT = '/logout',
  REGISTRATION_HOLDING = '/public/registration-holding',
  BIRTH_DATE = '/public/add-birth-date',
  NO_COUNTRY = '/public/unsupported-country',
  ACCOUNT_DEACTIVATED = '/public/account-deactivated',
  ACCOUNT_PENDING_VERIFICATION = 'public/account-pending-verification',
  ACCOUNT_PENDING_REVIEW = 'public/account-pending-review',
  CAMPAIGN_ERROR = '/public/campaign-error',
  OVERLOADED = '/public/overloaded',
  RATE_LIMIT = '/public/rate-limit',
  PHONE_VERIFICATION = '/public/phone-verification',
  PHONE_VERIFICATION_TOKEN = '/public/phone-verification/token',
  WALLET = '/wallet',
  WALLET_SUCCESS = '/wallet/success',
  WALLET_INCENTIVES = '/wallet/incentives',
}

@Injectable()
export class RoutingService {

  static URL = URL;

  private router: Router;

  constructor(
    private readonly injector: Injector,
  ) {
  }

  gotoChat(extras?: NavigationExtras): void {
    return this.navigateByUrl(URL.CHAT, extras);
  }

  gotoLogin(): void {
    return this.navigateByUrl(URL.SIGN_IN);
  }

  gotoCampaignError(): void {
    return this.navigateByUrl(URL.CAMPAIGN_ERROR);
  }

  gotoAccountDeactivated(): void {
    return this.navigateByUrl(URL.ACCOUNT_DEACTIVATED);
  }

  gotoOverloaded(): void {
    return this.navigateByUrl(URL.OVERLOADED);
  }

  gotoUnsupportedCountry(): void {
    return this.navigateByUrl(URL.NO_COUNTRY);
  }

  gotoRegister(): void {
    return this.navigateByUrl(URL.SIGN_UP);
  }

  gotoRateLimit(): void {
    return this.navigateByUrl(URL.RATE_LIMIT);
  }

  gotoInVerification(): void {
    return this.navigateByUrl(URL.ACCOUNT_PENDING_VERIFICATION);
  }

  gotoInReview(): void {
    return this.navigateByUrl(URL.ACCOUNT_PENDING_REVIEW);
  }

  gotoPhoneVerification(): void {
    return this.navigateByUrl(URL.PHONE_VERIFICATION);
  }

  gotoIncentivesProvider(provider: string): void {
    return this.navigateByUrl(`${URL.WALLET_INCENTIVES}?provider=${provider}`);
  }

  gotoIncentivesError(errorType: string): void {
    return this.navigateByUrl(`${URL.WALLET_INCENTIVES}?&error_message=${errorType}`);
  }

  gotoWalletSuccess(type: string): void {
    return this.navigateByUrl(URL.WALLET_SUCCESS, { state: { type } });
  }

  gotoLogout(): void {
    this.navigateByUrl(URL.LOGOUT, { skipLocationChange: true });
  }

  navToChat(): Promise<boolean> {
    return this.getRouter().navigateByUrl(URL.CHAT);
  }

  url(url: string): UrlTree {
    return this.getRouter().parseUrl(url);
  }

  /**
   * Currently best way to reload a route. (should find something better though)
   */
  reloadCurrentRoute(): void {
    const currentUrl = this.getRouter().url;

    this.getRouter()
      .navigateByUrl('/zzz', { skipLocationChange: true })
      .then(() => this.getRouter().navigateByUrl(currentUrl));
  }

  private getRouter(): Router {
    if (!this.router) {
      this.router = this.injector.get<Router>(Router);
    }

    return this.router;
  }

  private navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): void {
    void this.getRouter().navigateByUrl(url, extras);
  }

}
