<div class="container pb-md-0 pb-1rem">
  <div class="row">
    <div class="col-md-3 d-flex">
      <div class="m-auto text-center py-1rem">
        <img
          src="../../../assets/svg/icn-neo-error.svg"
          alt="email"
          class="img-fluid pm-notification__img"
        />
      </div>
    </div>

    <div class="col-md-6 d-flex m-auto">
      <div>
        <h2 class="mt-1rem m-md-0 mb-0">Oops, something went wrong</h2>

        <p class="mt-_5rem mb-1rem mb-md-0">
          {{errorMessage}}
        </p>
      </div>
    </div>

    <div class="col-md-3 m-auto d-flex">
      <button
        pm-button
        class="btn-primary outline block"
        (click)="clickButton()"
        [isLoading]="buttonLoading$ | async"
      >
        Reconnect
      </button>
<!--
      <a
        pm-button
        class="btn-info block"
        href="mailto:support@pollpass.com"
      >
        Contact support
      </a>
-->
    </div>
  </div>
</div>
