<ng-container *ngIf="isUserLoaded">
  <pm-campaign-navigation-bar></pm-campaign-navigation-bar>

  <section class="content">
    <div class="navigation-spacer"></div>
    <pm-notification></pm-notification>
    <pm-chat></pm-chat>
  </section>
</ng-container>

<ng-container *ngIf="isTimeoutError">
  <div class="error-page-wrapper">
    <div class="m-2rem">
      <img src="../../../assets/svg/logo.svg" class="logo">
    </div>

    <div class="container">
      <p class="fs-60">🙈</p>

      <h1 class="color-ink-2 my-1rem">
        A connection error has occurred.
      </h1>

      <p class="my-1rem">
        Please check your internet connection and click on the button below to connect again.
      </p>

      <button
        (click)="reconnect()"
        pm-button
        [isLoading]="isReconnectLoading$ | async"
        class="primary mt-2rem"
      >
        Reconnect
      </button>
    </div>
  </div>
</ng-container>
