import { IBrowserStorage } from '@pm/core/models/browser-storage.model';

export class BrowserStorageService {
  constructor(
    private readonly storage: IBrowserStorage,
  ) {
  }

  setItem(key: string, value: any): void {

    if (value === undefined) {
      this.removeItem(key);
      return;
    }

    this.storage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  getItem(key: string): any {
    const storageItem = this.storage.getItem(key);

    try {
      return JSON.parse(storageItem);
    } catch (e) {
      console.error('Error while retriving key [%s] from LocalStorage', key, storageItem, e);
      return null;
    }
  }

  clear(): void {
    this.storage.clear();
  }
}
