import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  Type,
  ViewChild
} from '@angular/core';
import { AnsweredQuestionMessageComponent } from '@pm/chat/components/answered-question-message/answered-question-message.component';
import { LanguageQuestionMessageComponent } from '@pm/chat/components/language-question-message/language-question-message.component';
import { MessageBubbleComponent } from '@pm/chat/components/message-bubble/message-bubble.component';
import { MultipleQuestionMessageComponent } from '@pm/chat/components/multiple-question-message/multiple-question-message.component';
import { OpenEndedQuestionMessageComponent } from '@pm/chat/components/open-ended-question-message/open-ended-question-message.component';
import { RadioQuestionMessageComponent } from '@pm/chat/components/radio-question-message/radio-question-message.component';
import { StatementMessageComponent } from '@pm/chat/components/statement-message/statement-message.component';
import { MessageAnchorDirective } from '@pm/chat/directives';
import { BubbleType } from '@pm/chat/models/bubble.model';
import { MessageMeta } from '@pm/chat/models/message-meta.model';
import {
  Answers,
  AnswerViewMessage,
  GoodByeMessage,
  LanguageQuestionMessage,
  Message,
  MessageType,
  MultipleQuestionMessage,
  RadioQuestionMessage,
  ScreenOutMessage,
  StatementMessage,
  WebhookExecutedMessage
} from '@pm/chat/models/message.model';
import { ScrollService } from '@pm/chat/services/scroll.service';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { WindowRefService } from '@pm/core/services';
import { Observable } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';

type QuestionMessage = RadioQuestionMessage | MultipleQuestionMessage | LanguageQuestionMessage;

function isRadioQuestionComponent(questionComponent: object): questionComponent is RadioQuestionMessageComponent {
  return questionComponent && questionComponent instanceof RadioQuestionMessageComponent;
}

function isMultipleQuestionComponent(questionComponent: object): questionComponent is MultipleQuestionMessageComponent {
  return questionComponent && questionComponent instanceof MultipleQuestionMessageComponent;
}

function isOpenEndedQuestionComponent(questionComponent: object): questionComponent is OpenEndedQuestionMessageComponent {
  return questionComponent && questionComponent instanceof OpenEndedQuestionMessageComponent;
}

@Component({
  selector: 'pm-message',
  templateUrl: 'message.component.html',
  styleUrls: ['message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent extends AbstractComponent implements OnInit, OnChanges, AfterViewInit {
  @HostBinding('class.active') @Input() isActive: boolean;
  @HostBinding('class.left-to-right') @Input() isRightToLeft = false;
  @Input() message: Message;
  @Input() activeAnswers: Answers;
  @Input() error$: Observable<string>;

  @Output() answerSubmit = new EventEmitter<string>();
  @Output() answerChange = new EventEmitter<{ answers: Answers, meta: Partial<MessageMeta> }>();
  @Output() resetConversation = new EventEmitter();
  @Output() rollbackConversation = new EventEmitter<string>();

  @ViewChild(MessageAnchorDirective, { static: true }) anchor: MessageAnchorDirective;

  isTesting = false;

  private messageInstance: any;

  constructor(
    private readonly cfr: ComponentFactoryResolver,
    private readonly cdr: ChangeDetectorRef,
    private readonly host: ElementRef,
    private readonly renderer: Renderer2,
    private readonly scrollService: ScrollService,
    private core: CoreStateFacade,
    private window: WindowRefService
  ) {
    super();
  }

  ngOnInit() {
    this.addHostClass('message-container');

    this.core.isTestingMode$.subscribe((isTesting) => this.isTesting = isTesting);

    if (!this.message) {
      return;
    }

    switch (this.message.type) {
      case MessageType.AnswerView:
        this.addHostClass('answer');
        break;
      case MessageType.Statement:
        // this.addHostClass('statement');
        // break;
      case MessageType.RadioQuestion:
      case MessageType.MultipleQuestion:
      case MessageType.OpenEndedQuestion:
      case MessageType.LanguageQuestion:
      case MessageType.Error:
      default:
        this.addHostClass('question');
        break;
    }

    this.error$
      .pipe(
        takeWhile(() => this.isActive),
        filter((error) => error !== null),
      )
      .subscribe((error) => {
        const currentInstance = this.messageInstance;
        if (currentInstance) {
          const currentMessageElement = this.host.nativeElement;
          switch (this.message.type) {
            case MessageType.RadioQuestion:
            case MessageType.LanguageQuestion:
            case MessageType.MultipleQuestion:
            case MessageType.OpenEndedQuestion:
              currentInstance.isError = error && error !== '';
              this.scrollService.scrollToPosition(.6, {y: currentMessageElement.offsetTop});
              currentInstance.markForCheck();
              break;
            default:
              break;
          }
        }
      });

    this.reloadInstance();
  }

  ngAfterViewInit(): void {
    this.window.nativeWindow.requestAnimationFrame(() => {
      const currentMessageElement = this.host.nativeElement;
      this.scrollService.scrollToPosition(.6, { y: currentMessageElement.offsetTop });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive && !changes.isActive.isFirstChange()) {
      this.reloadInstance();
    }
  }

  // Public only due to testing
  getMessageFactory(messageType: MessageType): (message: Message) => void {
    switch (messageType) {
      case MessageType.Statement:
        return this.loadStatementMessage.bind(this);

      case MessageType.AnswerView:
        return this.loadAnswerViewMessage.bind(this);

      case MessageType.RadioQuestion:
        return this.loadQuestionMessage(RadioQuestionMessageComponent).bind(this);

      case MessageType.MultipleQuestion:
        return this.loadQuestionMessage(MultipleQuestionMessageComponent).bind(this);

      case MessageType.LanguageQuestion:
        return this.loadQuestionMessage(LanguageQuestionMessageComponent).bind(this);

      case MessageType.OpenEndedQuestion:
        return this.loadQuestionMessage(OpenEndedQuestionMessageComponent).bind(this);

      case MessageType.GoodBye:
        return this.isTesting ? this.loadGoodbyeMessage.bind(this) : this.loadNoMessage.bind(this);

      case MessageType.Error:
        return this.loadStatementMessage.bind(this);

      case MessageType.ScreenOut:
        return this.loadScreenOutMessage.bind(this);

      case MessageType.WebhookExecuted:
        return this.loadWebhookExecutedMessage.bind(this);

      default:
        throw new Error(`Message type ${messageType} loader is not defined!`);
    }
  }

  private reloadInstance(): void {
    if (this.messageInstance) {
      const currentInstance = this.messageInstance;

      if (currentInstance.close) {
        // This is specific for Question Messages.
        // Question messages use 2 different components depending on whether or not their state is active
        // So before recreating a component we close it first, wait for animation to complete and we create a new component
        currentInstance.close()
          .then(() => {
            this.anchor.viewContainerRef.clear();
            this.messageInstance = this.getMessageFactory(this.message.type)(this.message);
            this.cdr.markForCheck();
          });
      } else {
        // Otherwise dont reload the component, update only the active state if the component has one.
        if (currentInstance.isActive !== undefined) {
          currentInstance.isActive = this.isActive;
          this.cdr.markForCheck();
        }
      }
    } else {
      this.messageInstance = this.getMessageFactory(this.message.type)(this.message);
      this.cdr.markForCheck();
    }
  }

  private resolveComponent<T>(component: Type<T>): T {
    const componentFactory = this.cfr.resolveComponentFactory(component);

    return <T>this.anchor
      .viewContainerRef
      .createComponent(componentFactory)
      .instance;
  }

  private loadWebhookExecutedMessage(message: WebhookExecutedMessage): StatementMessageComponent {
    const instance = this.resolveComponent(StatementMessageComponent);

    instance.message = message;
    instance.isRightToLeft = this.isRightToLeft;

    instance.name = 'Quota Call Triggered';

    return instance;
  }

  private loadScreenOutMessage(message: ScreenOutMessage): StatementMessageComponent {
    const instance = this.resolveComponent(StatementMessageComponent);

    instance.message = message;
    instance.isRightToLeft = this.isRightToLeft;

    instance.name = 'Screen Out Triggered';

    return instance;
  }

  private loadGoodbyeMessage(message: GoodByeMessage): StatementMessageComponent {
    const instance = this.resolveComponent(StatementMessageComponent);

    instance.message = {...message, nameHtml: 'You have reached the end of the survey.'};
    instance.isRightToLeft = this.isRightToLeft;

    instance.name = 'Survey Completed';

    instance.resetConversation
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.resetConversation.emit());

    return instance;
  }

  private loadStatementMessage(message: StatementMessage): StatementMessageComponent {
    const instance = this.resolveComponent(StatementMessageComponent);

    instance.message = message;
    instance.isTesting = this.isTesting;
    instance.isRightToLeft = this.isRightToLeft;

    instance.rollbackConversation
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.rollbackConversation.emit(instance.message.id));

    return instance;
  }

  private loadAnswerViewMessage(message: AnswerViewMessage): MessageBubbleComponent {
    const instance = this.resolveComponent<MessageBubbleComponent>(MessageBubbleComponent);

    instance.text = message.nameHtml;
    instance.type = BubbleType.Answer;
    instance.isRightToLeft = this.isRightToLeft;

    return instance;
  }

  private loadQuestionMessage<T>(questionComponent: Type<T>): (message: QuestionMessage) => QuestionMessage {
    return (message: QuestionMessage) => {
      let instance = null;

      if (this.isActive) {
        instance = this.resolveComponent(questionComponent);
        instance.message = message;
        instance.open();

        if (isMultipleQuestionComponent(instance)) {
          instance.isRightToLeft = this.isRightToLeft;
          instance.answersInput = this.activeAnswers;
        }

        if (isRadioQuestionComponent(instance)) {
          instance.isRightToLeft = this.isRightToLeft;
          instance.answerSubmit
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((messageId) => this.answerSubmit.emit(messageId));
        }

        if (isOpenEndedQuestionComponent(instance)) {
          instance.isRightToLeft = this.isRightToLeft;
          instance.answerSubmit
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((messageId) => this.answerSubmit.emit(messageId));
        }

        instance.answerChange
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe((change) => this.answerChange.emit(change));

      } else {
        instance = this.resolveComponent(AnsweredQuestionMessageComponent);
        instance.message = message;
        instance.isTesting = this.isTesting;
        instance.isRightToLeft = this.isRightToLeft;
        instance.rollbackConversation
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(() => this.rollbackConversation.emit(instance.message.id));
      }

      return instance;
    };
  }

  private loadNoMessage(): null {
    return null;
  }

  private addHostClass(className: string): void {
    this.renderer.addClass(this.host.nativeElement, className);
  }
}
