import { NgModule } from '@angular/core';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// ------------- Important ! -------------
// Careful to import each font separately as its own module!
// This is important because treeshaking does not work otherwise
// ------------- Important ! -------------
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons/faSignOutAlt';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleRight';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';


@NgModule({
  imports: [
    FontAwesomeModule
  ],
  declarations: [],
  exports: [],
  providers: [],
})
export class FontawesomeCoreModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faUser,
      faCheck,
      faAngleRight,
      faAngleLeft,
      faEnvelope,
      faFacebook,
      faGoogle,
      faChevronDown,
      faChevronDoubleRight,
      faChevronRight,
      faChevronLeft,
      faPaperPlane,
      faSpinner,
      faSignOutAlt,
      faSpinnerThird,
      faPlay
    );
  }
}
