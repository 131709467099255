import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AbstractNotificationComponent } from '@pm/notification/components/abstract-notification.component';

@Component({
  selector: 'pm-notification-emailreg',
  templateUrl: './notification-emailreg.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationEmailregComponent extends AbstractNotificationComponent {
}
