import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, Injector, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { pluck, takeUntil } from 'rxjs/operators';

import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { addGSDevtoolsScript } from '@pm/lib/add-gsdevtools';
import { SusiAnimationComponent } from '@pm/shared/components';
import { WindowRefService } from '@pm/core/services';
import { TimelineLite } from 'gsap';

interface AnimationComponent {
  animation: TimelineLite;
}

@Component({
  selector: 'pm-animation-debug',
  templateUrl: 'animation-debug.component.html',
  styleUrls: ['animation-debug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimationDebugComponent extends AbstractComponent implements OnInit, OnDestroy {

  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  private gsTool: any;

  constructor(
    private readonly windowRef: WindowRefService,
    private readonly injector: Injector,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super();

    addGSDevtoolsScript();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        pluck('animationName'),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((animationName) => {
        let animation = null;

        switch (animationName) {
          case 'susi':
            animation = this.createComponent(SusiAnimationComponent);
            break;
          default:
            console.warn('Wrong animation name given');
        }

        if (animation) {
          this.gsTool = this.getDevTools().create({ paused: true, animation });
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.gsTool) {
      this.gsTool.kill();
    }
  }

  private createComponent(component: Type<AnimationComponent>, extras: object = {}): TimelineLite {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component);

    const instance = this.container.createComponent(componentRef).instance;

    Object.assign(instance, extras);

    return instance.animation;
  }

  private getDevTools(): any {
    // noinspection TypeScriptUnresolvedVariable
    return this.windowRef.nativeWindow.GSDevTools;
  }
}
