import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DebugComponent } from '@pm/core/debug/debug.component';
import { SharedModule } from '@pm/shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule
  ],
  declarations: [
    DebugComponent,
  ],
  exports: [
    DebugComponent,
  ],
  providers: [],
})
export class DebugModule {
}
