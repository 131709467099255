import { Injectable } from '@angular/core';

import { Power2, TweenLite } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

import { WindowRefService } from '@pm/core/services';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const plugins = [ScrollToPlugin];

interface ScrollToCoordinates {
  x?: number;
  y?: number;
}
interface ScrollToParams {
  scrollTo: ScrollToCoordinates;
  delay: number;
  autoKill: boolean;
}

@Injectable()
export class ScrollService {

  private firstScrollQueue$: Subject<void>;
  private onComplete: () => void;
  private scrollTarget: HTMLElement | Window;

  constructor(
    private readonly windowRef: WindowRefService,
    private readonly coreFacade: CoreStateFacade,
  ) {
    this.firstScrollQueue$ = new Subject();
    this.scrollTarget = this.windowRef.nativeWindow;

    this.onComplete = () => this.firstScrollQueue$.next();

    this.firstScrollQueue$
      .pipe(
        debounceTime(200),
      )
      .subscribe(() => {
        // console.warn('ScrollService::firstScrollQueue$::subscribe');

        this.coreFacade.setFirstScrollDone();
        this.firstScrollQueue$.complete();

        this.onComplete = () => {
        };
      });
  }

  setScrollTarget (target: HTMLElement | Window) {
    this.scrollTarget = target;
  }

  scrollTo(sp: Partial<ScrollToParams>): void {
    const { delay, scrollTo } = sp;

    TweenLite.to(
      this.scrollTarget,
      delay,
      {
        scrollTo,
        ease: Power2.easeOut,
        onComplete: this.onComplete.bind(this),
      }
    );
  }

  scrollToPosition(delay: number = 0, scrollTo: ScrollToCoordinates): void {
    this.scrollTo({ delay, scrollTo });
  }
}
