<div class="row">
  <div class="col">
    <h2>Inputs</h2>
    <br>
  </div>
</div>
<div class="row">
  <div class="col-md">
    <div class="row">
      <div class="col-6">
        <h3>Text inputs</h3>
        <br>

        <div class="form-group">
          <label for="testInput">Test input label</label>
          <input
            id="testInput"
            class="form-control"
            placeholder="Test input placeholder"
          />
        </div>

        <div class="form-group">
          <label for="testInput">Test input1 label</label>
          <input
            id="testInput1"
            class="form-control is-invalid"
            placeholder="Test input1 placeholder"
          />
          <div class="invalid-feedback">Something isn't right</div>
          <div class="valid-feedback">All is right</div>
        </div>

        <div class="form-group">
          <label for="testInput">Test input2 label <b>(NOT IN USE)</b></label>
          <input
            id="testInput2"
            class="form-control is-valid"
            placeholder="Test input1 placeholder"
            min="2"
          />
          <div class="invalid-feedback">Something isn't right</div>
          <div class="valid-feedback">All is right</div>
        </div>
      </div>

      <div class="col-6">
        <h3>Radio buttons and checkboxes</h3>
        <br>

        <div class="form-check">

          <input
            type="radio"
            id="testRadio1"
            class="form-check-input"
            placeholder="Test input placeholder"
          />
          <label
            for="testRadio1"
            class="form-check-label"
          >
            Test radio label
          </label>
        </div>

        <div class="form-check">

          <input
            type="radio"
            id="testRadio2"
            class="form-check-input"
          />
          <label
            for="testRadio2"
            class="form-check-label"
          >
            Test radio label
          </label>
        </div>

        <div class="form-check">
          <input
            type="checkbox"
            id="testCheck1"
            class="form-check-input"
          />
          <label
            for="testCheck1"
            class="form-check-label"
          >
            Test checkbox label
          </label>
        </div>

        <br>
        <h3>Custom Radio buttons and Checkboxes</h3>
        <br>

        <div>
          <pm-checkbox
            idField="testCheck11"
          >
            Some value
          </pm-checkbox>
          <pm-checkbox
            idField="testCheck112"
            class="button"
          >
            Some value 1
          </pm-checkbox>

          <pm-radio
            idField="testCheck113"
            disabled
          >
            Some value 1
          </pm-radio>
        </div>
      </div>
      <div class="col-6">
        <h3>Custom checkbox `pm-checkbox`</h3>

      </div>
    </div>
  </div>
</div>
