<pm-infopage>
  <img title-image src="../../assets/svg/lost.svg">

  <h1>Oops, something went wrong...</h1>
  <p class="">
    The page you were looking for could not be found.
    We're sorry about that! Click below to go back home.
  </p>
  <button pm-button class="" routerLink="/">Back Home</button>
</pm-infopage>
