import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { merge, of, Subject, timer } from 'rxjs';
import { catchError, filter, map, pluck, switchMap, take } from 'rxjs/operators';

import { environment } from '@messenger-env/environment';
import { NotificationService } from '@pm/notification/services/notification.service';
import { NotificationType } from '@pm/notification/types';
import { NotificationNewVersionComponent } from '@pm/notification/components/notification-new-version/notification-new-version.component';
import { WindowRefService } from '@pm/core/services/window-ref.service';

const VERSION_CHECK_URL = '/assets/version.json';
const TIME_INTERVAL_MS = 60 * 60 * 1000; // check every hour

@Injectable()
export class VersionUpdateCheckService {
  private readonly currentVersionHash: string | number | null;
  private readonly triggerNewVersion$: Subject<void>;

  constructor(
    private readonly http: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly windowRefService: WindowRefService,
  ) {
    this.currentVersionHash = environment.appVersion;
    this.triggerNewVersion$ = new Subject<void>();
  }

  init(): void {
    merge(
      timer(TIME_INTERVAL_MS, TIME_INTERVAL_MS),
      this.triggerNewVersion$
    )
      .pipe(
        switchMap(() => (
          this.http.get(VERSION_CHECK_URL)
            .pipe(
              pluck('version'),
              map((v) => v && v !== 'unknown' && v !== this.currentVersionHash),
              catchError(() => of(false)),
            )
        )),
        filter((isNewVersionAvailable) => !!isNewVersionAvailable),
      )
      .subscribe(() => {
        this.notificationService.close()
          .then(() => {
            const notificationComponent = this.notificationService.open<NotificationNewVersionComponent>(NotificationType.NEW_APP_VERSION);

            notificationComponent.buttonClick
              .pipe(take(1))
              .subscribe(() => this.windowRefService.reload());
          });
      });
  }

  triggerNewVersionCheck(): void {
    this.triggerNewVersion$.next();
  }
}
