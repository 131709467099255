import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExchangeComponent, PublicComponent, NewPublicComponent} from './containers';
import {
  OverloadedComponent,
  UnsupportedCountryComponent
} from '../info-pages/containers';
import {AnimationDebugComponent} from '@pm/core/animation-debug/animation-debug.component';
import {CampaignErrorComponent} from '@pm/info-pages/containers/campaign-error/campaign-error.component';

export const routes: Routes = [
  {
    path: 'public',
    component: PublicComponent,
    children: [
      {
        path: '',
        // component: NewPublicComponent,
        redirectTo: 'register',
        pathMatch: 'full'
      },
      {
        path: 'campaign-error',
        component: CampaignErrorComponent
      },
      {
        path: 'overloaded',
        component: OverloadedComponent
      },
      {
        path: 'rate-limit',
        component: OverloadedComponent
      },
      {
        path: 'unsupported-country',
        component: UnsupportedCountryComponent
      }
    ]
  },
  {
    path: 'exchange',
    component: ExchangeComponent
  },
  {
    path: 'zz-animation-debug/:animationName',
    component: AnimationDebugComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PublicRoutingModule {
}
