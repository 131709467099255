import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { merge } from 'rxjs';
import { filter, map, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { Power3, TimelineLite } from 'gsap';

import { Answers, MessageType, QuestionMessageType, QuestionOption, RadioQuestionMessage } from '@pm/chat/models/message.model';
import { MessageMeta } from '@pm/chat/models/message-meta.model';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { BugsnagService } from '@pm/core/services';
import { DefaultLanguageCode, LanguageCode } from '@pm/localisation/models/localisation.model';

@Component({
  selector: 'pm-radio-question-message',
  templateUrl: './radio-question-message.component.html',
  styleUrls: ['./radio-question-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioQuestionMessageComponent extends AbstractComponent implements OnInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  @ViewChild('question', { static: true }) question: ElementRef;
  @ViewChild('answers', { static: true }) answers: ElementRef;

  @Input() message: RadioQuestionMessage;
  @Input() isError: boolean;
  @Input() isRightToLeft = false;

  @Output() answerChange = new EventEmitter<{ answers: Answers, meta: Partial<MessageMeta> }>();
  @Output() answerSubmit = new EventEmitter<string>();

  readonly form: FormGroup;
  openEndedValuesForm: FormGroup;

  private oldSelected = -1;
  private isIndecisive = false;
  private tl = new TimelineLite();

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly bugsnag: BugsnagService
  ) {
    super();
    this.form = this.createFrom();
  }

  get meta(): Partial<MessageMeta> {
    return {
      quick: this.isQuick,
      direct: this.isQuick,
      indecisive: this.isIndecisive
    };
  }

  get text(): string {
    return this.message.nameHtml;
  }

  get isQuick(): boolean {
    return this.message.isQuick;
  }

  get isBundleQuestion(): boolean {
    return this.message.bundleNameHtml.length > 0;
  }

  get questionOptions(): QuestionOption[] {
    return Array.from(this.message.options.values());
  }

  get questionStyle(): string {
    return this.isError ? 'pm-question-active-code-error' : 'pm-question-active-code-info';
  }

  markForCheck() {
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.openEndedValuesForm = this.createOpenEndedValuesForm(this.questionOptions);
    merge(this.openEndedValuesForm.valueChanges, this.form.get('options').valueChanges)
      .pipe(
        map(() => this.form.get('options').value),
        filter(() => this.form.valid && this.form.dirty),
        tap((answerId) => this.bugsnag.leaveBreadcrumb('RQMC::valueChanges::', {
          formValid: true,
          answerId,
          questionId: this.message.questionId
        })),
        tap((answerId) => this.checkIfIndecisive(answerId)),
        tap((answerId) => this.answerChange.emit({ answers: this.buildAnswer(answerId), meta: this.meta })),
        filter(() => this.isQuick),
        // If an option is quick it will disappear as soon as its answered.
        // Slower browsers might not hide it fast enough so users will click
        // quickly again thus triggering an error. So this is why we throttle a bit
        // Before accepting the next change event
        throttleTime(1000),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        // For quick answers, reset the form value, without triggering a change in valueChanges.
        // This is used so users can click again on the radio in case NEO connection was out when they clicked, so that can try again
        this.form.reset({ options: '' }, { emitEvent: false });

        this.answerSubmit.emit(this.message.questionId);

        this.cdr.markForCheck();

        // TODO: Re-add this if bugsnag shows errors
        // Once answer is sent, we must stop listening to changes,
        // otherwise we might submit same answer twice and trigger an error!
        // subscription.unsubscribe();

        this.bugsnag.leaveBreadcrumb('RQMC::answerSent');
      });
  }

  open(): Promise<void> {
    return new Promise((resolve) => {
      this.tl
        .to(this.question.nativeElement, .5, { opacity: 1, ease: Power3.easeOut })
        .eventCallback('onComplete', resolve.bind(this))
      ;
    });
  }

  close(): Promise<void> {
    return new Promise((resolve) => {
      this.answers.nativeElement.classList.add('d-none');

      this.tl
        .to(this.wrapper.nativeElement, .25, {opacity: 0, ease: Power3.easeOut})
        .eventCallback('onComplete', resolve.bind(this));
    });
  }

  inputIsActive(id: string): boolean {
    return this.form.get('options').value === id;
  }

  areEmptyAnswerOptions(): boolean {
    return Object.values(this.form.getRawValue())
      .some((id: string) => {
        const formControl = this.openEndedValuesForm.get(id);
        return this.isOpenEndedOption(id) ? formControl.value.length === 0 && formControl.touched : false;
      });
  }

  // ----------------------------------------------------------------------------------------

  private createFrom(): FormGroup {
    return this.fb.group({
      options: ''
    });
  }

  private createOpenEndedValuesForm(options: QuestionOption[]): FormGroup {
    return this.fb.group(
      options
        .filter((option) => option.openEnded)
        .reduce((config, option) => ({...config, [option.id]: ''}), {})
    );
  }

  private checkIfIndecisive(id: number) {
    if (this.isIndecisive) {
      return;
    }

    const isIndecisive = this.oldSelected !== -1 && this.oldSelected !== id;
    this.oldSelected = id;

    this.isIndecisive = isIndecisive;
  }

  private buildAnswer(id: string): Answers {
    return {
      questionId: this.message.questionId,
      questionType: <QuestionMessageType>this.message.type,
      answer: [ this.isOpenEndedOption(id) ? {id: id, text: this.openEndedValuesForm.get(id).value} : {id: id}],
      languageCode: this.getLanguageCode(id)
    };
  }

  private getLanguageCode(id: string): LanguageCode {
    return this.message.type === MessageType.LanguageQuestion ? this.message.options.get(id).code : DefaultLanguageCode;
  }

  private isOpenEndedOption(id: string) {
    return id.length > 0 ? this.questionOptions.find((option) => option.id === id).openEnded : false;
  }

  isNewImage(questionOption: QuestionOption): boolean {
    return questionOption?.image?.size ? true : false;
  }

  isImage(questionOption: QuestionOption) {
    return (this.isOldImage(questionOption) || this.isNewImage(questionOption)) ? true : false;
  }

  isOldImage(questionOption: QuestionOption): boolean {
    const htmlText = questionOption.nameHtml;
    const altText = htmlText.substring(htmlText.indexOf('alt="') + 5, htmlText.lastIndexOf('"'));
    return htmlText.indexOf('alt="') !== -1 ? true : false;
  }
}
