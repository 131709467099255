import { Injectable } from '@angular/core';

const IMG_PATH = '/assets/';

const IMG_PRELOAD = [
  'svg/polygon.svg',
  'svg/pollpass-logo.svg',
  'svg/lower-treshold-illy.svg',
  'svg/icn-close.svg',
  'svg/icn-profile.svg',

  // The ones below here are questionable
  'svg/construction-worker.svg',
  'svg/goat.svg',
  'svg/logo.svg',
  'svg/lost.svg',
  'svg/more.svg',
  'svg/overloaded.svg',
  'svg/plug.svg',
  // ----

  'img/emailreg-1.gif',

  'img/levels/level-1.png',
  'img/levels/level-10.png',
  'img/levels/level-20.png',
  'img/levels/level-30.png',
  'img/levels/level-40.png',
];

/*
 * Based on https://stackoverflow.com/questions/3646036/preloading-images-with-javascript
 */
@Injectable()
export class ImgPreloadService {

  init(): void {
    // IMG_PRELOAD.forEach((img) => this.preload(IMG_PATH + img));
  }

  private preload(url: string): void {
    try {
      const img = new Image();
      img.src = url;
      // img.onload = () => console.warn('ImgPreloadServiceService::preload::loaded', url);
      // console.warn('ImgPreloadServiceService::preload::', url);
    } catch (e) {
    }
  }
}
