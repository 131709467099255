import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Inject, Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { filter, take, tap } from 'rxjs/operators';

import { SusiAnimationComponent } from '@pm/shared/components';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { UserModel } from '@pm/core/models/user.model';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';

@Injectable()
export class SusiAnimationService {

  private listener: Subscription;
  private willSusiBeShown: boolean = null;
  private susiAnimation: Promise<any>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector,
    private readonly coreStateFacade: CoreStateFacade,
    private readonly router: Router,
  ) {
  }

  initListener() {
    // This listener will fire the SUSI animation anytime a user has visited chat for the first time after login/registration
    if (this.listener) {
      return;
    }

    this.listener = combineLatest([
      this.coreStateFacade.user$,
      this.router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd)
        ),
    ])
      .pipe(
        tap(([user]) => {
          if (user === null) {
            this.willSusiBeShown = true;
          } else {
            this.willSusiBeShown = this.willSusiBeShown === null ? false : this.willSusiBeShown;
          }
        }),
        filter(([_, routerEvent]: [UserModel, RouterEvent]) => routerEvent.url === '/' && this.willSusiBeShown === true),
      )
      .subscribe(() => {
        this.willSusiBeShown = false;
        void this.start();

        this.listener.unsubscribe();
        this.listener = null;
      });
  }

  start(): Promise<any> {
    if (!this.susiAnimation) {
      this.susiAnimation = new Promise<any>((resolve) => {
        const componentRef = this.componentFactoryResolver
          .resolveComponentFactory(SusiAnimationComponent)
          .create(this.injector);

        const removeSusi = this.prependComponentToBody(componentRef);

        this.coreStateFacade.setInitAnimationStarted();

        const subscription = componentRef.instance.complete
          .pipe(
            take(1)
          )
          .subscribe(() => {
            removeSusi();
            subscription.unsubscribe();
            componentRef.destroy();
            this.susiAnimation = null;

            this.coreStateFacade.setInitAnimationDone();

            resolve(undefined);
          });
      });
    }

    return this.susiAnimation;
  }

  private prependComponentToBody(componentRef: ComponentRef<any>): () => void {
    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // 4. Append DOM element to the body
    // this.document.body.appendChild(domElem);
    this.document.body.insertBefore(domElem, this.document.body.firstChild);

    // 5. Wait some time and remove it from the component tree and from the DOM
    return () => {
      this.appRef.detachView(componentRef.hostView);
    };
  }
}
