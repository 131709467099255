import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pm-question-message-item-image-container',
  templateUrl: './question-message-item-image-container.component.html',
  styleUrls: ['./question-message-item-image-container.component.scss']
})
export class QuestionMessageItemImageContainerComponent {
  @Input() htmlText: string;
  @Input() isNewImage: boolean;
  @Input() size: {height: string, width: string};
  constructor() { }
}
