import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@pm/shared/shared.module';

import {
  HoldingComponent,
  InfoPageComponent,
  CampaignErrorComponent,
  NoContentComponent,
  OverloadedComponent,
  UnsupportedCountryComponent,
} from './containers';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
  ],
  declarations: [
    InfoPageComponent,
    HoldingComponent,
    OverloadedComponent,
    UnsupportedCountryComponent,
    NoContentComponent,
    CampaignErrorComponent
  ],
  exports: [],
})
export class InfoPagesModule {
}
