import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvironmentService } from '@pm/core/services/environment.service';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  constructor(
    private env: EnvironmentService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isIgnoredRequestUrl(req.url)) {
      return next.handle(req);
    }

    const clonedReq = req.clone({
      url: `${this.env.api}/${req.url[0] === '/' ? req.url.substring(1) : req.url}`
    });

    return next.handle(clonedReq);
  }

  private isIgnoredRequestUrl(url: string): boolean {
    return url.startsWith('http') || url.endsWith('version.json');
  }
}
