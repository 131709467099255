<div class="row">
  <div class="col">
    <h2>Typography</h2>
    <br>
  </div>
</div>
<div class="row">
  <div class="col-md">
    <div class="row">
      <div class="col-6 mb-2">
        <h3>Headers</h3>
        <br>

        <h1>H1 Tag Header</h1>
        <h2>H2 Tag Header</h2>
        <h3>H3 Tag Header</h3>

        <hr>

        <h1 class="color-primary">Color Primary</h1>
        <h1 class="color-error">Error Color</h1>
        <h1 class="color-success">Success Color</h1>
        <h1 class="color-ink-1">Color Ink 1</h1>
        <h1 class="color-ink-2">Color Ink 2</h1>
        <h1 class="color-ink-3">Color Ink 3</h1>
      </div>

      <div class="col-6 mb-2">
        <h3>Paragraphs</h3>
        <br>

        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
          when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          It has survived not only five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </p>

        <hr>

        <p
          *ngFor="let colClass of [
          'color-primary',
          'color-error',
          'color-success',
          'color-ink-1',
          'color-ink-2',
          'color-ink-3'
          ]"
          [class]="colClass"
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
          when an unknown printer took a ...
          <br>
          <br>
        </p>
      </div>

      <div class="col-6 mb-2">
        <h3>Links</h3>
        <br>

        <a href="/">A regular link</a>
      </div>
    </div>
  </div>
</div>
