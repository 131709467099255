export interface LanguageTranslations {
  nameEnglish: string;
  code: LanguageCode;
  countries: Country[];
  isRightToLeft?: boolean;
  translations: {[key: string]: string};
}

export type LanguageCode = string;

export type DefaultLanguageCode = LanguageCode;

export const DefaultLanguageCode: DefaultLanguageCode = 'Default';

export type Country = string;

export type LanguagesConfigurations = Map<LanguageCode, LanguageConfiguration>;

export interface LanguageConfiguration {
  nameEnglish: string;
  countries: Country[];
  translations: {[key: string]: string};
}

export const LanguageData: LanguageTranslations[] = [
  {
    nameEnglish: 'Default',
    code: 'Default',
    countries: [],
    translations: {
      'submit': 'Submit'
    }
  },
  { nameEnglish: 'Arabic',
    code: 'AR',
    countries: ['Egypt', 'Israel', 'Morocco', 'Saudi Arabia', 'UAE'],
    translations: {'submit': 'إرسال'},
    isRightToLeft: true,
  },
  { nameEnglish: 'Flemish',
    code: 'BE-FL',
    countries: ['Belgium'],
    translations: {'submit': 'indienen'}
  },
  { nameEnglish: 'Belgian French',
    code: 'BE-FR',
    countries: ['Belgium'],
    translations: {'submit': 'Envoyer'}
  },
  { nameEnglish: 'Canadian French',
    code: 'CA-FR',
    countries: ['Canada'],
    translations: {
      'submit': 'Envoyer'
    }
  },
  { nameEnglish: 'Danish',
    code: 'DA',
    countries: ['Denmark'],
    translations: {
      'submit': 'indsend'
    }
  },
  { nameEnglish: 'German',
    code: 'DE',
    countries: ['Austria', 'Germany', 'Switzerland'],
    translations: {'submit': 'Absenden'}
  },
  { nameEnglish: 'English - US',
    code: 'EN',
    countries: ['Australia', 'Canada', 'Egypt', 'Ghana', 'Hong Kong', 'Ireland', 'Kenya', 'Malaysia', 'New Zealand',
      'Nigeria', 'Philippines', 'Saudi Arabia', 'Singapore', 'South Africa', 'UAE', 'UK', 'USA'],
    translations: {
      'submit': 'Submit'
    }
  },
  { nameEnglish: 'Latin American Spanish',
    code: 'ES',
    countries: ['Colombia', 'Mexico', 'USA'],
    translations: {'submit': 'Enviar'}
  },
  { nameEnglish: 'Spanish',
    code: 'ES-ES',
    countries: ['Spain'],
    translations: {'submit': 'Enviar'}
  },
  { nameEnglish: 'French',
    code: 'FR',
    countries: ['France', 'Morocco', 'Switzerland'],
    translations: {
      'submit': 'Envoyer'
    }
  },
  { nameEnglish: 'Hebrew',
    code: 'HE',
    countries: ['Israel'],
    translations: {'submit': 'הגש'},
    isRightToLeft: true,
  },
  { nameEnglish: 'Hindi',
    code: 'HI',
    countries: ['India'],
    translations: {'submit': 'सबमिट करें'}
  },
  { nameEnglish: 'Indonesian',
    code: 'IND',
    countries: ['Indonesia'],
    translations: {'submit': 'Kirim'}
  },
  { nameEnglish: 'Italian',
    code: 'IT',
    countries: ['Italy', 'Switzerland'],
    translations: {'submit': 'Invia'}
  },
  { nameEnglish: 'Japanese',
    code: 'JA',
    countries: ['Japan'],
    translations: {'submit': '送信'}
  },
  { nameEnglish: 'Korean',
    code: 'KO',
    countries: ['South Korea'],
    translations: {'submit': '제출'}
  },
  { nameEnglish: 'Malay',
    code: 'MLY',
    countries: ['Malaysia'],
    translations: {'submit': 'Hantar'}
  },
  { nameEnglish: 'Dutch',
    code: 'NL',
    countries: ['Netherlands'],
    translations: {'submit': 'Verzenden'}
  },
  { nameEnglish: 'Polish',
    code: 'PL',
    countries: ['Poland'],
    translations: {'submit': 'Wyślij'}
  },
  { nameEnglish: 'Portuguese',
    code: 'PT',
    countries: ['Portugal'],
    translations: {'submit': 'Enviar'}
  },
  { nameEnglish: 'Brazilian Portuguese',
    code: 'PT-BR',
    countries: ['Brazil'],
    translations: {'submit': 'Enviar'}
  },
  { nameEnglish: 'Romanian',
    code: 'ROU',
    countries: ['Romania'],
    translations: {'submit': 'Trimiteți'}
  },
  { nameEnglish: 'Russian',
    code: 'RU',
    countries: ['Russia'],
    translations: {'submit': 'Отправить'}
  },
  { nameEnglish: 'Swedish',
    code: 'SV',
    countries: ['Sweden'],
    translations: {'submit': 'Skicka'}
  },
  { nameEnglish: 'Tagalog',
    code: 'TG',
    countries: ['Philippines'],
    translations: {'submit': 'I-submit'}
  },
  { nameEnglish: 'Thai',
    code: 'TH',
    countries: ['Thailand'],
    translations: {'submit': 'ส่ง'}
  },
  { nameEnglish: 'Turkish',
    code: 'TR',
    countries: ['Turkey'],
    translations: {'submit': 'Gönder'}
  },
  { nameEnglish: 'Taiwanese Mandarin Chinese',
    code: 'TW',
    countries: ['Taiwan'],
    translations: {'submit': '提交'}
  },
  { nameEnglish: 'Vietnamese',
    code: 'VI',
    countries: ['Vietnam'],
    translations: {'submit': 'Gửi'}
  },
  { nameEnglish: 'Chinese (Simplified)',
    code: 'ZH-S',
    countries: ['China', 'Malaysia'],
    translations: {'submit': '提交'}
  },
  { nameEnglish: 'Chinese (Traditional)',
    code: 'ZH-T',
    countries: ['Hong Kong'],
    translations: {'submit': '遞交'}
  },
];
