import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MessageMeta} from '@pm/chat/models/message-meta.model';
import {
  Answers,
  isQuestionMessage,
  LanguageQuestionMessage,
  Message,
  MessageType,
  MultipleAnswerValue,
  OpenEndedQuestionMessage,
  RadioAnswerValue,
  RadioQuestionMessage
} from '@pm/chat/models/message.model';
import {ChatStateFacade} from '@pm/chat/ngrx/chat-facade.service';
import {ActiveMessage} from '@pm/chat/ngrx/chat.ngrx';
import {NeoService} from '@pm/chat/services';
import {LocalisationService} from '@pm/chat/services/localisation.service';
import {AbstractComponent} from '@pm/core/abstracts/abstract.component';
import {CoreStateFacade} from '@pm/core/ngrx/core-state-facade.service';
import {BehaviorSubject, combineLatest, Observable, of, timer} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'pm-chat',
  templateUrl: 'chat.component.html',
  styleUrls: ['chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input() skipWaitForInitAnimation: boolean;
  @ViewChild('answerInput', { static: true, read: ElementRef }) answerInputComponent: ElementRef;

  messages$: Observable<Message[]>;
  isConnectionReady$: Observable<boolean>;

  isLoading$ = new BehaviorSubject<boolean>(true);
  showInput = false;
  isRightToLeft = false;
  activeMessageId: string = null;
  activeMessageAnswers: Answers = null;

  constructor(
    private readonly neoService: NeoService,
    private readonly localisationService: LocalisationService,
    private readonly chatFacade: ChatStateFacade,
    private readonly coreFacade: CoreStateFacade,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  get submitName$(): Observable<string> {
    return this.chatFacade.language$.pipe(
      map((lang) =>
        this.localisationService.loadComponentByLanguage('submit', lang))
    );
  }

  ngOnInit() {
    this.initChat();

    this.chatFacade.activeMessage$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe((activeMessage) => {
        this.showInput = this.isSubmitButtonShown(activeMessage);
        this.activeMessageId = activeMessage && activeMessage.message && activeMessage.message.id || null;
        this.activeMessageAnswers = activeMessage && activeMessage.answers || null;

        this.cdr.markForCheck();
      });

    this.chatFacade.isRightToLeft$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((isRightToLeft) => this.isRightToLeft = isRightToLeft);

    this.messages$ = this.chatFacade.sortedMessages$;
    this.isConnectionReady$ = this.neoService.neoReady$;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.neoService.disconnect();
  }

  trackByFn(index: number, message: Message) {
    return message.id;
  }

  isOpenEndError(answers: Answers): boolean {
    if (answers && answers.answer) {
      switch (answers.questionType) {
        case MessageType.MultipleQuestion:
          return answers.answer.some((option: MultipleAnswerValue) => option.hasOwnProperty('text') ? option.text.length === 0 && option.selected : false);

        case MessageType.RadioQuestion:
          return answers.answer.some((option: RadioAnswerValue) => option.hasOwnProperty('text') ? option.text.length === 0 : false);

        default:
          return false;
      }
    }
  }

  sendAnswer(): void {
    this.isConnectionReady$
      .pipe(take(1))
      .subscribe((isConnectionReady) => {
        if (!isConnectionReady) {
          this.neoService.reconnect();
        }
        this.showInput = false;
        this.cdr.markForCheck();
        this.neoService.sendAnswer();
      });
  }

  answerChange(payload: { answers: Answers, meta: Partial<MessageMeta> }): void {
    // even though we get this value from `LanguageSwitched` event, we need to
    // set the value here as well, otherwise we see a jump when the layout switches to RTL
    if (payload.answers.questionType === MessageType.LanguageQuestion) {
      this.localisationService.setByLanguageCode(payload.answers.languageCode);
    }

    this.chatFacade.updateActiveMessageData(payload);
    this.cdr.markForCheck();
  }

  resetConversation(): void {
    this.isConnectionReady$
      .pipe(take(1))
      .subscribe((isConnectionReady) => {
        if (!isConnectionReady) {
          this.neoService.reconnect();
        }
        this.cdr.markForCheck();
        this.neoService.resetConversation();
      });
  }

  rollbackConversation(messageId: string): void {
    this.isConnectionReady$
      .pipe(take(1))
      .subscribe((isConnectionReady) => {
        if (!isConnectionReady) {
          this.neoService.reconnect();
        }
        this.cdr.markForCheck();
        this.neoService.rollbackConversation(messageId);
      });
  }


  // ------------------------------------------------------------------------------------

  private init(): void {
    this.neoService.reconnect();

    combineLatest([
      this.chatFacade.activeMessage$,
      this.chatFacade.processingHistory$,
    ])
      .pipe(
        switchMap(([activeMessage, isHistory]) => (
          this.showLoadingMessage(activeMessage && activeMessage.message || null)
            ? timer(this.waitingMessageDelay(activeMessage && activeMessage.message || null, isHistory)).pipe(map(() => true))
            : of(false)
        )),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe((val) => {
        this.isLoading$.next(val);
        this.cdr.markForCheck();
      });
  }

  private showLoadingMessage(message: Message): boolean {
    return message === null || (message.type !== MessageType.GoodBye && !isQuestionMessage(message));
  }

  private waitingMessageDelay(message: Message, isHistory: boolean): number {
    return (message === null || isHistory) ? 0 : 150;
  }

  private isSubmitButtonShown(activeMessage: ActiveMessage): boolean {
    if (!activeMessage) {
      return false;
    }

    const { message, answers } = activeMessage;

    if (answers) {
      switch (answers.questionType) {
        case MessageType.RadioQuestion:
          return !(message as RadioQuestionMessage).isQuick && answers.answer.length > 0 && !this.isOpenEndError(answers);

        case MessageType.MultipleQuestion:
          return answers.answer.some((item: MultipleAnswerValue) => item.selected) && !this.isOpenEndError(answers);

        case MessageType.LanguageQuestion:
          return !(message as LanguageQuestionMessage).isQuick && answers.answer.length > 0;

        case MessageType.OpenEndedQuestion:
          return (message as OpenEndedQuestionMessage).hasMultipleOptions && answers.answer.length > 0;

        default:
          throw new Error(`[Chat Component] Answers of type ${answers.questionType} is not defined!`);
      }
    }

    return false;
  }

  private initChat(): void {
    if (this.skipWaitForInitAnimation) {
      this.init();
      return;
    }

    this.coreFacade
      .initAnimationStarted$
      .pipe(
        // Init as soon as animation is finished
        filter((animationStarted) => !animationStarted),
        take(1),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.init());
  }
}
