import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { environment } from '@messenger-env/environment';

// ------------------------------------------------------------------------
// Actions
// ------------------------------------------------------------------------
export const SettingsActions = {
  setNeoSocket: createAction('[Settings] Set NEO Socket URL', props<{ neoSocketUrl: string, isTestingMode?: boolean }>()),
  setNeoSocketError: createAction('[Settings] Set NEO Socket Error', props<{ isSocketError: boolean }>()),
  setNeoSocketParams: createAction('[Settings] Set NEO Socket Params', props<{ params: NeoSocketParams }>()),
  setDash2Mode: createAction('[Settings] Set Dash2 Mode', props<{ isDash2Mode: boolean }>()),
  setAuthToken: createAction('[Settings] Set Auth Token', props<{ authToken: string }>())
};

// ------------------------------------------------------------------------
// Reducers
// ------------------------------------------------------------------------
export interface SettingsState {
  neoSocketUrl: string;
  neoSocketParams: NeoSocketParams;
  isPreviewMode: boolean;
  isTestingMode: boolean;
  isSocketError: boolean;
  isDash2Mode: boolean;
  authToken: string | null;
}

export interface NeoSocketParams {
  panel: string;
  country: string;
  language: string;
}

const initialState: SettingsState = {
  neoSocketUrl: environment.socket,
  neoSocketParams: {panel: 'Initial Panel', country: '', language: ''},
  isPreviewMode: false,
  isTestingMode: false,
  isSocketError: false,
  isDash2Mode: false,
  authToken: null,
};

const settingsReducer = createReducer(
  initialState,
  on(SettingsActions.setNeoSocket, (state, { neoSocketUrl, isTestingMode }) => ({
    ...state,
    neoSocketUrl,
    isTestingMode: !!isTestingMode
  })),
  on(SettingsActions.setNeoSocketError, (state, { isSocketError }) => ({
    ...state,
    isSocketError,
  })),
  on(SettingsActions.setNeoSocketParams, (state, { params }) => ({
    ...state,
    neoSocketParams: params
  })),
  on(SettingsActions.setDash2Mode, (state, { isDash2Mode }) => ({
    ...state,
    isDash2Mode: !!isDash2Mode,
  })),
  on(SettingsActions.setAuthToken, (state, { authToken }) => ({
    ...state,
    authToken: authToken
  })),
);

export function SettingsReducer(state: SettingsState | undefined, action: Action): SettingsState {
  return settingsReducer(state, action);
}

// ------------------------------------------------------------------------
// Selectors
// ------------------------------------------------------------------------
export const settingsState = createFeatureSelector<SettingsState>('settings');

export const SettingsSelectors = {
  SettingsState: settingsState,
  NeoSocketUrl: createSelector(settingsState, (state: SettingsState) => state.neoSocketUrl),
  NeoSocketParams: createSelector(settingsState, (state: SettingsState) => state.neoSocketParams),
  IsTestingMode: createSelector(settingsState, (state: SettingsState) => state.isTestingMode),
  IsDash2Mode: createSelector(settingsState, (state: SettingsState) => state.isDash2Mode),
  AuthToken: createSelector(settingsState, (state: SettingsState) => state.authToken),
};
