<div
  [ngClass]="{
    'right-to-left': isRightToLeft,
    'bubble': type === 'Answer' || type === 'Message',
    'answer': type === 'Answer',
    'question': type === 'Question',
    'message': type === 'Message',
    'webhook': type === 'Webhook',
    'goodbye': type === 'GoodBye',
    'screen-out': type === 'ScreenOut'
  }"
>
  <div *ngIf="isName" class="heading-3">{{name}}</div>
  <pm-html-text
    [htmlText]="text"
    class="bubble-text"
  ></pm-html-text>
</div>
<div *ngIf="showRollbackOption" (click)="triggerRollbackConversation()" class="rollback-icon">
  <fa-icon icon="play"></fa-icon>
</div>
