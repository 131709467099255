<div
  #wrapper
  class="pm-question-container"
  [class.right-to-left]="isRightToLeft"
>
  <pm-bundle-name *ngIf="isBundleQuestion" [nameHtml]="message.bundleNameHtml"></pm-bundle-name>
  <div
    #question
    class="pm-question"
  >
    <pm-html-text
      [htmlText]="text"
      [ngClass]="questionStyle"
    ></pm-html-text>
  </div>

  <div
    #answers
    class="pm-question-answers"
  >
    <form
      [formGroup]="form"
      autocomplete="off"
      novalidate
    >
      <section class="pm-question-options">
        <ng-container *ngFor="let option of questionOptions; let i=index">
          <pm-radio
            *ngIf="!option.openEnded"
            [idField]="option.id"
            formControlName="options"
            [value]="option.id"
            name="options"
            [class.quick]="isQuick"
            [class.right-to-left]="isRightToLeft"
          >
            <pm-question-message-item-image-container id="image-container" *ngIf="isImage(option)"[isNewImage]="isNewImage(option)" [size]="option.image?.size" [htmlText]="option.nameHtml"></pm-question-message-item-image-container>  
            <pm-html-text
              *ngIf="!isImage(option)"
              class="answerOption"
              [htmlText]="option.nameHtml"
            ></pm-html-text>
          </pm-radio>
          <div *ngIf="option.openEnded" class="open-ended-option">
            <pm-radio
              [idField]="option.id"
              formControlName="options"
              [value]="option.id"
              name="options"
              [class.quick]="isQuick"
              [class.right-to-left]="isRightToLeft"
            >
              <div [formGroup]="openEndedValuesForm" class="open-end-input-container">
                <span><pm-html-text [htmlText]="option.nameHtml"></pm-html-text></span>
                <div *ngIf="inputIsActive(option.id)">
                  <span>:</span>
                  <input *ngIf="inputIsActive(option.id)" type="text" [formControlName]="option.id" placeholder="Please specify" class="open-end-input" autofocus>
                </div>
              </div>
            </pm-radio>
          </div>
        </ng-container>
      </section>
    </form>
    <div *ngIf="areEmptyAnswerOptions()" class="open-end-error">You can’t save an empty answer option. Please specify the answer or uncheck the option.</div>
  </div>
</div>
