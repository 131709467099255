<div class="pm-chat">
  <div
    class="messages-container container"
    *ngIf="{
      messages: messages$ | async,
      isLoading: isLoading$ | async
    } as asy"
  >
    <!-- Positioning / CSS targeting fix -->
    <div class="pm-chat-message"></div>
    <div
      *ngIf="asy.isLoading && asy.messages && asy.messages.length < 1"
      class="pm-chat-message"
    >
    <pm-loading-bubble [isRightToLeft]="isRightToLeft"></pm-loading-bubble>
  </div>

    <pm-message
      *ngFor="let message of asy.messages; trackBy: trackByFn; let last = last;"
      [message]="message"
      [isRightToLeft]="isRightToLeft"
      (answerSubmit)="sendAnswer()"
      (answerChange)="answerChange($event)"
      (resetConversation)="resetConversation()"
      (rollbackConversation)="rollbackConversation($event)"
      [activeAnswers]="activeMessageAnswers"
      [isActive]="message.id === activeMessageId"
      [error$]="this.chatFacade.lastMessageError$"
      class="pm-chat-message"
    >
      <pm-loading-bubble
        *ngIf="last && asy.isLoading"
        [isRightToLeft]="isRightToLeft"
      ></pm-loading-bubble>
    </pm-message>
  </div>

  <pm-answer-input
    #answerInput
    [class.visible]="showInput"
    [class.right-to-left]="isRightToLeft"
    [isDisabled]="!showInput"
    [submitButtonName]="submitName$"
    (clicked)="sendAnswer()"
  ></pm-answer-input>
</div>
