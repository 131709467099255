import { Injectable, OnDestroy } from '@angular/core';
import { of, Subject, Subscription, timer } from 'rxjs';
import { filter, mapTo, switchMap } from 'rxjs/operators';

import { BugsnaggableError, BugsnagService } from '@pm/core/services/bugsnag.service';
import { MessageModel } from '@pm/chat/models/message.model';

const TIMEOUT_TTL_MS = 10000;

@Injectable()
export class ChatTimeTrackingService implements OnDestroy {
  private readonly counter: Subject<boolean>;
  private readonly sub: Subscription;

  constructor(
    bugsnag: BugsnagService,
  ) {
    this.counter = new Subject();

    this.sub = this.counter
      .pipe(
        switchMap((val) => (
            val === true
              ? timer(TIMEOUT_TTL_MS).pipe(mapTo(true))
              : of(null)
          )
        ),
        filter((val) => !!val),
      )
      .subscribe(() => bugsnag.reportError(new BugsnaggableError(
        `ChatTimeTrackingService:: Time exceeded ${TIMEOUT_TTL_MS / 1000}s`,
        { severity: 'info', groupingHash: 'ChatTimeTrackingServicew' }
        )
      ));
  }

  trackMessageSent(): void {
    this.counter.next(true);
  }

  trackMessageReceived(msg: MessageModel): void {
    if (msg.kind === 'Question' || msg.kind === 'GoodBye' || msg.kind === 'Statement') {
      this.counter.next(false);
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.counter.complete();
  }
}
