import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Answers, Message } from '@pm/chat/models/message.model';
import { MessageMeta } from '@pm/chat/models/message-meta.model';

import { ActiveMessage, ChatActions, ChatSelectors, ChatState } from '@pm/chat/ngrx/chat.ngrx';
import { LocalisationActions, LocalisationSelectors } from '@pm/core/ngrx/localisation.ngrx';
import { LanguageCode, LanguageTranslations } from '@pm/localisation/models/localisation.model';

@Injectable()
export class ChatStateFacade {
  constructor(
    private readonly store: Store<ChatState>
) {}

  // ----------- Selectors

  get sortedMessages$(): Observable<Message[]> {
    return this.store.select(ChatSelectors.messages)
      .pipe(
        map((messages) => Array.from(messages.values())),
        map((messages) => messages.sort((a, b) => (b.createdAt > a.createdAt) ? -1 : 1)),
      );
  }

  get activeMessage$(): Observable<ActiveMessage> {
    return this.store.select(ChatSelectors.activeMessage);
  }

  get processingHistory$(): Observable<boolean> {
    return this.store.select(ChatSelectors.processingHistory);
  }

  get lastMessageError$(): Observable<string | null> {
    return this.store.select(ChatSelectors.lastError);
  }

  get language$(): Observable<LanguageCode> {
    return this.store.select(LocalisationSelectors.Language);
  }

  get isRightToLeft$(): Observable<boolean> {
    return this.store.select(LocalisationSelectors.IsRightToLeft);
  }

  // ----------- Actions

  addMessage(message: Message): void {
    this.store.dispatch(ChatActions.addMessage({ message }));
  }

  setProcessingHistory(isProcessing: boolean): void {
    this.store.dispatch(ChatActions.setProcessingHistory({ isProcessing }));
  }

  clearMessenger(): void {
    this.store.dispatch(ChatActions.clear());
  }

  updateActiveMessage(activeMessage: ActiveMessage): void {
    this.store.dispatch(ChatActions.updateActiveMessage({ activeMessage }));
  }

  updateActiveMessageData(payload: { answers: Answers, meta: Partial<MessageMeta> }): void {
    this.store.dispatch(ChatActions.updateActiveMessageData(payload));
  }

  updateLastError(error: string): void {
    this.store.dispatch(ChatActions.updateLastError({ error }));
  }

  updateLanguage(language: LanguageTranslations) {
    this.store.dispatch(LocalisationActions.UpdateLanguage({
      language: language.code,
      isRightToLeft: language.isRightToLeft === true,
    }));
  }
}
