export class Utils {
  static range(from: number, to: number): number[] {
    return (new Array(to - from))
      .fill(0)
      .map((_, idx: number) => to - (to - from) + idx + 1);
  }

  static parseQueryParams(url: string): Map<string, any> {
    const parts = url.split('?');
    if (parts.length < 2) {
      return new Map<string, any>();
    }

    return parts[1].split('&').reduce((acc, curr) => {
      const param = curr.split('=');
      acc.set(param[0], param[1]);
      return acc;
    }, new Map<string, any>());
  }
}

// Based on https://github.com/pnegri/uuid-js
export class UUID {
  // static limitUI04 = Math.pow(2, 4);
  static limitUI06 = Math.pow(2, 6);
  static limitUI08 = Math.pow(2, 8);
  static limitUI12 = Math.pow(2, 12);
  static limitUI16 = Math.pow(2, 16);
  static limitUI32 = Math.pow(2, 32);

  // Returns a random integer between min and max
  // Using Math.round() will give you a non-uniform distribution!
  // @see https://developer.mozilla.org/en-US/docs/JavaScript/Reference/Global_Objects/Math/random
  static getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static randomUI06(): number {
    return UUID.getRandomInt(0, UUID.limitUI06 - 1);
  }

  static randomUI08(): number {
    return UUID.getRandomInt(0, UUID.limitUI08 - 1);
  }

  static randomUI12(): number {
    return UUID.getRandomInt(0, UUID.limitUI12 - 1);
  }

  static randomUI16(): number {
    return UUID.getRandomInt(0, UUID.limitUI16 - 1);
  }

  static randomUI32(): number {
    return UUID.getRandomInt(0, UUID.limitUI32 - 1);
  }

  static randomUI48(): number {
    return (0 | Math.random() * (1 << 30)) + (0 | Math.random() * (1 << 48 - 30)) * (1 << 30); // tslint:disable-line
  }

  static paddedString(str: string, length: number, z?: string): string {
    str = String(str);
    z = (!z) ? '0' : z;
    let i = length - str.length;
    for (; i > 0; i >>>= 1, z += z) { // tslint:disable-line
      if (i & 1) { // tslint:disable-line
        str = z + str;
      }
    }
    return str;
  }

  static fromParts(
    timeLow: number,
    timeMid: number,
    timeHiAndVersion: number,
    clockSeqHiAndReserved: number,
    clockSeqLow: number,
    node: number,
  ) {
    return UUID.paddedString(timeLow.toString(16), 8)
      + '-'
      + UUID.paddedString(timeMid.toString(16), 4)
      + '-'
      + UUID.paddedString(timeHiAndVersion.toString(16), 4)
      + '-'
      + UUID.paddedString(clockSeqHiAndReserved.toString(16), 2)
      + UUID.paddedString(clockSeqLow.toString(16), 2)
      + '-'
      + UUID.paddedString(node.toString(16), 12);
  }

  static create4(): string {
    return UUID.fromParts(
      UUID.randomUI32(),
      UUID.randomUI16(),
      0x4000 | UUID.randomUI12(), // tslint:disable-line
      0x80 | UUID.randomUI06(), // tslint:disable-line
      UUID.randomUI08(),
      UUID.randomUI48()
    );
  }
}
