import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';

import {
  NotificationChatErrorComponent,
  NotificationEmailregComponent,
  NotificationWrapperComponent,
  NotificationNewVersionComponent,
} from './components';

import { NotificationComponent, } from './containers';

import { NotificationService } from '@pm/notification/services/notification.service';
import { SharedModule } from '@pm/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    OverlayModule,
    RouterModule,
  ],
  declarations: [
    NotificationWrapperComponent,
    NotificationEmailregComponent,
    NotificationChatErrorComponent,
    NotificationNewVersionComponent,
    NotificationComponent
  ],
  providers: [
    NotificationService,
  ],
  exports: [
    NotificationComponent,
  ],
  entryComponents: [
    NotificationWrapperComponent,
  ]
})
export class NotificationModule {
}
