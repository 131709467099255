import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthGrantModel } from '@pm/core/models/auth-grant.model';
import { LevelModel, UserModel } from '@pm/core/models/user.model';
import { RootState } from '@pm/core/ngrx';
import { AuthActions, AuthSelectors } from '@pm/core/ngrx/auth.ngrx';
import { LayoutActions, LayoutSelectors } from '@pm/core/ngrx/layout.ngrx';
import { NeoSocketParams, SettingsActions, SettingsSelectors, SettingsState } from '@pm/core/ngrx/settings.ngrx';
import { UserActions, UserSelectors } from '@pm/core/ngrx/user.ngrx';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';


@Injectable()
export class CoreStateFacade {

  constructor(
    private readonly store: Store<RootState>
  ) {
  }

  // ------------------------------------------------
  // Authentication
  // ------------------------------------------------
  get authGrant$(): Observable<AuthGrantModel> {
    return this.store.select(AuthSelectors.AuthGrant);
  }

  get accessToken$(): Observable<string> {
    return this.store.select(AuthSelectors.AccessToken);
  }

  get refreshToken$(): Observable<string> {
    return this.store.select(AuthSelectors.RefreshToken);
  }

  get isAuthenticating$(): Observable<boolean> {
    return this.store.select(AuthSelectors.IsAuthenticating);
  }

  setAuthenticating(isAuthenticating: boolean): void {
    this.store.dispatch(AuthActions.Authenticating({ isAuthenticating }));
  }

  clearAuthGrant(): void {
    this.store.dispatch(AuthActions.ClearAuthGrant());
  }

  updateAuthGrant(authGrant: AuthGrantModel): void {
    this.store.dispatch(AuthActions.UpdateAuthGrant({ authGrant }));
  }

  waitingForRelevant(isWaiting: boolean): void {
    this.store.dispatch(AuthActions.WaitingForRelevant({ isWaiting }));
  }

  rateLimitActive(): void {
    this.store.dispatch(AuthActions.RateLimitActive());
  }

  addPhoneToVerify(parsedMobile: string): void {
    this.store.dispatch(AuthActions.AddPhoneToVerify({ parsedMobile }));
  }

  // ------------------------------------------------
  // User
  // ------------------------------------------------
  get user$(): Observable<UserModel | null> {
    return this.store.select(UserSelectors.User);
  }

  get userOnce$(): Observable<UserModel> {
    return this.user$.pipe(take(1));
  }

  get userOnceExisting$(): Observable<UserModel> {
    return this.userOnce$.pipe(filter((user: UserModel) => user !== null));
  }

  get existingUser$(): Observable<UserModel> {
    return this.user$.pipe(filter((user: UserModel) => user !== null));
  }

  userLoaded(user: UserModel): void {
    this.store.dispatch(UserActions.UserLoaded({ user }));
  }

  clearUser(): void {
    this.store.dispatch(UserActions.ClearUser());
  }

  updateLevel(level: LevelModel): void {
    this.store.dispatch(UserActions.UpdateLevel({ level }));
  }

  // ------------------------------------------------
  // Layout
  // ------------------------------------------------
  get firstScrollDone$(): Observable<boolean> {
    return this.store.select(LayoutSelectors.firstScrollDone);
  }

  get initAnimationStarted$(): Observable<boolean> {
    return this.store.select(LayoutSelectors.initAnimationStarted);
  }

  get showNavigationMenu$(): Observable<boolean> {
    return this.store.select(LayoutSelectors.showNavigationMenu);
  }

  toggleNav() {
    this.store.dispatch(LayoutActions.ToggleNavigation());
  }

  // openNav() {
  //   this.store.dispatch(LayoutActions.OpenNavigation());
  // }

  closeNav() {
    this.store.dispatch(LayoutActions.CloseNavigation());
  }

  setFirstScrollDone() {
    this.store.dispatch(LayoutActions.FirstScrollDone());
  }

  setInitAnimationStarted() {
    this.store.dispatch(LayoutActions.InitAnimationStarted());
  }

  setInitAnimationDone() {
    this.store.dispatch(LayoutActions.InitAnimationDone());
  }

  // ------------------------------------------------
  // Settings
  // ------------------------------------------------
  get settingsState$(): Observable<SettingsState> {
    return this.store.select(SettingsSelectors.SettingsState);
  }

  get neoSocketUrlOnce$(): Observable<string> {
    return this.store.select(SettingsSelectors.NeoSocketUrl).pipe(take(1));
  }

  get neoSocketParams$(): Observable<NeoSocketParams> {
    return this.store.select(SettingsSelectors.NeoSocketParams);
  }

  get isTestingMode$(): Observable<boolean> {
    return this.store.select(SettingsSelectors.IsTestingMode);
  }

  get isDash2Mode$(): Observable<boolean> {
    return this.store.select(SettingsSelectors.IsDash2Mode);
  }

  get authToken$(): Observable<string> {
    return this.store.select(SettingsSelectors.AuthToken);
  }

  setNeoSocketUrl(neoSocketUrl: string, isTestingMode: boolean = false): void {
    this.store.dispatch(SettingsActions.setNeoSocket({ neoSocketUrl, isTestingMode }));
  }

  setNeoSocketError(isSocketError: boolean): void {
    this.store.dispatch(SettingsActions.setNeoSocketError({ isSocketError }));
  }

  setNeoSocketParams(params: NeoSocketParams): void {
    this.store.dispatch(SettingsActions.setNeoSocketParams({params: {panel: params.panel, country: params.country, language: params.language}}));
  }

  setDash2Mode(isDash2Mode: boolean): void {
    this.store.dispatch(SettingsActions.setDash2Mode({isDash2Mode}));
  }

  setAuthToken(authToken: string): void {
    this.store.dispatch(SettingsActions.setAuthToken({authToken}));
  }
}
