import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChatStateFacade } from '@pm/chat/ngrx/chat-facade.service';
import { ConnectionStateFacade } from '@pm/chat/ngrx/connection-facade.service';
import { NeoService } from '@pm/chat/services';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { RoutingService, WindowRefService } from '@pm/core/services';
import { ConnectionStatusType } from '@pm/lib/rx-websocket-subject';
import { NotificationChatErrorComponent, NotificationEmailregComponent } from '@pm/notification/components';
import { NotificationService } from '@pm/notification/services/notification.service';
import { NotificationType } from '@pm/notification/types';
import { combineLatest, from, Observable, of, timer } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

enum ConnectedType {
  DISCONNECTED = 'disconnected', // Means that NEO connection has not tried reconnecting (TBD)
  OFFLINE = 'offline', // Means that NEO connection is not reconnecting (TBD)
  INACTIVE = 'inactive', // Means that NEO connection has timed out
  OPEN = 'open', // Means that NEO connection is Up & Running
  NOT_REQUIRED = 'not-required', // Means that NEO connection is not required
}

@Component({
  selector: 'pm-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent extends AbstractComponent implements OnInit, OnDestroy {
  // Following streams are public due to testing
  disconnected$: Observable<ConnectedType>;
  redirectedFromEmail$: Observable<boolean>;
  currentUrl$: Observable<string>;
  isNeoRoute$: Observable<boolean>;

  constructor(
    private readonly neoService: NeoService,
    private readonly connectionFacade: ConnectionStateFacade,
    private readonly coreStateFacade: CoreStateFacade,
    private readonly chatStateFacade: ChatStateFacade,
    private readonly router: Router,
    private readonly routingService: RoutingService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly windowRefService: WindowRefService,
    private readonly mns: NotificationService,
  ) {
    super();

    this.currentUrl$ = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((evt: NavigationEnd) => evt.url),
        map((url) => url.split('?')[0]),
        startWith(router.url.split('?')[0]),
      );

    this.isNeoRoute$ = this.currentUrl$.pipe(map((url) => url === '/' || url.startsWith('/m/') || url.startsWith('/c/')));

    this.redirectedFromEmail$ = this.getEmailRedirect$();
    this.disconnected$ = this.getDisconnected$();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    void this.mns.close();
  }

  ngOnInit(): void {
    const redirectedFromEmail$ = this.getEmailRedirect$();
    const disconnected$ = this.getDisconnected$();

    combineLatest([disconnected$, redirectedFromEmail$])
      .pipe(
        distinctUntilChanged((prev, current) => (prev.every((v, i) => v === current[i]))),
        // Make sure this is run in separate lifecycle hook as Angular triggers
        // an ExpressionChangedAfterItHasBeenCheckedError error otherwise

        delay(0),
        map(([disconnected, isEmailRedirect]) => {
          if (!(disconnected === ConnectedType.OPEN || disconnected === ConnectedType.NOT_REQUIRED)) {
            return [NotificationType.DISCONNECTED];
          } else if (isEmailRedirect) {
            return [NotificationType.EMAIL_REG];
          }

          return [null];
        }),
        switchMap(([notification, message]) => (
          // If currently open notification is the one we want, ignore
          notification === this.mns.openComponentType
            ? of([null])
            // Always close a modal that might be open (the below function is idempotent)
            : from(this.mns.close())
              .pipe(map(() => [notification, message]))
        )),
        filter(([notification]) => notification !== null),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(this.openNotification.bind(this));
  }

  private getEmailRedirect$(): Observable<boolean> {
    return combineLatest([
      this.activatedRoute.queryParams
        .pipe(
          map((params) => params.hasOwnProperty('sent_from') && params['sent_from'] === 'wallet')
        ),
      this.currentUrl$
        .pipe(
          map((url) => url === '/')
        ),
    ])
      .pipe(
        map(([isFromRedirect, isNeo]) => isFromRedirect && isNeo)
      );
  }

  private getDisconnected$(): Observable<ConnectedType> {
    // This will emit a ConnectionType enum to determine whether or not to show the notification for disconnection
    return this.isNeoRoute$
      .pipe(
        switchMap((isNeo) => (isNeo
            ? this.connectionFacade.connectionStatus$
              .pipe(
                delay(0),
                switchMap((status) => (
                    status === ConnectionStatusType.CLOSED
                      ? (
                        this.connectionFacade.connectionReconnecting$
                          .pipe(
                            // The delay() call here is important because the connectionReconnecting$ stream will
                            // emit BEFORE the router change event. So we need to run this in an async call
                            delay(0),
                            map(
                              (reconnecting) => (reconnecting.count === 0
                                  ? ConnectedType.OFFLINE
                                  : ConnectedType.DISCONNECTED
                              )
                            ),
                          )
                      )
                      : (
                        status === ConnectionStatusType.WAITING || status === ConnectionStatusType.CONNECTING
                          // If status is waiting, give it 1s before emitting inactive state
                          ? timer(3000)
                            .pipe(
                              map(() => ConnectedType.INACTIVE),
                            )
                          : of(ConnectedType.OPEN)
                      )
                  )
                ),
              )
            : of(ConnectedType.NOT_REQUIRED)
          )
        ),
        distinctUntilChanged(),
      );
  }

  private openNotification([notification, message]: [NotificationType, string]): void {
    switch (notification) {
      case NotificationType.DISCONNECTED:
        // console.log('Messenger Disconnected');
        break;
      case NotificationType.EMAIL_REG:
        this.openEmailReg();
        break;
      default:
        throw new Error(`Invalid notification type [${notification}]`);
    }
  }

  private openEmailReg(): void {
    const notificationComponent = this.mns.open<NotificationEmailregComponent>(NotificationType.EMAIL_REG);

    notificationComponent.buttonClick
      .pipe(
        // no need for takeUntil(this.componentDestroy$) as buttonClick will be completed once component is destroyed
      )
      .subscribe(() => this.routingService.gotoChat());
  }
}
