export interface IBrowserStorage {
  setItem(key: string, val: any): void;

  removeItem(key: string): void;

  getItem(key: string): any;

  clear(): void;
}

export class MemoryStorage implements IBrowserStorage {
  data: object = {};

  setItem(key: string, val: any) {
    this.data[key] = val;
  }

  removeItem(key: string): void {
    delete this.data[key];
  }

  getItem(key: string): any {
    return this.data[key];
  }

  clear(): void {
    this.data = {};
  }
}
