<pm-message-bubble
  [name]="name"
  [text]="text"
  [isTesting]="isTesting"
  [isRightToLeft]="isRightToLeft"
  [type]="bubbleType"
  (rollbackConversation)="triggerRollbackConversation()"
></pm-message-bubble>

<div *ngIf="isGoodbyeMessage" class="goodbye-options-container">
    <div class="survey-ended">Survey ended</div>
    <div (click)="triggerResetConversation()" class="restart-survey">Restart survey</div>
</div>
