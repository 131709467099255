<div class="container pb-md-0 pb-1rem">
  <div class="row">
    <div class="col-md-3 d-flex">
      <div class="m-auto text-center py-1rem">
        <img
          src="../../../assets/img/gift.png"
          alt="email"
          class="img-fluid pm-notification__img"
          style="max-width: 65px"
        />
      </div>
    </div>

    <div class="col-md-6 d-flex m-auto">
      <div class="py-2rem">
        <h2 class="mt-1rem m-md-0 mb-0">Update available</h2>
        <p class="mt-_5rem mb-1rem mb-md-0">A new version of Pollpass is ready. Please click on the button update to the latest version.</p>
      </div>
    </div>

    <div class="col-md-3 m-auto d-flex">
      <button
        pm-button
        class="btn-primary outline block"
        (click)="clickButton()"
        [isLoading]="buttonLoading$ | async"
      >
        Update now
      </button>
    </div>
  </div>
</div>
