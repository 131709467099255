import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, merge, Observable, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap, map, takeUntil, tap } from 'rxjs/operators';

import { AdTrackingService, AffiliateService, RegistrationTrackingService, RoutingService } from '@pm/core/services';
import { IExchangeAffiliate, IExchangeError, IExchangeParams } from '@pm/core/models/exchange.model';
import { ErrorType } from '@pm/core/models/error.model';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';

export enum RedirectTypes {
  AFFILIATE = 'affiliate',
  AD_TRACKING = 'adtracking',
  ERROR = 'error'
}

@Component({
  selector: 'pm-exchange',
  styleUrls: ['./exchange.component.scss'],
  templateUrl: './exchange.component.html'
})
export class ExchangeComponent extends AbstractComponent implements OnInit {
  exchange: IExchangeParams;

  error = new BehaviorSubject<string>('');

  constructor(
    private readonly routingService: RoutingService,
    private readonly route: ActivatedRoute,
    private readonly affiliateService: AffiliateService,
    private readonly adTrackingService: AdTrackingService,
    private readonly registrationTrackingService: RegistrationTrackingService
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        mergeMap((params: Params) => {

          if (!params.redirect_type) {
            return throwError(`Unsupported redirect, redirect_type is missing!`);
          }

          switch (params.redirect_type) {
            case RedirectTypes.AFFILIATE:
              return this.affiliateRedirect(params);
            case RedirectTypes.AD_TRACKING:
              return this.adTrackingRedirect(params);
            case RedirectTypes.ERROR:
              return this.errorRedirect(params);
            default:
              return throwError(`Case for ${params.redirect_type} is not defined!`);
          }
        }),
        catchError((e) => {
          this.error.next(e);
          return throwError(e);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  // REDIRECTS

  private affiliateRedirect(queryParams: Params): Observable<any> {
    return of(queryParams)
      .pipe(
        map((params: Params) => params as IExchangeAffiliate),
        tap((data) => this.affiliateService.storeSession(data)),
        tap((data) => this.registrationTrackingService.storeRegistrationSource(data.source)),
        tap(() => this.routingService.gotoRegister()),
      );
  }

  private adTrackingRedirect(queryParams: Params): Observable<any> {
    return of(queryParams)
      .pipe(
        map((data) => data['source'] || ''),
        tap((trackingSource) => this.adTrackingService.storeAdSource(trackingSource)),
        tap((trackingSource) => this.registrationTrackingService.storeRegistrationSource(trackingSource)),
        tap(() => this.adTrackingService.pushAdSource()),
        tap(() => this.routingService.gotoRegister()),
      );
  }

  private errorRedirect(queryParams: Params): Observable<any> {
    const params$ = of(queryParams);

    const supported$ = params$
      .pipe(
        filter((params: Params) => params.hasOwnProperty('error_kind')),
        map((params: Params) => params as IExchangeError),
        tap((params: IExchangeError) => {
          switch (params.error_kind) {
            case ErrorType.AuthorizationError_UsersOverload:
              this.routingService.gotoOverloaded();
              break;

            case ErrorType.AuthorizationError_DeactivatedAccount:
              this.routingService.gotoAccountDeactivated();
              break;

            case ErrorType.AuthorizationError_SecurityTagged:
              this.routingService.gotoInReview();
              break;

            default:
              this.error.next('Something went wrong!');
          }
        })
      );

    const unsupported$ = params$
      .pipe(
        filter((params: Params) => !params.hasOwnProperty('error_kind')),
        tap(() => this.error.next('Something went wrong!')),
      );

    return merge(supported$, unsupported$);
  }
}
