
// ------------- AUTO-GENERATED WITH '/src/environments/set-env.ts' -------------
import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  enableProdMode: true,
  appVersion: 'cde98a3',
  isSiCountryEnabled: true,
  isEmailRegistrationEnabled: true,
  isStoreDevToolsEnabled: true,
  isStoreLogEnabled: false,
  isDebugEnabled: true,
  isTesting: false,
  refreshAccessTokenIntervalSec: 3600,
  api: 'https://surveys-staging.pollpass.com',
  socket: 'wss://neo-staging.pollpass.com',
  authClientId: 'ef377c1e5d97ec7ed34337f6ca5fe76175cd69bd6efb0821823a4666b17f5',
  authClientSecret: '5dbcbfcb1cd277591e6aec6d29c44f7c1972afe9b356c58dcfec1fe8ec18719bf',
  bugsnagKey: '1dc103023788ce5cfe226ec50451d717',
  bugsnagStage: 'staging',
  segmentKey: 'DWwxokmJwIUvzdM0IOqFi3AokL1MGPgV',
  VWOAccountId: 'test',
  eKey: 'ec6d29c44f7c19172afe9b356c58dcfec',
  hotjarKey: '2738532',
};
