import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AbstractNotificationComponent } from '@pm/notification/components/abstract-notification.component';

@Component({
  selector: 'pm-notification-new-version',
  templateUrl: './notification-new-version.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationNewVersionComponent extends AbstractNotificationComponent {
}
