import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pm-ks-button',
  templateUrl: './ks-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KsButtonComponent {
  isSocialLoading: boolean;
}
