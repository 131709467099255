import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, fromEvent, merge, Observable, Subject } from 'rxjs';
import { filter, finalize, map, take, takeUntil, tap } from 'rxjs/operators';

import { ConnectionStatusType, IConnectionStatus, IReconnectionStatus, RxWebSocketSubject } from '@pm/lib/rx-websocket-subject';
import { BugsnagService, RoutingService, WindowRefService } from '@pm/core/services';
import { ChatStateFacade } from '@pm/chat/ngrx/chat-facade.service';
import { AnswerMessageService } from '@pm/chat/services/answer-message.service';
import { MessageModel, ResetConversationMessage, RollbackConversationMessage } from '@pm/chat/models/message.model';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { NeoMessageHandlerService } from '@pm/chat/services/neo-message-handler.service';
import { ConnectionStateFacade } from '@pm/chat/ngrx/connection-facade.service';
import { ChatTimeTrackingService } from '@pm/chat/services/chat-time-tracking.service';
import { UUID } from '@pm/utils';
import { NeoSocketParams } from '@pm/core/ngrx/settings.ngrx';

@Injectable()
export class NeoService {
  readonly _window: any;
  readonly neoInitialized$: Observable<boolean>;
  readonly neoReady$: Observable<boolean>;

  private readonly ngUnsubscribe$ = new Subject<void>();
  private ws$: RxWebSocketSubject<any>;

  constructor(
    private readonly coreFacade: CoreStateFacade,
    private readonly chatFacade: ChatStateFacade,
    private readonly connectionFacade: ConnectionStateFacade,
    private readonly answerMessageService: AnswerMessageService,
    private readonly messageHandler: NeoMessageHandlerService,
    private readonly bugsnag: BugsnagService,
    private readonly chatTimeTrackingService: ChatTimeTrackingService,
    private readonly routingService: RoutingService,
    readonly windowRef: WindowRefService,
  ) {
    this._window = windowRef.nativeWindow;

    this.neoInitialized$ = connectionFacade.connectionStatus$
      .pipe(
        map((status: ConnectionStatusType) => status === ConnectionStatusType.OPEN)
      );

    this.neoReady$ = combineLatest([this.neoInitialized$, chatFacade.processingHistory$])
      .pipe(
        map(([isConnected, processingHistory]) => isConnected && !processingHistory),
      );
  }

  reconnect(): void {
    this.disconnect();
    this.initNewWebsocketConnection();
  }

  disconnect(): void {
    if (this.ws$) {
      this.ws$.disconnect();
      this.ngUnsubscribe$.next();
      this.ws$ = null;
    }
  }

  sendAnswer(): void {
    if (!this.ws$) {
      throw new Error('Neoservice::sendAnswer:: Missing websocket connection to send answer!');
    }

    this.answerMessageService
      .generate$()
      .subscribe(([answerNeoMessage, _]) => {
        this.bugsnag.leaveBreadcrumb('NS::sendAnswer::', { question_id: answerNeoMessage.question_id });
        this.ws$.send(answerNeoMessage);
        this.chatTimeTrackingService.trackMessageSent();
      });
  }

  resetConversation(): void {
    this.bugsnag.leaveBreadcrumb('NS::resetConversation::');
    this.chatFacade.clearMessenger();
    this.ws$.send(this.generateResetConversationMessage);
  }

  get generateResetConversationMessage(): ResetConversationMessage {
    return {
      id: UUID.create4(),
      created_at: (new Date()).toISOString(),
      kind: 'ResetConversation'
    };
  }

  rollbackConversation(messageId: string): void {
    this.bugsnag.leaveBreadcrumb('NS::rollbackConversation::');
    this.chatFacade.clearMessenger();
    this.ws$.send(this.generateRollbackConversationMessage(messageId));
  }

  generateRollbackConversationMessage(messageId: string): RollbackConversationMessage {
    return {
      id: UUID.create4(),
      created_at: (new Date()).toISOString(),
      kind: 'RollbackConversation',
      message_id: messageId
    };
  }

  reloadMessenger(): void {
    this.chatFacade.clearMessenger();
    this.reconnect();
  }

  // -------------------------------------------------------------------------------------------

  private initNewWebsocketConnection(): void {
    combineLatest([
      this.coreFacade.neoSocketUrlOnce$,
      this.coreFacade.userOnce$,
      this.coreFacade.isTestingMode$
    ]).pipe(
      map(([socketUrl, user, isTesting]) => {
        if (!socketUrl) {
          console.warn('SOCKET env parameter was not provided');
          socketUrl = 'wss://neo-testing.pollpass.com';
        }

        if (isTesting) {
          return new URL(socketUrl);
        }

        let fullURL: URL = new URL(`${socketUrl}/r/${user.id}/${user.linkType}/${user.campaignCode}`);

        if (user.panelCode) {
          fullURL = new URL(`${fullURL.href}/p/${user.panelCode}`);
        }

        if (user.meta) {
          const urlParams = new URLSearchParams(Object.entries(user.meta)).toString();
          fullURL = new URL(`${fullURL.href}?${urlParams}`);
        }
        return fullURL;
      })
    )
      .subscribe((url) => {
        this.ws$ = new RxWebSocketSubject(url.href, this._window);
        this.initConnectionListeners();
        this.initActiveMessageListener();
      });
  }

  private initActiveMessageListener(): void {
    // Anytime a new message is added to the list, update the activeMessage
    combineLatest([
      this.chatFacade.sortedMessages$
        .pipe(
          map((messages) => messages[messages.length - 1])
        ),
      this.chatFacade.activeMessage$,
      this.chatFacade.processingHistory$,
    ])
      .pipe(
        filter(([latestMessage, activeMessage, pHistory]) => latestMessage && !pHistory && (!activeMessage || latestMessage.id !== activeMessage.message.id)),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(([message]) => this.chatFacade.updateActiveMessage({ message, meta: { shownAt: Date.now() } }));
  }

  private initConnectionListeners(): void {
    merge(
      this.coreFacade.authToken$.pipe(
        take(1),
        tap((authToken) => this.ws$.connect(authToken))
      ),
      (this._window.addEventListener ? fromEvent(this._window, 'offline') : EMPTY)
        .pipe(
          tap(() => this.reconnect())
        ),
      this.ws$.statusObservable
        .pipe(
          tap((status: IConnectionStatus) => this.connectionFacade.updateStatus(status.status)),
          tap((status: IConnectionStatus) => {
            this.coreFacade.setNeoSocketError(status.closeCode === 1006 && status.status === ConnectionStatusType.CLOSED);
          }),
        ),
      this.ws$.reconnectingObservable
        .pipe(
          tap((status: IReconnectionStatus) => {
            if (status.count > 3) {
              this.ws$.disconnect();
              this.routingService.gotoCampaignError();
            } else {
              this.connectionFacade.setReconnecting(status);
            }
          })
        ),
      this.ws$
        .pipe(
          tap((msg: MessageModel) => this.chatTimeTrackingService.trackMessageReceived(msg)),
          tap((msg: MessageModel) => {
            try {
              this.messageHandler.handle(msg);
            } catch (e) {
              // if (e.message && e.message.startsWith('NEO Error')) {
              //   return this.reloadMessenger();
              // }

              throw e;
            }
          }),
          finalize(() => this.disconnect()),
        )
    )
      .pipe(
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  // SURVEY TESTING

  startSurveyTesting(params: NeoSocketParams, surveyId: string, previewSocketURI: string, authToken: string, isDash2Mode: boolean = false): void {
    const baseURL = previewSocketURI + `/${surveyId}/testing?Q_Language=${params.language}&Country=${params.country}&panelname=${params.panel}`;
    this.clearPreviousConnection();
    this.coreFacade.setDash2Mode(isDash2Mode);
    this.coreFacade.setNeoSocketUrl(baseURL, true);
    this.coreFacade.setNeoSocketParams({panel: params.panel, language: params.language, country: params.country});
    this.coreFacade.setAuthToken(authToken);
  }

  clearPreviousConnection(): void {
    this.disconnect();
    this.chatFacade.clearMessenger();
  }
}
