<div
  #wrapper
  class="pm-question-container"
  [class.right-to-left]="isRightToLeft"
>
  <pm-bundle-name *ngIf="isBundleQuestion" [nameHtml]="message.bundleNameHtml"></pm-bundle-name>
  <div
    #question
    class="pm-question"
  >
    <pm-html-text
      [htmlText]="text"
      [ngClass]="questionStyle"
    ></pm-html-text>
  </div>

  <div
    #answers
    class="pm-question-answers"
  >
    <form
      [formGroup]="form"
      novalidate
    >
      <section class="pm-question-options">
        <ng-container *ngFor="let option of questionOptions; let i=index">
          <pm-checkbox
            *ngIf="!option.openEnded"
            [idField]="option.id"
            [formControlName]="i"
            [class.quick]="isQuick"
            [class.right-to-left]="isRightToLeft"
            class="quick"
          >
            <pm-question-message-item-image-container id="image-container" *ngIf="isImage(option)"[isNewImage]="isNewImage(option)" [size]="this.option?.image?.size" [htmlText]="option.nameHtml"></pm-question-message-item-image-container>
            <pm-html-text *ngIf="!isImage(option)" [htmlText]="option.nameHtml"></pm-html-text>
          </pm-checkbox>
          <div *ngIf="option.openEnded" class="open-ended-option">
            <pm-checkbox [idField]="option.id"
                         [formControlName]="i"
                         [class.quick]="isQuick"
                         [class.right-to-left]="isRightToLeft">
              <form [formGroup]="openEndedValuesForm" class="open-end-input-container">
                <span><pm-html-text [htmlText]="option.nameHtml"></pm-html-text></span>
                <div *ngIf="inputIsActive(i)"class="open-end-input-container">
                  <span>:</span>
                  <input *ngIf="inputIsActive(i)" type="text" [formControlName]="i" placeholder="Please specify" class="open-end-input" autofocus>
                </div>
              </form>
            </pm-checkbox>
          </div>
        </ng-container>
      </section>
    </form>
    <div *ngIf="areEmptyAnswerOptions()" class="open-end-error">You can’t save an empty answer option. Please specify the answer or uncheck the option.</div>
  </div>
</div>
