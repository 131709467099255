import { Injectable } from '@angular/core';
import { Country, DefaultLanguageCode, LanguageCode, LanguageData, LanguageTranslations } from '@pm/localisation/models/localisation.model';
import { ChatStateFacade } from '../ngrx/chat-facade.service';

@Injectable()
export class LocalisationService {
  constructor(private readonly chatFacade: ChatStateFacade) { }

  setByLanguageCode(languageCode: LanguageCode) {
    const language = this.findLanguageByCode(languageCode) || this.findLanguageByCode(DefaultLanguageCode);

    if (language) {
      this.chatFacade.updateLanguage(language);
    }
  }

  loadComponentByLanguage(uiComponent: string, language: LanguageCode = 'EN'): string {
    return this.findLanguageByCode(language)
      .translations[uiComponent] || '';
  }

  allCountries(): Country[] {
    return [...new Set(<Country>[].concat.apply([], (LanguageData.map((lang) => lang.countries))))].sort();
  }

  /**
   * This method can return undefined, but due to `strictNullChecks` not being enabled in our tsconfig,
   * TypeScript ignores the `undefined` part and only sees the `LanguageTranslations` as being the return type.
   * This can lead to unexpected errors like `Could not read property foo of undefined`.
   * Enabling null checks would require more works as we would need to fix a lot of cases where this is misused.
   *
   * Doc: https://www.typescriptlang.org/tsconfig#strictNullChecks
   */
  private findLanguageByCode(languageCode: LanguageCode): LanguageTranslations | undefined {
    return LanguageData.find((lang) => lang.code === languageCode);
  }
}
