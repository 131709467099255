import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { CampaignService } from '@pm/campaign/services/campaign.service';
import { AbstractComponent } from '@pm/core/abstracts/abstract.component';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { RoutingService } from '@pm/core/services';

interface RouteParams {
  linkType: string;
  campaignCode: string;
  panelCode: string;
}

@Component({
  selector: 'pm-campaign-container',
  templateUrl: './campaign-container.component.html',
  styleUrls: ['./campaign-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignContainerComponent extends AbstractComponent implements OnInit {
  isUserLoaded = false;
  isTimeoutError = false;
  reload$: Subject<void>;
  isReconnectLoading$: Observable<boolean>;

  constructor(
    private cdr: ChangeDetectorRef,
    private coreStateFacade: CoreStateFacade,
    private routingService: RoutingService,
    private activatedRoute: ActivatedRoute,
    private campaignService: CampaignService,
  ) {
    super();

    this.reload$ = new Subject();
  }

  ngOnInit(): void {
    combineLatest([
      this.getRouteParams$(),
      this.getUrlMeta$()
    ]).subscribe({
      next: ([{ linkType, panelCode, campaignCode }, meta]) => {
        this.campaignService.registerUser(linkType, campaignCode, panelCode, meta);
        this.isUserLoaded = true;
        this.cdr.markForCheck();
      },
    });

    this.isReconnectLoading$ = this.reload$
      .pipe(
        switchMap(() => timer(0, 5000).pipe(take(2))),
        map(v => v < 1),
        // takeUntil(this.ngUnsubscribe$),
      );
  }

  reconnect(): void {
    this.reload$.next();
  }

  private getRouteParams$(): Observable<RouteParams> {
    return this.activatedRoute.params
      .pipe(
        map(({ linkType, panelCode, campaignCode }) => ({ linkType, panelCode, campaignCode })),
        take(1)
      );
  }

  private getUrlMeta$(): Observable<object> {
    return this.activatedRoute.queryParams
      .pipe(
        take(1)
      );
  }

}
