import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AuthGrantModel } from '@pm/core/models/auth-grant.model';
import { RoutingService } from '@pm/core/services/routing.service';

// ------------------------------------------------------------------------
// Actions
// ------------------------------------------------------------------------
export const AuthActions = {
  Authenticating: createAction('[Auth] Authenticating', props<{ isAuthenticating: boolean }>()),
  UpdateAuthGrant: createAction('[Auth] Update Auth Grant', props<{ authGrant: AuthGrantModel }>()),
  ClearAuthGrant: createAction('[Auth] Clear Auth Grant'),
  RateLimitActive: createAction('[Auth] Rate Limit Active'),
  WaitingForRelevant: createAction('[Auth] Waiting for Relevant', props<{ isWaiting: boolean }>()),
  AddPhoneToVerify: createAction('[Auth] Add Phone to Verify', props<{ parsedMobile: string }>()),
  ClearPhoneToVerify: createAction('[Auth] Clear Phone to Verify'),
};

// ------------------------------------------------------------------------
// Reducers
// ------------------------------------------------------------------------
export interface AuthState {
  isAuthenticating: boolean;
  isRefreshing: boolean;
  authGrant: AuthGrantModel | null;
  waitingForRelevant: boolean;
  phoneToVerify: string;
}

const initialState: AuthState = {
  isAuthenticating: false,
  isRefreshing: false,
  authGrant: null,
  waitingForRelevant: false,
  phoneToVerify: ''
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.Authenticating, (state, { isAuthenticating }) => ({
    ...state,
    isAuthenticating
  })),
  on(AuthActions.UpdateAuthGrant, (state, { authGrant }) => ({
    ...state,
    authGrant
  })),
  on(AuthActions.ClearAuthGrant, (state) => ({
    ...state,
    authGrant: null
  })),
  on(AuthActions.WaitingForRelevant, (state, { isWaiting }) => ({
    ...state,
    waitingForRelevant: isWaiting
  })),
  on(AuthActions.AddPhoneToVerify, (state, { parsedMobile }) => ({
    ...state,
    phoneToVerify: parsedMobile
  })),
  on(AuthActions.ClearPhoneToVerify, (state) => ({
    ...state,
    phoneToVerify: ''
  }))
);

export function AuthReducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducer(state, action);
}
// ------------------------------------------------------------------------
// Selectors
// ------------------------------------------------------------------------
const authState = createFeatureSelector<AuthState>('auth');
const AuthGrant = createSelector(authState, (state: AuthState) => state.authGrant);

export const AuthSelectors = {
  AuthGrant,
  IsAuthenticating: createSelector(authState, (state: AuthState) => state.isAuthenticating),
  IsRefreshing: createSelector(authState, (state: AuthState) => state.isRefreshing),
  WaitingForRelevant: createSelector(authState, (state: AuthState) => state.waitingForRelevant),
  PhoneToVerify: createSelector(authState, (state: AuthState) => state.phoneToVerify),
  // Selecting from AuthGrant
  AccessToken: createSelector(AuthGrant, (item: AuthGrantModel) => item ? item.accessToken : ''),
  RefreshToken: createSelector(AuthGrant, (item: AuthGrantModel) => item ? item.refreshToken : ''),
};

// ------------------------------------------------------------------------
// Effects
// ------------------------------------------------------------------------
@Injectable()
export class ErrorEffects {

  rateLimitActive$ = createEffect(() => (
      this.actions$.pipe(
        ofType(AuthActions.RateLimitActive),
        tap(() => this.routingService.gotoRateLimit())
      )
    ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly routingService: RoutingService
  ) {
  }
}
