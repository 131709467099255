import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConnectionStatusType, IReconnectionStatus } from '@pm/lib/rx-websocket-subject';

import { ChatState } from '@pm/chat/ngrx/chat.ngrx';
import { ConnectionActions, ConnectionSelectors } from '@pm/chat/ngrx/connection.ngrx';

@Injectable()
export class ConnectionStateFacade {

  constructor(
    private readonly store: Store<ChatState>
  ) {
  }

  // CONNECTION STATE

  get connectionStatus$(): Observable<ConnectionStatusType> {
    return this.store.select(ConnectionSelectors.Status);
  }

  get connectionReconnecting$(): Observable<IReconnectionStatus> {
    return this.store.select(ConnectionSelectors.Reconnecting);
  }

  updateStatus(cst: ConnectionStatusType): void {
    this.store.dispatch(ConnectionActions.UpdateStatus({ cst }));
  }

  setReconnecting(reconnecting: IReconnectionStatus): void {
    this.store.dispatch(ConnectionActions.Reconnecting({ reconnecting }));
  }
}
