<label class="pm-radio">
  <input
    #radio
    type="radio"
    class="custom-control-input"
    (click)="onChange($event.target.value)"
    [id]="idField"
    [disabled]="isDisabled"
    [name]="name"
    [value]="value"
    autocomplete="off"
  />
  <span class="custom-control-indicator"></span>
  <ng-content></ng-content>
</label>
