import { Injectable } from '@angular/core';

import { Base64 } from 'js-base64';

@Injectable()
export class EncryptService {

  enc(value: string): string {
    return Base64.encode(value);
  }

  dec(value: string) {
    return Base64.decode(value);
  }
}
