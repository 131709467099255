import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BubbleType } from '@pm/chat/models/bubble.model';

@Component({
  selector: 'pm-message-bubble',
  templateUrl: 'message-bubble.component.html',
  styleUrls: ['message-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageBubbleComponent {
  @Input() name = '';
  @Input() text: string;
  @Input() isTesting: boolean;
  @Input() isRightToLeft = false;
  @Input() type = BubbleType.Message;

  @Output() rollbackConversation = new EventEmitter();

  triggerRollbackConversation() {
    this.rollbackConversation.emit();
  }

  get isName(): boolean {
    return this.name.length > 0;
  }

  get showRollbackOption(): boolean {
    return this.isTesting && (this.type === 'Question' || this.type === 'Message');
  }

}
