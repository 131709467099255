import { HttpErrorResponse } from '@angular/common/http';

export enum ErrorType {
  // noinspection JSUnusedGlobalSymbols
  AuthenticationError_InvalidGrant = 'AuthenticationError::InvalidGrant',
  AuthenticationError_InvalidToken = 'AuthenticationError::InvalidToken',
  AuthenticationError_WrongPassword = 'AuthenticationError::WrongPassword',
  AuthenticationError_InvalidPhoneVerification = 'AuthenticationError::InvalidPhoneVerification',
  AuthenticationError_InvalidPassword = 'AuthenticationError::InvalidPassword',
  AuthenticationError_DeactivatedCampaign = 'AuthenticationError::DeactivatedCampaign',
  AuthorizationError_DeactivatedCampaign = 'AuthorizationError::DeactivatedCampaign',
  AuthenticationError_InvalidCode = 'AuthenticationError::InvalidCode',

  Pundit_NotAuthorizedError = 'Pundit::NotAuthorizedError',

  AuthorizationError_UsersOverload = 'AuthorizationError::UsersOverload',
  AuthorizationError_RegistrationsClosed = 'AuthorizationError::RegistrationsClosed',
  AuthorizationError_DeactivatedAccount = 'AuthorizationError::DeactivatedAccount',
  AuthorizationError_SecurityTagged = 'AuthorizationError::SecurityTagged',
  AuthorizationError_ResetPasswordToken = 'AuthorizationError::ResetPasswordToken',
  AuthorizationError_NoPermissions = 'AuthorizationError::NoPermissions',
  AuthorizationError_GeoBlocked = 'AuthorizationError::GeoBlocked',
  AuthorizationError_MissingInformation = 'AuthorizationError::MissingInformation',
  AuthorizationError_NoAuthorizationCode = 'AuthorizationError::NoAuthorizationCode',
  AuthorizationError_DeactivatedMagicLink = 'AuthorizationError::DeactivatedMagicLink',
  AuthorizationError_InvalidMagicLinkCode = 'AuthenticationError::InvalidMagicLinkCode',
  AuthorizationError_ScopeNotPermitted = 'AuthorizationError::ScopeNotPermitted',
  AuthorizationError_InReview = 'AuthorizationError::InReview',


  ActiveRecord_RecordNotFound = 'ActiveRecord::RecordNotFound',
  ActiveRecord_RecordInvalid = 'ActiveRecord::RecordInvalid',
  ActiveRecord_RecordNotDestroyed = 'ActiveRecord::RecordNotDestroyed',

  RecordError_EventNotPermitted = 'RecordError::EventNotPermitted',
  RecordError_UnsupportedAffiliateProvider = 'RecordError::UnsupportedAffiliateProvider',
  RecordError_ListLoadingFailed = 'RecordError::ListLoadingFailed',
  RecordError_InsufficientFunds = 'RecordError::InsufficientFunds',

  RequestError_ProcessBulkError = 'RequestError::ProcessBulkError',
  RequestError_PhoneVerificationError = 'RequestError::PhoneVerificationError',
  RequestError_PhoneVerificationLimit = 'RequestError::PhoneVerificationLimit',
  RequestError_PhoneVerificationNotPossible = 'RequestError::PhoneVerificationNotPossible',
  RequestError_PrematurePhoneVerificationResendError = 'RequestError::PrematurePhoneVerificationResend',

  ActionController_ParameterMissing = 'ActionController::ParameterMissing',
  PG_UniqueViolation = 'PG::UniqueViolation',

  ActiveModel_Errors = 'ActiveModel::Errors',

  UnknownError = 'UnknownError',

  // CloudFlare - OPS
  ExceptionCloudFlareRateLimiting = 'Exception::CloudFlare::RateLimiting',
}

const InverseErrorTypes = new Map<ErrorType, string>();
Object.keys(ErrorType).forEach((error: string) => {
  InverseErrorTypes.set(ErrorType[error], error);
});

export class ApiError {
  type: ErrorType;
  messages: string[];
  codes: number[];
  originalResponse: HttpErrorResponse;
  data: object;

  constructor(response: HttpErrorResponse) {
    if (!response.error) {
      console.error('ApiError::constructor::', JSON.stringify(response));
      throw new Error('Unknown response format');
    }

    this.originalResponse = response;

    if (!response.error.error) {
      if (response.status !== 0) {
        console.warn('Error format is incorrect!', response);
      }
      this.setUnknownError();
      return;
    }

    this.setKnownError(response.error.error);
  }

  get isConnectionError(): boolean {
    return this.originalResponse.status === 0;
  }

  setKnownError(error: any) {
    const errorType = InverseErrorTypes.get(error.kind);

    if (!errorType) {
      console.warn(`Error kind "${error.kind}" not added to types!`);
    }

    this.type = ErrorType[errorType] || ErrorType.UnknownError;
    this.messages = error.messages;
    this.codes = error.codes;
    this.data = error.data || {};
  }

  setUnknownError() {
    this.type = ErrorType.UnknownError;
    this.messages = ['Ooops, something went wrong!'];
    this.codes = [];
  }
}
