import { Component, Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({})
// tslint:disable-next-line: directive-class-suffix
export abstract class AbstractComponent implements OnDestroy {
  protected ngUnsubscribe$: Subject<void>;

  protected constructor() {
    this.ngUnsubscribe$ = new Subject();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }
}
