import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { NotificationWrapperComponent } from '@pm/notification/components';
import { NotificationType } from '@pm/notification/types';
import { AbstractNotificationComponent } from '@pm/notification/components/abstract-notification.component';

const DEFAULT_OVERLAY_CFG = {
  hasBackdrop: true,
};

@Injectable()
export class NotificationService {

  private closeComponent: () => Promise<boolean>;
  private _openComponentType: NotificationType;

  get openComponentType(): NotificationType | null {
    return this._openComponentType;
  }

  constructor(
    private injector: Injector,
    private overlay: Overlay
  ) {
  }

  open<T extends AbstractNotificationComponent>(type: NotificationType, extras: object = {}): NotificationWrapperComponent<T> {

    if (this.isOpen()) {
      throw new Error('Cannot open notification while another notification is already open!');
    }

    const overlayRef = this.overlay.create({
      ...DEFAULT_OVERLAY_CFG,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const componentInstance = this.attachDialogContainer<T>(overlayRef);

    Object.assign(componentInstance, { type, ...extras });

    void componentInstance.open();

    this._openComponentType = type;
    this.closeComponent = () => {
      overlayRef.detachBackdrop();

      return componentInstance
        .close()
        .then(() => {
          overlayRef.dispose();

          return true;
        });
    };

    return componentInstance;
  }

  close(): Promise<boolean> {
    if (this.closeComponent) {
      const closeComponentResult = this.closeComponent();
      this.closeComponent = null;
      this._openComponentType = null;
      return closeComponentResult;
    }

    return Promise.resolve(false);
  }

  isOpen(): boolean {
    return !!this.closeComponent;
  }

  private attachDialogContainer<T extends AbstractNotificationComponent>(overlayRef: OverlayRef): NotificationWrapperComponent<T> {
    const injector = Injector.create({
      parent: this.injector,
      providers: [],
    });

    const containerPortal = new ComponentPortal(NotificationWrapperComponent, null, injector);

    const containerRef = overlayRef.attach(containerPortal);

    return containerRef.instance as NotificationWrapperComponent<T>;
  }
}
