import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BugsnaggableError, BugsnagService } from '@pm/core/services/bugsnag.service';

@Injectable()
export class LngDetectorService {
  constructor(
    @Inject(DOCUMENT) private readonly doc: any,
    private readonly bugsnagService: BugsnagService
  ) {
  }

  getLangInfo(): null | string {
    const innerText = this.getAnswerElementText();

    if (!innerText) {
      this.bugsnagService.reportError(
        new BugsnaggableError('LngDetectorService:: Cannot get innerText', { groupingHash: 'LngDetectorService:: no innertext' })
      );

      return null;
    }

    switch (innerText) {
      case 'Submit':
        return null;
      case 'En' + 'viar':
        return 'es';
      case 'Ipasa':
        return 'ph';
      default:
        this.bugsnagService.reportError(
          new BugsnaggableError(`LngDetectorService:: InnerText not matching for lang [${innerText}]`, {
            groupingHash: 'LngDetectorService:: InnerText not matching',
            severity: 'info',
          })
        );

        return '--';
    }
  }

  private getAnswerElementText(): string {
    try {
      return this.doc.querySelector('pm-answer-input .content').textContent.trim();
    } catch (e) {
      this.bugsnagService.leaveBreadcrumb('LngDetectorService::getAnswerElementText:: error', { errorMessage: e && e.message });
      return null;
    }
  }
}
