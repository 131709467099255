import {BrowserModule, Title} from '@angular/platform-browser';
import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

import {environment} from '@messenger-env/environment';
import '../styles/styles.scss';

import {metaReducers, RootReducers} from '@pm/core/ngrx';
import {CoreModule} from '@pm/core/core.module';
import {PublicModule} from '@pm/public/public.module';
import {AppComponent} from '@pm/app.component';
import {AppRoutingModule} from '@pm/app-routing.module';
import {BugsnagErrorHandler} from '@pm/lib/bugsnag-error-handler';
import {KitchensinkModule} from '@pm/kitchensink/kitchensink.module';
import {CampaignModule} from '@pm/campaign/campaign.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxHotjarModule} from 'ngx-hotjar';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
  ],
  imports: [
    NgxHotjarModule.forRoot(environment.hotjarKey),
    KitchensinkModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    PublicModule,
    CampaignModule,
    AppRoutingModule,
    StoreModule.forRoot(RootReducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    environment.isStoreDevToolsEnabled ? StoreDevtoolsModule.instrument({ maxAge: 25, serialize: { options: { map: true }}}) : []
  ],
  exports: [],
  providers: [
    Title,
    [
      {
        provide: ErrorHandler,
        useClass: BugsnagErrorHandler,
        deps: [Injector],
      }
    ],
  ]
})
export class AppModule {
}
