import { BrowserStorageService } from './browser-storage.service';
import { MemoryStorage } from '@pm/core/models/browser-storage.model';

export class BrowserStorageServiceFactory {
  static create() {
    const storage = BrowserStorageServiceFactory.hasLocalStorage() ?
      localStorage :
      new MemoryStorage();
    return new BrowserStorageService(storage);
  }

  static hasLocalStorage() {
    const key = 'test';

    try {
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export let BrowserStorageServiceProvider = {
  provide: BrowserStorageService,
  useFactory: BrowserStorageServiceFactory.create,
  deps: []
};
