import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AccountModalComponent } from './modals';
import {
  BackButtonComponent,
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  InputComponent,
  RadioComponent,
  SendButtonComponent,
  SusiAnimationComponent,
} from './components';
import { CapitalizePipe } from './pipes';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
  ],
  declarations: [
    CapitalizePipe,
    ButtonComponent,
    BackButtonComponent,
    AccountModalComponent,
    BackButtonComponent,
    CardComponent,
    CheckboxComponent,
    RadioComponent,
    SusiAnimationComponent,
    InputComponent,
    SendButtonComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    CapitalizePipe,
    ButtonComponent,
    BackButtonComponent,
    ReactiveFormsModule,
    AccountModalComponent,
    CheckboxComponent,
    RadioComponent,
    SusiAnimationComponent,
    CardComponent,
    FontAwesomeModule,
    InputComponent,
    SendButtonComponent
  ],
  entryComponents: [
    SusiAnimationComponent,
  ]
})
export class SharedModule {
}
