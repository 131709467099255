<img src="../../../assets/svg/logo.svg" class="logo">
<div class="container">
  <div class="container">
    <div class="title-image">
      <ng-content select="[title-image]"></ng-content>
    </div>

    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
