import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreStateFacade } from '@pm/core/ngrx/core-state-facade.service';
import { AdTrackingService, AffiliateService, BrowserStorageService, BugsnaggableError, BugsnagService, RoutingService } from '@pm/core/services';
import { ChatStateFacade } from '@pm/chat/ngrx/chat-facade.service';
import { LocalisationService } from '@pm/chat/services';
import { UUID } from '@pm/utils';

@Injectable()
export class CampaignService {
  constructor(
    private readonly http: HttpClient,
    private readonly coreFacade: CoreStateFacade,
    private readonly chatFacade: ChatStateFacade,
    private readonly adTrackingService: AdTrackingService,
    private readonly affiliateService: AffiliateService,
    private readonly browserStorageService: BrowserStorageService,
    private readonly routingService: RoutingService,
    private readonly bugsnag: BugsnagService,
  ) {
    (window as any).temp1 = this;
  }

  registerUser(linkType: string, campaignCode: string, panelCode?: string, meta?: object) {
    const userId = this.setUserId(campaignCode);

    this.coreFacade.userLoaded(
      {
        id: userId,
        linkType: linkType,
        campaignCode: campaignCode,
        panelCode: panelCode,
        meta: meta
      }
    );

    this.storeUserId(userId, campaignCode);
  }

  setUserId(campaignCode: string): string {
    const userId = this.retrieveUserId(campaignCode);
    return !!userId ? userId : UUID.create4();
  }

  private retrieveUserId(campaignCode: string): string {
    return this.browserStorageService.getItem(campaignCode);
  }

  private storeUserId(userId: string, campaignCode: string): void {
    return this.browserStorageService.setItem(campaignCode, userId);
  }

}
