import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'a[pm-back-button]',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
}
