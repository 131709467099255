<label class="pm-checkbox">
  <input
    #checkbox
    type="checkbox"
    class="custom-control-input"
    (click)="onChange($event.target.checked)"
    [id]="idField"
    [disabled]="isDisabled"
  />
  <span class="custom-control-indicator"></span>
  <ng-content></ng-content>
</label>
